import React from "react";
import { IResponsiveImage } from "@ottomotors/ottomotors-sanity";
import { TBreakpoint } from "@ottomotors/ottomotors-common/hooks";
import cn from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";

interface IProps {
  imageData: IResponsiveImage;
  style?: React.CSSProperties; // GatsbyImage doesn't play nice with classNames
  className?: string;
  breakpoint?: TBreakpoint;
  loading?: "eager" | "lazy";
}

const ResponsiveImage = ({
  imageData,
  style: inlineStyles,
  className,
  breakpoint = "smallTablet",
  loading,
}: IProps) => {
  const { desktopImage, mobileImage, altText } = imageData;
  const breakpoints = useBreakpoints();

  const isAtBreakpoint = breakpoints[breakpoint];

  return (
    <div className={cn(className)}>
      {isAtBreakpoint ? (
        <GatsbyImage
          style={inlineStyles}
          loading={loading}
          alt={altText || "OTTO Motors"}
          image={desktopImage.asset.gatsbyImageData}
        />
      ) : (
        <GatsbyImage
          style={inlineStyles}
          alt={altText || "OTTO Motors"}
          loading={loading}
          image={mobileImage.asset.gatsbyImageData}
        />
      )}
    </div>
  );
};

export default ResponsiveImage;
