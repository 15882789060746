import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { LayoutProvider, SVG } from "@ottomotors/ottomotors-common/components";
import { Button } from "@ottomotors/ottomotors-common/components";
import { SliceConfig } from "@ottomotors/ottomotors.com/components";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import * as styles from "./styles.module.scss";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";

const countries = [
  "Other",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Indian Ocean Territory",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Côte d’Ivoire",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong S.A.R., China",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao S.A.R., China",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Korea",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "U.S. Virgin Islands",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican",
  "Venezuela",
  "Viet Nam",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const states = [
  "Other",
  "Alabama",
  "Alaska",
  "Alberta",
  "Arizona",
  "Arkansas",
  "British Columbia",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Manitoba",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Brunswick",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "Newfoundland",
  "North Carolina",
  "North Dakota",
  "Northwest Territories",
  "Not Applicable",
  "Nova Scotia",
  "Nunavut",
  "Ohio",
  "Oklahoma",
  "Ontario",
  "Oregon",
  "Pennsylvania",
  "Prince Edward Island",
  "Quebec",
  "Rhode Island",
  "Saskatchewan",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Unknown",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "Yukon",
];

const industries = [
  "Other",
  "Automotive",
  "Consumer Products",
  "Food & Beverage",
  "Industrial",
];

const RoiCalculator = ({ data: { sliceConfig, pardotTarget } }) => {
  const { largeTablet } = useBreakpoints();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const inputsRef = useRef([]);
  const containerRef = useRef();
  const resultsRef = useRef();

  //
  // On the old website:
  // http://138.197.139.239/resources/roi-calculator
  //
  // Given these:
  // - Personnel: 18
  // - Fully burdened labor rate: 36.5
  // - Hours per shift: 6
  // - Shifts per day: 3
  // - Days per week: 5
  // - Production weeks: 31
  //
  // It returns:
  // - Payback: < 12 months
  // - 5 yr IRR: 60%

  const [errorFields, setErrorFields] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    "roi-country": "Other",
    "roi-state": "Other",
    "roi-industry": "Other",
    "roi-personnel": "18",
    "roi-labor-rate": "36.5",
    "roi-hours": "6",
    "roi-shift": "3",
    "roi-days": "5",
    "roi-weeks": "31",
  });
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  const [valueIRR, setValueIRR] = useState(`N/A`);
  const [valuePayback, setValuePayback] = useState(`N/A Months*`);

  // ---------------------------------------------------------------------------
  // methods

  const adjustOutputPositions = () => {
    inputsRef.current.forEach((input) => {
      if (!input) return;

      const value = input.value;
      const min = parseFloat(input.min) || 0;
      const max = parseFloat(input.max) || 100;
      const range = max - min;
      const thumbWidth = 20;
      const width = input.offsetWidth - thumbWidth;

      const normalizedValue = (value - min) / range;
      const thumbPosition = width * normalizedValue;
      const output = input.nextElementSibling;

      if (output) {
        const outputWidth = output.offsetWidth;
        const outputPosition = thumbPosition - outputWidth / 2 + thumbWidth / 2;
        output.style.left = `${outputPosition}px`;
        output.textContent = value;
      }
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (event.target.type === "range") {
      adjustOutputPositions();
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    handleInputChange(event);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
    handleInputChange(event);
  };

  const calculateIRR = ({
    personnel,
    laborRate,
    hours,
    shifts,
    days,
    weeks,
  }) => {
    // todo: get this math from client
    return `?`;
  };

  const calculatePaybackPeriod = ({
    personnel,
    laborRate,
    hours,
    shifts,
    days,
    weeks,
  }) => {
    const totalCost = 125000;
    const otherExpenses = 15000;

    const paybackMonths = Math.round(
      (((totalCost + otherExpenses) * personnel) /
        (personnel * laborRate * hours * shifts * days * weeks)) *
        12
    );

    return paybackMonths;
  };

  const calculate = () => {
    const personnel = parseFloat(formData["roi-personnel"]);
    const laborRate = parseFloat(formData["roi-labor-rate"]);
    const hours = parseFloat(formData["roi-hours"]);
    const shifts = parseFloat(formData["roi-shift"]);
    const days = parseFloat(formData["roi-days"]);
    const weeks = parseFloat(formData["roi-weeks"]);

    const irr = calculateIRR({
      personnel,
      laborRate,
      hours,
      shifts,
      days,
      weeks,
    });
    const paybackPeriod = calculatePaybackPeriod({
      personnel,
      laborRate,
      hours,
      shifts,
      days,
      weeks,
    });

    setValueIRR(irr);
    setValuePayback(`${paybackPeriod} Months*`);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const requiredFields = [
      "roi-first-name",
      "roi-last-name",
      "roi-email",
      "roi-company",
      "roi-telephone",
      "roi-state",
      "roi-country",
      "roi-personnel",
      "roi-labor-rate",
      "roi-hours",
      "roi-shift",
      "roi-days",
      "roi-weeks",
    ];

    const missingFields = requiredFields.filter((field) => !formData?.[field]);

    if (missingFields.length > 0) {
      setErrorMessage("Please fill in all required fields");
      setErrorFields(missingFields);

      if (containerRef.current) {
        const containerOffsetTop =
          containerRef.current.getBoundingClientRect().top + window.pageYOffset;

        window.scrollTo({
          top: containerOffsetTop - 48,
          behavior: "smooth",
        });
      }

      return;
    }

    setSubmitting(true);

    // fetch(`/api/roi`, {
    fetch(`/.netlify/functions/roi`, {
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        calculate();
        setSubmitted(true);
        setSubmitting(false);

        // if (largeTablet && resultsRef?.current) {
        //   const containerOffsetTop = resultsRef.current.getBoundingClientRect().top + window.pageYOffset;

        //   window.scrollTo({
        //     top: containerOffsetTop - 48,
        //     behavior: "smooth",
        //   });
        // }
      })
      .catch((error) => {
        console.error(`Error:`, error);
      });
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  // for testing
  // useEffect(() => {
  //   calculate();
  // }, [formData]);

  useEffect(() => {
    console.log(country, "country");
  }, [country]);

  useEffect(() => {
    console.log(state, "state");
  }, [state]);

  useEffect(() => {
    if (!inputsRef?.current?.[0]) {
      return;
    }

    window.addEventListener("resize", adjustOutputPositions);

    inputsRef.current.forEach((input) => {
      adjustOutputPositions(input);
      input.addEventListener("input", handleInputChange);
    });

    return () => {
      if (!inputsRef?.current?.[0]) {
        return;
      }

      inputsRef.current.forEach((input) => {
        if (!input) return;

        input.removeEventListener("input", handleInputChange);
      });
    };
  }, [inputsRef]);

  // ---------------------------------------------------------------------------
  // render

  const renderOptions = (options) => {
    return options.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ));
  };

  const renderInputGroup = (
    label,
    name,
    type,
    fullWidth,
    options = [],
    selected
  ) => {
    let jsx = <></>;

    switch (type) {
      case "select":
        jsx = (
          <select
            className="b1"
            name={name}
            onChange={handleInputChange}
            required
            defaultValue={selected}
          >
            <option value="" disabled>
              Select {label}
            </option>
            {renderOptions(options)}
          </select>
        );
        break;

      case "country":
        jsx = (
          <div className="b1">
            <CountryDropdown
              name={name}
              defaultOptionLabel="Other"
              value={country}
              onChange={(val, e) => handleCountryChange(e)}
            />
          </div>
        );
        break;

      case "states":
        jsx = (
          <div className="b1">
            <RegionDropdown
              name={name}
              blankOptionLabel="Other"
              defaultOptionLabel="Other"
              country={country}
              value={state}
              onChange={(val, e) => handleStateChange(e)}
            />
          </div>
        );
        break;

      default:
        jsx = (
          <input
            className="b1"
            name={name}
            placeholder={label}
            required
            onChange={handleInputChange}
            type={type}
          />
        );
        break;
    }

    return (
      <div
        className={cn(styles.inputGroup, {
          [styles.fullWidth]: fullWidth,
          [styles.error]: errorFields?.includes(name),
        })}
      >
        <label className="label">{label}</label>
        {jsx}
      </div>
    );
  };

  const renderRangeGroup = (
    label,
    name,
    min,
    max,
    step = "1",
    unitPrefix = ``,
    disclaimer
  ) => {
    return (
      <div
        className={cn(styles.inputGroup, styles.inputGroupRange, {
          [styles.error]: errorFields?.includes(name),
        })}
      >
        <label htmlFor={name} className="b1">
          {label}
        </label>

        {disclaimer && (
          <p className={cn(`caption`, styles.disclaimer)}>{disclaimer}</p>
        )}

        <input
          ref={(el) => inputsRef.current.push(el)}
          name={name}
          type="range"
          onChange={handleInputChange}
          min={min}
          max={max}
          value={formData[name]}
          step={step}
        />

        {formData?.[name] && (
          <output htmlFor={name} className={cn(`caption`, styles.output)}>
            {formData[name]}
          </output>
        )}

        <div className={styles.valueFlex}>
          <span className="caption">
            {unitPrefix}
            {min}
          </span>
          <span className="caption">
            {unitPrefix}
            {max}
          </span>
        </div>
      </div>
    );
  };

  return (
    <SliceConfig config={sliceConfig}>
      <section ref={containerRef} className={styles.container}>
        <LayoutProvider grid className={styles.grid}>
          <form onSubmit={onSubmit} noValidate className={styles.form}>
            <div
              className={cn(styles.formGroup, {
                [styles.hidden]: submitted,
              })}
            >
              <header className={styles.header}>
                <h2 className="h2">Your Information</h2>
                <p className={cn(`b1`, styles.errorMessage)}>{errorMessage}</p>
              </header>

              <div className={styles.formFlex}>
                {renderInputGroup(
                  "* First Name",
                  "roi-first-name",
                  "text",
                  false
                )}
                {renderInputGroup(
                  "* Last Name",
                  "roi-last-name",
                  "text",
                  false
                )}
                {renderInputGroup("* Email", "roi-email", "email", false)}
                {renderInputGroup("* Company", "roi-company", "text", false)}
                {renderInputGroup(
                  "* Phone Number",
                  "roi-telephone",
                  "tel",
                  false
                )}
                {renderInputGroup(
                  "* State/Province",
                  "roi-state",
                  "states",
                  false,
                  states,
                  `Other`
                )}
                {renderInputGroup(
                  "* Country",
                  "roi-country",
                  "country",
                  true,
                  countries,
                  `Other`
                )}
                {renderInputGroup(
                  "* Industry",
                  "roi-industry",
                  "select",
                  true,
                  industries,
                  `Other`
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <header className={styles.header}>
                <h2 className="h2">ROI Calculation</h2>
              </header>

              <div className={styles.formFlex}>
                {renderRangeGroup(
                  "Number of material transport personnel per shift",
                  "roi-personnel",
                  "1",
                  "100",
                  "1"
                )}

                {renderRangeGroup(
                  "Fully burdened labor rate (hourly)",
                  "roi-labor-rate",
                  "8.00",
                  "100.00",
                  "0.5",
                  "$",
                  `The fully-burdened labor cost is the full hourly cost to employ a worker for the hours they work, which includes wages and the “burden” of additional costs such as taxes, benefits, and supplies.`
                )}

                {renderRangeGroup(
                  "Hours per shift",
                  "roi-hours",
                  "4",
                  "12",
                  "1"
                )}

                {renderRangeGroup("Shifts per day", "roi-shift", "1", "4", "1")}

                {renderRangeGroup(
                  "Operating days per week",
                  "roi-days",
                  "1",
                  "7",
                  "1"
                )}

                {renderRangeGroup(
                  "Production weeks per year",
                  "roi-weeks",
                  "1",
                  "52",
                  "1"
                )}

                <Button
                  className={cn(styles.button, {
                    [styles.disabled]: submitting,
                  })}
                  disabled={submitting}
                  buttonType="submit"
                  text="Calculate ROI"
                />
              </div>
            </div>
          </form>

          <div ref={resultsRef} className={styles.results}>
            <div className={styles.resultsInner}>
              <h2 className={cn(`label`)}>Your Results</h2>

              <div className={styles.resultGroup}>
                <h3 className={cn(`h1`, styles.resultValue)}>
                  <span>{valuePayback}</span>
                </h3>
                <p className={cn(`label`)}>payback period</p>
              </div>

              {/* <div className={styles.resultGroup}>
                <h3 className={cn(`h1`, styles.resultValue)}>
                  <span>{valueIRR}</span>
                </h3>
                <p className={cn(`label`)}>5 Year IRR</p>
              </div> */}

              <p
                className={cn(
                  `caption`,
                  styles.disclaimer,
                  styles.resultsDisclaimer
                )}
              >
                * Disclaimer
                <br />
                <br />
                The Payback Period and IRR returned are estimates based on
                Direct Labor Rates alone. Outputs do not include supplemental
                benefits of autonomous mobile robots including throughput and
                density improvements, which will return even larger impacts. All
                figures shown in USD.
              </p>
            </div>
          </div>
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default RoiCalculator;
