import React, { useState } from "react";
import cn from "classnames";
import {
  Button,
  UniversalLinkWrapper,
} from "@ottomotors/ottomotors-common/components";
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./styles.module.scss";

const ResourceCard = ({
  data,
  landscapeCardImage = false,
  noImage = false,
  className,
}) => {
  const { title, slug, category, cardImage, cardButtonText, _type } =
    data || {};

  const [isHovered, setIsHovered] = useState(false);

  let linkType;
  let linkProperty = "";

  switch (_type) {
    case "ottomotors.webinar":
      linkType = "referenceOttomotorsWebinar";
      linkProperty = "webinar";
      break;

    case "ottomotors.video":
      linkType = "referenceOttomotorsVideo";
      linkProperty = "video";
      break;

    case "ottomotors.blogArticle":
      linkType = "referenceOttomotorsBlogArticle";
      linkProperty = "blogArticle";
      break;

    case "ottomotors.pressRelease":
      linkType = "referenceOttomotorsPressRelease";
      linkProperty = "pressRelease";
      break;

    case "ottomotors.ebook":
      linkType = "referenceOttomotorsEbook";
      linkProperty = "ebook";
      break;

    case "ottomotors.whitePaper":
      linkType = "referenceOttomotorsWhitePaper";
      linkProperty = "whitePaper";
      break;

    case "ottomotors.caseStudy":
      linkType = "referenceOttomotorsCaseStudy";
      linkProperty = "caseStudy";
      break;

    case "ottomotors.download":
      linkType = "referenceOttomotorsDownload";
      linkProperty = "download";
      break;

    case "ottomotors.event":
      linkType = "referenceOttomotorsEvent";
      linkProperty = "event";
      break;

    default:
      break;
  }

  const button = {
    text: cardButtonText || "Read More",
    link: [
      {
        _type: linkType,
        [linkProperty]: {
          slug: slug,
        },
      },
    ],
  };

  const getEventCategory = (category: string) => {
    if (!category) return "Event";
    let eventCategory = "";

    switch (category) {
      case "conference":
        eventCategory = "Conference";
        break;
      case "tradeShow":
        eventCategory = "Trade Show";
        break;
      case "partnerEvent":
        eventCategory = "Partner Event";
        break;
      case "speakingSession":
        eventCategory = "Speaking Session";
        break;
      default:
        break;
    }

    return eventCategory;
  };

  const getContentType = (button, category) => {
    if (!button?.link?.[0]) return null;

    const buttonData = button?.link?.[0];

    let type;

    switch (buttonData?._type) {
      case "referenceOttomotorsBlogArticle":
        type = "Blog";
        break;

      case `referenceOttomotorsVideo`:
        type = "Video";
        break;

      case `referenceOttomotorsEbook`:
        type = "Ebook";
        break;

      case `referenceOttomotorsPressRelease`:
        type = "Press Release";
        break;

      case `referenceOttomotorsWebinar`:
        type = "Webinar";
        break;

      case "referenceOttomotorsWhitePaper":
        type = "White Paper";
        break;

      case "referenceOttomotorsCaseStudy":
        type = "Case Study";
        break;

      case "referenceOttomotorsDownload":
        type = "Download";
        break;

      case "referenceOttomotorsEvent":
        type = getEventCategory(category?.[0]);
        break;

      default:
        break;
    }

    return type;
  };

  const articleType = getContentType(button, category);

  return (
    <UniversalLinkWrapper className={className} data={button}>
      <article
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={styles.container}
      >
        {!noImage && cardImage?.asset?.gatsbyImageData && (
          <figure
            className={cn(styles.image, {
              [styles.landscape]: landscapeCardImage,
            })}
          >
            <GatsbyImage
              image={cardImage?.asset?.gatsbyImageData}
              alt={cardImage?.altText || "OTTO Motors"}
            />
          </figure>
        )}
        <div className={styles.content}>
          {articleType && (
            <p>
              <span className={cn("label", styles.tag)}>
                {articleType.toUpperCase()}
              </span>
            </p>
          )}
          <div className={styles.text}>
            {title && (
              <header className="h4">
                <h4>{title}</h4>
              </header>
            )}
          </div>

          {button && (
            <div className={styles.buttonContainer}>
              {button?.text && button?.link?.[0] && (
                <Button
                  className={styles.button}
                  display
                  text={button.text}
                  iconRight={"arrowRight"}
                  iconLeft={"arrowRight"}
                  animateOnHover
                  variant="text"
                  isHovered={isHovered}
                />
              )}
            </div>
          )}
        </div>
      </article>
    </UniversalLinkWrapper>
  );
};

export default ResourceCard;
