import { useStaticQuery, graphql } from "gatsby";

const useResourceArticles = () => {
  const allArticleData = useStaticQuery(graphql`
    query articleFilterQuery {
      # ebooks
      allSanityOttomotorsEbook {
        nodes {
          _id
          _type

          title

          slug {
            current
          }
          resourceTopics {
            topics
          }

          resourceLanguages {
            languages
          }
          publishedDate

          cardButtonText
          cardImage {
            altText
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }

      # case studyes
      allSanityOttomotorsCaseStudy {
        nodes {
          _id
          _type

          title

          slug {
            current
          }
          resourceTopics {
            topics
          }

          resourceLanguages {
            languages
          }
          publishedDate

          cardButtonText
          cardImage {
            altText
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }

      # blog articles
      allSanityOttomotorsBlogArticle {
        nodes {
          _id
          _type

          title

          slug {
            current
          }
          resourceTopics {
            topics
          }

          resourceLanguages {
            languages
          }

          publishedDate

          cardButtonText
          cardImage {
            altText
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }

      # video case studies
      allSanityOttomotorsVideo {
        nodes {
          _id
          _type

          title

          slug {
            current
          }
          resourceTopics {
            topics
          }

          resourceLanguages {
            languages
          }

          publishedDate

          cardButtonText
          cardImage {
            altText
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }

      # webinars
      allSanityOttomotorsWebinar {
        nodes {
          _id
          _type

          title
          streamUrl

          slug {
            current
          }
          resourceTopics {
            topics
          }

          resourceLanguages {
            languages
          }

          publishedDate

          cardButtonText
          cardImage {
            altText
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }

      # white papers
      allSanityOttomotorsWhitePaper {
        nodes {
          _id
          _type

          title

          slug {
            current
          }
          resourceTopics {
            topics
          }

          resourceLanguages {
            languages
          }

          publishedDate

          cardButtonText
          cardImage {
            altText
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }

      # white papers
      allSanityOttomotorsDownload {
        nodes {
          _id
          _type

          title

          slug {
            current
          }
          resourceTopics {
            topics
          }

          resourceLanguages {
            languages
          }

          publishedDate

          cardButtonText
          cardImage {
            altText
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  const allArticles = allArticleData.allSanityOttomotorsWebinar.nodes
    .concat(allArticleData.allSanityOttomotorsVideo.nodes)
    .concat(allArticleData.allSanityOttomotorsBlogArticle.nodes)
    .concat(allArticleData.allSanityOttomotorsEbook.nodes)
    .concat(allArticleData.allSanityOttomotorsCaseStudy.nodes)
    .concat(allArticleData.allSanityOttomotorsWhitePaper.nodes)
    .concat(allArticleData.allSanityOttomotorsDownload.nodes);

  return allArticles;
};

export default useResourceArticles;
