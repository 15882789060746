import React, { useState } from "react";
import {
  Button,
  UniversalLinkWrapper,
} from "@ottomotors/ottomotors-common/components";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./styles.module.scss";

const ProductCard = ({ data }) => {
  const { heading, body, image, buttonPrimary } = data || {};

  const [isHovered, setIsHovered] = useState(true);

  let isPortraitImage;

  if (image?.asset?.gatsbyImageData) {
    const imageData = image?.asset?.gatsbyImageData;
    isPortraitImage = imageData.height > imageData.width;
  }

  return (
    <UniversalLinkWrapper
      className={styles.container}
      data={buttonPrimary}
      onMouseEnter={() => (buttonPrimary ? setIsHovered(true) : {})}
      onMouseLeave={() => (buttonPrimary ? setIsHovered(false) : {})}
    >
      <article>
        {image?.asset?.gatsbyImageData && (
          <figure className={styles.image}>
            <GatsbyImage
              image={image?.asset?.gatsbyImageData}
              alt={image?.altText || "OTTO Motors"}
              objectFit={isPortraitImage ? `cover` : `contain`}
            />
          </figure>
        )}

        <div className={styles.content}>
          {(heading || body) && (
            <header className={styles.header}>
              <h3 className="h4">{heading}</h3>
              {body && <p className="b1">{body}</p>}
            </header>
          )}

          {buttonPrimary && (
            <div className={styles.buttonContainer}>
              {buttonPrimary?.text && buttonPrimary?.link?.[0] && (
                <Button
                  display
                  iconRight={"arrowRight"}
                  iconLeft={"arrowRight"}
                  animateOnHover
                  variant="text"
                  text={buttonPrimary.text}
                  isHovered={isHovered}
                />
              )}
            </div>
          )}
        </div>
      </article>
    </UniversalLinkWrapper>
  );
};

export default ProductCard;
