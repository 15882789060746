import React from "react";
import cn from "classnames";
import {
  IntersectionAnimation,
  LayoutProvider,
} from "@ottomotors/ottomotors-common/components";
import { SliceConfig } from "@ottomotors/ottomotors.com/components";
import Card from "./components/Card";
import { IOttoMotorsKeyStatistics } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsKeyStatistics;
}

const KeyStatistics = ({
  data: { statistics, slicePadding, sliceConfig },
}: IProps) => {
  const { paddingTop, paddingBottom } = slicePadding || {};
  const fixedWidth = statistics?.length < 3;

  return (
    <SliceConfig config={sliceConfig}>
      <section
        className={cn(styles.container, {
          [styles.paddingTopNone]: !paddingTop,
          [styles.paddingBottomNone]: !paddingBottom,
        })}
      >
        <LayoutProvider grid>
          {statistics?.[0] && (
            <div
              className={cn(styles.content, { [styles.center]: fixedWidth })}
            >
              {statistics.map((card, index) => {
                const animationStagger = 150;

                return (
                  <IntersectionAnimation
                    key={`key-statistics-card-${card?._key}`}
                    className={cn(styles.card, {
                      [styles.fixedWidth]: fixedWidth,
                    })}
                    delay={animationStagger * index}
                    margin="-60px"
                  >
                    <Card data={card} delay={animationStagger * index} />
                  </IntersectionAnimation>
                );
              })}
            </div>
          )}
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default KeyStatistics;
