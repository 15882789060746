// extracted by mini-css-extract-plugin
export var accordion = "styles-module--accordion--c6f4b";
export var accordionContent = "styles-module--accordionContent--2be4f";
export var accordionTitle = "styles-module--accordionTitle--63dc1";
export var active = "styles-module--active--1fe08";
export var chevron = "styles-module--chevron--7262f";
export var container = "styles-module--container--cace9";
export var desktop = "1340px";
export var fixedContainer = "styles-module--fixedContainer--5cfe4";
export var giant = "2200px";
export var hidden = "styles-module--hidden--89027";
export var isExpanded = "styles-module--isExpanded--af581";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--addef";
export var links = "styles-module--links--0b28a";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--1f3fe";