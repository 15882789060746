// extracted by mini-css-extract-plugin
export var button = "styles-module--button--aa975";
export var closeButton = "styles-module--closeButton--ca5ed";
export var container = "styles-module--container--8fe0e";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--8f52d";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--1adca";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var video = "styles-module--video--a46fb";
export var visible = "styles-module--visible--ccc87";