// extracted by mini-css-extract-plugin
export var body = "styles-module--body--c88ae";
export var buttonContainer = "styles-module--buttonContainer--531f0";
export var buttons = "styles-module--buttons--d6a60";
export var caption = "styles-module--caption--d77da";
export var container = "styles-module--container--0f097";
export var content = "styles-module--content--d419c";
export var desktop = "1340px";
export var fullWidth = "styles-module--fullWidth--6802b";
export var giant = "2200px";
export var heading = "styles-module--heading--e3540";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--e3102";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subheading = "styles-module--subheading--a928e";
export var tablet = "768px";
export var text = "styles-module--text--0be18";
export var twoCol = "styles-module--twoCol--abb79";