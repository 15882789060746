import React, { useState } from "react";
import cn from "classnames";
import { SVG } from "@ottomotors/ottomotors-common/components";

import * as styles from "./styles.module.scss";

interface IProps {
  items: {
    value: number;
    label: string;
  }[];
  value: number;
  setValue: (value: number) => void;
}

const Dropdown = ({ items, value, setValue }: IProps) => {
  const [itemsVisible, setItemsVisible] = useState(false);
  const handleSelect = (value: number) => {
    setValue(value);
    setItemsVisible(false);
  };
  return (
    <div className={styles.container}>
      <button
        className={styles.dropdownButton}
        onClick={() => setItemsVisible(!itemsVisible)}
      >
        <span className="label">Workflow</span>
        <span className="b1">{items[value].label}</span>
        <figure
          className={cn(styles.dropdownButtonChevron, {
            [styles.open]: itemsVisible,
          })}
        >
          <SVG svg="chevronDown" />
        </figure>
      </button>
      <ul
        className={cn(styles.dropdownItems, {
          [styles.visible]: itemsVisible,
        })}
      >
        {items.map((item, index) => {
          const isActive = value === item.value;
          return (
            <li
              key={index}
              className={cn("b2", styles.dropdownItem, {
                [styles.active]: isActive,
              })}
              onClick={() => handleSelect(item.value)}
            >
              {item.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Dropdown;
