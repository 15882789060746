import React, { useState } from "react";
import cn from "classnames";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import { TextLockup } from "@ottomotors/ottomotors.com/components";
import Dropdown from "./components/Dropdown";
import Accordion from "./components/Accordion";
import StepsCarousel from "./components/StepsCarousel";
import { IOttoMotorsWorkflow } from "@ottomotors/ottomotors-sanity/schemas/ottomotors.com/slices/workflow";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsWorkflow;
}

const Workflow = ({ data: { textLockup, items } }: IProps) => {
  const [expandedTab, setExpandedTab] = useState<number>(0);
  if (!items?.[0]) return null;

  const textLockupConfig = {
    ...textLockup,
    headingTypestyle: textLockup?.headingTypestyle || "h2",
  };

  return (
    <section className={cn(styles.container)}>
      <LayoutProvider className={styles.layout} grid>
        {textLockup && (
          <article className={styles.textLockup}>
            <TextLockup data={textLockupConfig} />
          </article>
        )}
        <div className={styles.dropdownContainer}>
          <Dropdown
            items={items.map((item, i) => ({ value: i, label: item.heading }))}
            value={expandedTab}
            setValue={setExpandedTab}
          />
        </div>
        <div className={styles.accordionContainer}>
          <Accordion
            items={items}
            expandedTab={expandedTab}
            setExpandedTab={setExpandedTab}
          />
        </div>
        <div className={styles.carouselContainer}>
          {items.map((item, index) => {
            const isActive = expandedTab === index;
            return (
              <StepsCarousel
                key={item._key}
                item={item}
                active={isActive}
              />
            );
          })}
        </div>
      </LayoutProvider>
    </section>
  );
};

export default Workflow;
