import React from "react";
import { IOttoMotorsAnchorPoint } from "@ottomotors/ottomotors-sanity";

interface IProps {
  data: IOttoMotorsAnchorPoint;
}

const AnchorPoint = ({ data: { _key, anchorId } }: IProps) => {
  if (!anchorId) return null;
  const tocJumpId = anchorId.toLowerCase().replace(/[^0-9a-z]/gi, '');

  return <div id={tocJumpId} style={{ top: '-64px', position: 'relative' }}/>;
};

export default AnchorPoint;
