import React from "react";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import {
  TextLockup,
  Form,
  SliceConfig,
} from "@ottomotors/ottomotors.com/components";

import { IOttoMotorsContact } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsContact;
}

const Contact = ({
  data: { textLockup, confirmationMessage, sliceConfig },
}: IProps) => {
  let i;

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider className={styles.grid} grid>
          {textLockup && (
            <TextLockup className={styles.textLockup} data={textLockup} />
          )}
          <div className={styles.form}>
            <Form type="contact" />
          </div>
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default Contact;
