// extracted by mini-css-extract-plugin
export var buttonContainer = "styles-module--buttonContainer--56b45";
export var checkSvg = "styles-module--checkSvg--31415";
export var container = "styles-module--container--f56b7";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--89921";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var progress = "styles-module--progress--4d8fb";
export var progressBar = "styles-module--progressBar--665c7";
export var progressBarContainer = "styles-module--progressBarContainer--5adc5";
export var progressBarSvg = "styles-module--progressBarSvg--703f6";
export var progressBarText = "styles-module--progressBarText--450e4";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var table = "styles-module--table--b1423";
export var tableContainer = "styles-module--tableContainer--79e7c";
export var tableData = "styles-module--tableData--f2b36";
export var tableHeader = "styles-module--tableHeader--ffa30";
export var tableRow = "styles-module--tableRow--eb290";
export var tablet = "768px";
export var text = "styles-module--text--77d30";