import React from "react";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import {
  AcceptCookiesMessage,
  SliceConfig,
  TextLockup,
} from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsInteractiveFactory } from "@ottomotors/ottomotors-sanity";
import { useApp } from "@ottomotors/ottomotors.com/hooks";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsInteractiveFactory;
}

const InteractiveFactory = ({ data: { sliceConfig, textLockup } }: IProps) => {
  const { cookiesAccepted } = useApp();
  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.container}>
        <LayoutProvider grid>
          {textLockup && (
            <TextLockup className={styles.text} data={textLockup} />
          )}
          <div className={styles.iframeContainer}>
            {cookiesAccepted ? (
              <iframe
                className={styles.iframe}
                allow="fullscreen"
                src="https://interact.rockwellautomation.com/AutonomousProductionLogistics/"
              ></iframe>
            ) : (
              <AcceptCookiesMessage text="view this experience" />
            )}
          </div>
        </LayoutProvider>
      </div>
    </SliceConfig>
  );
};

export default InteractiveFactory;
