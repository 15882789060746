import React from "react";
import cn from "classnames";
import { SVG } from "@ottomotors/ottomotors-common/components";

import * as styles from "./styles.module.scss";

interface IProps {
  text: string;
}

const ChecklistItem = ({ text }: IProps) => {
  return (
    <span className={cn("b1", styles.container)}>
      <figure className={styles.svg}>
        <SVG svg="checkCircle" />
      </figure>
      {text}
    </span>
  );
};

export default ChecklistItem;
