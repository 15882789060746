import React from "react";
import { Link } from "gatsby";
import { getUniversalLinkUrl } from "@ottomotors/ottomotors-common/utils";
import {
  IExternalUrl,
  IReferenceOttoMotorsAmr,
  IReferenceOttoMotorsBlogArticle,
  IReferenceOttoMotorsCaseStudy,
  IReferenceOttoMotorsEbook,
  IReferenceOttoMotorsEvent,
  IReferenceOttoMotorsPage,
  IReferenceOttoMotorsProduct,
  IReferenceOttoMotorsPressRelease,
  IReferenceOttoMotorsResourcePage,
  IReferenceOttoMotorsVideo,
  IReferenceOttoMotorsWebinar,
  IReferenceOttoMotorsWhitePaper,
  IReferenceOttoMotorsDownload,
} from "@ottomotors/ottomotors-sanity";

// simpler wrapper component for a UniversalLink.
// instead of returning a Button component it parses the url
// and wraps the content with the appropriate link element
// with the correct slug according to page type

interface IProps {
  data: {
    link: Array<
      | IExternalUrl
      | IReferenceOttoMotorsAmr
      | IReferenceOttoMotorsPage
      | IReferenceOttoMotorsProduct
      | IReferenceOttoMotorsPressRelease
      | IReferenceOttoMotorsResourcePage
      | IReferenceOttoMotorsBlogArticle
      | IReferenceOttoMotorsCaseStudy
      | IReferenceOttoMotorsEbook
      | IReferenceOttoMotorsEvent
      | IReferenceOttoMotorsVideo
      | IReferenceOttoMotorsWebinar
      | IReferenceOttoMotorsWhitePaper
      | IReferenceOttoMotorsDownload
    >;
  };
  className?: string;
  onMouseEnter?: any;
  onMouseLeave?: any;
  children: React.ReactNode;
}

const UniversalLinkWrapper = ({
  data,
  className = "",
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  children,
}: IProps) => {
  const { link: linkData } = data || {};

  const link = linkData?.[0];

  if (!link)
    return (
      <div
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
    );

  const url = getUniversalLinkUrl(linkData);

  switch (link?._type) {
    case `externalUrl`:
      return (
        <a
          className={className}
          href={url}
          target="_blank"
          rel="noreferrer noopener"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </a>
      );

    default:
      return (
        <Link
          className={className}
          to={url}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </Link>
      );
  }
};

export default UniversalLinkWrapper;
