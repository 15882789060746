// extracted by mini-css-extract-plugin
export var container = "styles-module--container--21ee9";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--5161c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logo = "styles-module--logo--9be50";
export var logos = "styles-module--logos--8ad6a";
export var marquee = "styles-module--marquee--e0eb8";
export var mobile = "400px";
export var scrollX = "styles-module--scroll-x--92691";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var textLockup = "styles-module--textLockup--72551";