import React from "react";
import {
  LayoutProvider,
  PortableTextRenderer,
} from "@ottomotors/ottomotors-common/components";
import {
  ShareButtons,
  SliceConfig,
} from "@ottomotors/ottomotors.com/components";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { IRichText } from "@ottomotors/ottomotors-sanity";
import cn from "classnames";
import { useSiteSettings } from "@ottomotors/ottomotors.com/hooks";
import { IPageMetadata } from "@ottomotors/ottomotors.com/components/SanitySlice";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IRichText;
  pageMetadata?: IPageMetadata;
}

const RichText = ({
  data: { sliceConfig, _rawText, text, showShareButtons = false },
  pageMetadata,
}: IProps) => {
  const textContent = _rawText || text;
  const siteSettings = useSiteSettings();
  let isPressReleasePage = false;

  if (typeof window !== `undefined` && window?.location?.pathname) {
    isPressReleasePage = window.location.pathname.includes("/press-releases/");
  }

  return (
    <SliceConfig config={sliceConfig}>
      <div className={cn(styles.container)}>
        <LayoutProvider grid>
          {showShareButtons && (
            <div className={styles.shareButtonsContainer}>
              <ShareButtons
                className={styles.shareButtons}
                heading={pageMetadata?.heading}
                description={pageMetadata?.description}
              />
            </div>
          )}

          <div className={styles.text}>
            <PortableTextRenderer
              rawText={textContent}
              siteSettings={siteSettings}
              gatsbyImageComponent={GatsbyImage}
              getGatsbyImageData={getGatsbyImageData}
              showShareButtons={showShareButtons}
              pageMetadata={pageMetadata}
              isPressReleasePage={isPressReleasePage}
            />
          </div>
        </LayoutProvider>
      </div>
    </SliceConfig>
  );
};

export default RichText;
