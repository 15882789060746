import React, { useState } from "react";
import cn from "classnames";
import { SVG, LayoutProvider } from "@ottomotors/ottomotors-common/components";
import {
  Slider,
  SliceConfig,
  TextLockup,
  NumberCountingAnimation,
  VideoPlay,
} from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsProductDescription } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsProductDescription;
}

const ProductDescription = ({
  data: { textLockup, heroMedia, metrics, sliceConfig },
}: IProps) => {
  const [measurementUnit, setMeasurementUnit] = useState("metric");

  // check if any variable metrics have been added
  const hasVariableMetrics = metrics?.lineItems?.some(
    (lineItem) => lineItem?.metric || lineItem?.imperial
  );

  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.container}>
        <LayoutProvider grid>
          {(heroMedia?.image || heroMedia?.video) && (
            <figure className={styles.media}>
              {(heroMedia?.video?.url && (
                <VideoPlay src={heroMedia?.video.url} />
              )) || (
                <>
                  {heroMedia?.image?.asset && (
                    <img
                      src={heroMedia.image.asset.url}
                      alt={heroMedia.image.altText}
                    />
                  )}
                </>
              )}
            </figure>
          )}
          <div className={styles.content}>
            {textLockup && <TextLockup data={textLockup} fullWidth />}

            {metrics?.lineItems?.[0] && (
              <div>
                {metrics?.heading && (
                  <p className={cn("label", styles.metricsHeading)}>
                    {metrics.heading}
                  </p>
                )}

                {hasVariableMetrics && (
                  <div className={styles.slider}>
                    <Slider
                      uncheckedText="metric"
                      checkedText="imperial"
                      onToggleWithText={setMeasurementUnit}
                    />
                  </div>
                )}

                <ul className={styles.metrics}>
                  {metrics.lineItems.map((lineItem) => {
                    const {
                      _key,
                      _type,
                      icon,
                      key,
                      value,
                      metric,
                      metricUnit,
                      imperial,
                      imperialUnit,
                    } = lineItem;
                    const specs = {
                      metric: {
                        value: metric,
                        unit: metricUnit,
                      },
                      imperial: {
                        value: imperial,
                        unit: imperialUnit,
                      },
                    };

                    if (
                      _type === "ottomotors.productMetrics.lineItemDimensions"
                    ) {
                      const {
                        icon,
                        metricWidth,
                        metricHeight,
                        metricDepth,
                        metricUnit,
                        imperialWidth,
                        imperialHeight,
                        imperialDepth,
                        imperialUnit,
                      } = lineItem;

                      const dimensions = {
                        metric: {
                          width: metricWidth,
                          height: metricHeight,
                          depth: metricDepth,
                          unit: metricUnit,
                        },
                        imperial: {
                          width: imperialWidth,
                          height: imperialHeight,
                          depth: imperialDepth,
                          unit: imperialUnit,
                        },
                      };
                      return (
                        <li
                          key={`product-description-metric-${_key}`}
                          className={styles.lineItem}
                        >
                          <div className={styles.lineItemKeyContainer}>
                            {icon && (
                              <figure className={styles.lineItemIcon}>
                                <SVG svg={icon} />
                              </figure>
                            )}

                            <p className={cn("caption", styles.lineItemKey)}>
                              {key}
                            </p>
                          </div>

                          <p className={cn("caption", styles.lineItemValue)}>
                            <span>
                              <NumberCountingAnimation
                                end={dimensions[measurementUnit].width}
                                increment={0.1}
                                duration={0.2}
                              />

                              <span> x </span>
                              <NumberCountingAnimation
                                end={dimensions[measurementUnit].height}
                                increment={0.1}
                                duration={0.2}
                              />
                              <span> x </span>
                              <NumberCountingAnimation
                                end={dimensions[measurementUnit].depth}
                                increment={0.1}
                                duration={0.2}
                              />
                              <span>{dimensions[measurementUnit].unit}</span>
                            </span>
                          </p>
                        </li>
                      );
                    }

                    return (
                      <li
                        key={`product-description-metric-${_key}`}
                        className={styles.lineItem}
                      >
                        <div className={styles.lineItemKeyContainer}>
                          {icon && (
                            <figure className={styles.lineItemIcon}>
                              <SVG svg={icon} />
                            </figure>
                          )}

                          <p className={cn("caption", styles.lineItemKey)}>
                            {key}
                          </p>
                        </div>

                        <p className={cn("caption", styles.lineItemValue)}>
                          {(value && value) || (
                            <>
                              <NumberCountingAnimation
                                end={specs[measurementUnit].value}
                                increment={0.1}
                                duration={0.2}
                              />
                              <span>{specs[measurementUnit].unit}</span>
                            </>
                          )}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </LayoutProvider>
      </div>
    </SliceConfig>
  );
};

export default ProductDescription;
