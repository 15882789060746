// extracted by mini-css-extract-plugin
export var circleSvg = "styles-module--circleSvg--fe953";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var playButton = "styles-module--playButton--c7d5b";
export var playButtonContent = "styles-module--playButtonContent--6ac3c";
export var playSvg = "styles-module--playSvg--a8330";
export var playText = "styles-module--playText--8fd3d";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";