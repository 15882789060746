import { useEffect, useState } from "react";

// This is a magic number that matches the template media queries in Pardot
const IFRAME_BREAKPOINT = 436;

const useIframeResizer = (
    iframeWidth,
    embed, // The entire embed string, which includes the src from the CMS
    embedHeightDesktop,
    embedHeightMobile
) => {
    const [iframeDimensions, setIframeDimensions] = useState({
        width: "100%",
        height: embedHeightDesktop,
    });

    const [iframeSrc, setIframeSrc] = useState("");

    // Function to extract the src attribute from the embed string
    const extractSrcFromEmbed = (embedString) => {
        const srcRegex = /src="([^"]*)"/;
        const match = srcRegex.exec(embedString);
        return match ? match[1] : "";
    };

    // Parse the iframe src from the embed string
    useEffect(() => {
        if (embed) {
            const src = extractSrcFromEmbed(embed);
            setIframeSrc(src);
        }
    }, [embed]);

    // Updates iframe dimensions based on width and the provided breakpoints
    useEffect(() => {
        setIframeDimensions((prevDimensions) => ({
            ...prevDimensions,
            height:
                iframeWidth > IFRAME_BREAKPOINT
                    ? embedHeightDesktop
                    : embedHeightMobile,
        }));
    }, [iframeWidth, embedHeightDesktop, embedHeightMobile]);

    // A function to directly set the iframe attributes using the ref
    const updateIframeAttributes = (iframeRef) => {
        if (iframeRef && iframeRef.current) {
            iframeRef.current.width = "100%"; // Hardcoded width as 100%
            iframeRef.current.height = iframeDimensions.height; // Dynamic height
        }
    };

    return { iframeDimensions, iframeSrc, updateIframeAttributes };
};

export default useIframeResizer;
