import React from "react";
import * as styles from "./styles.module.scss";

const AcceptCookiesMessage = ({
  className = "b1",
  text = "watch this video",
}: {
  className?: string;
  text?: string;
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    if (window.Cookiebot?.renew) window.Cookiebot?.renew();
  };
  return (
    <div className={`${styles.container} ${className ? className : ""}`}>
      Please&nbsp;<a onClick={handleClick}>allow all cookies</a>
      &nbsp;to&nbsp;{text}.
    </div>
  );
};

export default AcceptCookiesMessage;
