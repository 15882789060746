// extracted by mini-css-extract-plugin
export var button = "styles-module--button--051f4";
export var closeButton = "styles-module--closeButton--22d32";
export var closeButtonSvg = "styles-module--closeButtonSvg--c4e4a";
export var container = "styles-module--container--5279d";
export var desktop = "1340px";
export var filters = "styles-module--filters--e38de";
export var giant = "2200px";
export var grid = "styles-module--grid--92851";
export var header = "styles-module--header--ee07c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var open = "styles-module--open--3e63b";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";