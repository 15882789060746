import React from "react";
import { NumberCountingAnimation } from "@ottomotors/ottomotors.com/components";
import { SVG } from "@ottomotors/ottomotors-common/components";
import { useInView } from "react-intersection-observer";
import { IOttoMotorsKeyStatisticsCard } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsKeyStatisticsCard;
  delay?: number;
}

const Card = ({ data, delay = 0 }: IProps) => {
  const { ref, inView, entry } = useInView();

  const { heading, subheading, text, animatedIcon } = data || {};
  const prefix = heading?.prefix ?? "";
  const value = heading?.value ?? "";
  const suffix = heading?.suffix ?? "";
  let title = "";
  let coloredText = "";

  title = `${prefix}${value}${suffix}`;

  if (text) {
    coloredText = text.replace(
      /\*\*\*(.*?)\*\*\*/g,
      '<span style="color: #8438E8;">$1</span>'
    );
  }

  return (
    <article ref={ref} className={styles.card}>
      {(heading || subheading) && (
        <header className={styles.cardHeader}>
          <p className="h1">
            {prefix}
            {value && (
              <NumberCountingAnimation
                end={inView ? value : 0}
                increment={1}
                duration={0.5}
                delay={delay}
              />
            )}
            {suffix}
          </p>
          <p className="label">{subheading}</p>
        </header>
      )}

      {coloredText && (
        <p className="h4" dangerouslySetInnerHTML={{ __html: coloredText }} />
      )}

      {/* replace with animated icon component */}
      {animatedIcon && (
        <figure>
          <SVG svg={animatedIcon?.icon}></SVG>
        </figure>
      )}
    </article>
  );
};

export default Card;
