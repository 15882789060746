// extracted by mini-css-extract-plugin
export var body = "styles-module--body--31033";
export var container = "styles-module--container--06def";
export var contentContainer = "styles-module--contentContainer--1fbbf";
export var dark = "styles-module--dark--1c5c1";
export var desktop = "1340px";
export var expanded = "styles-module--expanded--e43e1";
export var giant = "2200px";
export var header = "styles-module--header--f6c2b";
export var heading = "styles-module--heading--cb2c1";
export var icon = "styles-module--icon--a091e";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--a9744";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";