const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [{ color: "#ffffff" }],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      { visibility: "on" },
      { color: "#f5f5f5" },
      { weight: 2 },
      { gamma: 0.84 },
    ],
  },
  {
    // highway icons
    featureType: "all",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [{ weight: 0.6 }, { color: "#f5f5f5" }],
  },
  {
    // country and city labels
    featureType: "administrative",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "administrative.country",
    elementType: "geometry",
    stylers: [{ visibility: "on" }],
  },
  {
    featureType: "administrative.country",
    elementType: "labels",
    stylers: [{ color: "#ffffff" }],
    // stylers: [{ visibility: "off" }],
  },
  // {
  // featureType: "administrative.province",
  // elementType: "labels",
  // stylers: [{ visibility: "off" }],
  // },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{ color: "#c9c9c9" }],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{ color: "#e4e4e4" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#e4e4e4" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#dedede" }, { lightness: "25" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#e4e4e4" }, { lightness: "15" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#ffffff" }],
  },
];

export default mapStyles;
