// extracted by mini-css-extract-plugin
export var container = "styles-module--container--2da86";
export var desktop = "1340px";
export var footer = "styles-module--footer--1508e";
export var footerGrid = "styles-module--footerGrid--b4222";
export var footerItem = "styles-module--footerItem--3dce7";
export var giant = "2200px";
export var header = "styles-module--header--d59ed";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--81d99";
export var mobile = "400px";
export var share = "styles-module--share--e53ad";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var tags = "styles-module--tags--76a0a";
export var videoContainer = "styles-module--videoContainer--f21b2";