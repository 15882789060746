import React, { useState } from "react";
import cn from "classnames";
import { Button } from "@ottomotors/ottomotors-common/components";

import * as styles from "./styles.module.scss";

interface IProps {
  data: any;
}

const LineItem = ({ data }: IProps) => {
  const { file, title } = data || {};

  const [isHovered, setIsHovered] = useState(false);

  const sizeInKb = Math.round(file?.asset?.size / Math.pow(1024, 1));

  return (
    <a
      className={cn("b1", styles.lineItem)}
      href={`${file?.asset?.url}?dl=`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <p className={styles.title}>{title}</p>

      {/* mobile */}
      <div className={styles.extensionAndSize}>
        <p className="caption">
          {`.${file?.asset?.extension}`} {`[${sizeInKb} KB]`}
        </p>

        <div>
          <Button
            display
            className={styles.download}
            variant="iconButton"
            icon="download"
            text="Download"
            isHovered={isHovered}
          />
        </div>
      </div>

      {/* tablet and desktop */}
      <p className={styles.extension}>{`.${file?.asset?.extension}`}</p>
      <p className={styles.size}>{`[${sizeInKb} KB]`}</p>

      <div className={styles.downloadDesktop}>
        <Button
          display
          className={styles.download}
          variant="iconButton"
          icon="download"
          text="Download"
          isHovered={isHovered}
        />
      </div>
    </a>
  );
};

export default LineItem;
