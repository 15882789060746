import React from "react";
import cn from "classnames";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import {
  CardCarousel,
  SliceConfig,
  TextLockup,
} from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsCardCarouselProduct } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsCardCarouselProduct;
}

const CardCarouselProduct = ({
  data: { cards, textLockup, sliceConfig },
}: IProps) => {
  const isInset = cards?.length <= 3;

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider grid>
          {textLockup && (
            <TextLockup className={styles.text} data={textLockup} />
          )}
          <CardCarousel
            className={cn(styles.carousel, { [styles.inset]: isInset })}
            data={{ cards }}
            variant="product"
          />
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default CardCarouselProduct;
