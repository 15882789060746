import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import {
  LayoutProvider,
  SVG,
  UniversalLink,
} from "@ottomotors/ottomotors-common/components";
import {
  SliceConfig,
  Slider,
  TextLockup,

  // LayoutProvider
} from "@ottomotors/ottomotors.com/components";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";

import { IOttoMotorsDataTable } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsDataTable;
}

// todo: @dog haven't been able to figure out how to make the <th> sticky on mobile because of the overflow: auto on .tableContainer
// ref of someone getting it working here, not sure how they've got it working though: https://codepen.io/chriscoyier/pen/yLVNErX
const DataTable = ({
  data: { sliceConfig, textLockup, table, toggleTable, button },
}: IProps) => {
  if (!table?.rows?.[0]) return;

  const { tablet } = useBreakpoints();

  const scrollableRef = useRef(null);

  const [measurementUnit, setMeasurementUnit] = useState("metric");
  const [scrollProgress, setScrollProgress] = useState(0);

  const rows = table.rows;
  const backgroundColorHex = sliceConfig?.backgroundColor?.hex;

  const isToggleable = toggleTable?.rows?.[0];

  let measurementTable;

  if (toggleTable) {
    measurementTable = {
      metric: { rows: [...toggleTable.rows] },
      imperial: { rows: [...table.rows] },
    };
  }

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef?.current) {
        const container = scrollableRef.current;
        const scrollProgress =
          container.scrollLeft /
          (container.scrollWidth - container.clientWidth);
        setScrollProgress(scrollProgress * 100);
      }
    };

    if (scrollableRef?.current) {
      scrollableRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableRef?.current) {
        scrollableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollableRef?.current]);

  const getTableJsx = () => {
    let tableRows = rows;

    if (isToggleable) {
      tableRows = measurementTable[measurementUnit].rows;
    }

    return tableRows.map(({ cells }, rowIndex) => {
      const isHeader = rowIndex === 0;
      return (
        <tr
          key={rowIndex} // It's a good practice to add a key when mapping over elements
          className={cn(styles.tableRow)}
        >
          {cells.map((text, cellIndex) => {
            const cellWidth = 100 / cells.length;
            const fixedWidth = cellIndex === 0 ? `45vw` : `35vw`;
            const cellWidthStyles = tablet
              ? { width: `${cellWidth}%` }
              : { width: fixedWidth };

            // background styles for sticky cells
            const backgroundColorStyles = {
              background: `${backgroundColorHex}`,
            };

            let showCheck;

            if (text && /\*\*\*check\*\*\*/.test(text)) {
              showCheck = true;
            }

            if (isHeader) {
              return (
                // show metric slider for the first cell in the table header
                <th
                  key={cellIndex} // Add a key here as well
                  className={cn("h4", styles.tableHeader)}
                  style={{
                    ...cellWidthStyles,
                    ...(!tablet && cellIndex === 0 && backgroundColorStyles),
                  }}
                >
                  {isToggleable && cellIndex === 0 ? (
                    <Slider
                      uncheckedText="metric"
                      checkedText="imperial"
                      onToggleWithText={setMeasurementUnit}
                    />
                  ) : (
                    text
                  )}
                </th>
              );
            }

            return (
              <td
                key={cellIndex} // Add a key here as well
                className={cn(
                  cellIndex === 0 ? "label" : "b2",
                  styles.tableData
                )}
                style={{
                  ...cellWidthStyles,
                  ...(!tablet && cellIndex === 0 && backgroundColorStyles),
                }}
              >
                {showCheck && (
                  <figure className={styles.checkSvg}>
                    <SVG svg="checkCircle" />
                  </figure>
                )}
                {!showCheck && <p>{text}</p>}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider className={styles.grid} grid>
          {textLockup && (
            <TextLockup className={styles.text} data={textLockup} />
          )}

          <div className={styles.progressBarContainer}>
            <div className={styles.progress}>
              <div
                className={styles.progressBar}
                style={{
                  width: `${scrollProgress}%`,
                }}
              />
            </div>

            <p className={styles.progressBarText}>
              <span className="label">Swipe</span>
              <figure className={styles.progressBarSvg}>
                <SVG svg="doubleChevronRight" />
              </figure>
            </p>
          </div>

          <div ref={scrollableRef} className={cn(styles.tableContainer, {})}>
            <table
              className={cn(styles.table, {})}
              // onScroll={() => console.log("scrollin")}
            >
              {getTableJsx()}
            </table>
          </div>

          {button && (
            <div className={styles.buttonContainer}>
              {button?.text && button?.link?.[0] && (
                <UniversalLink
                  link={button}
                  iconRight={"arrowRight"}
                  iconLeft={"arrowRight"}
                  animateOnHover
                  fluid={tablet ? false : true}
                />
              )}
            </div>
          )}
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default DataTable;
