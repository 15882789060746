import React, { useRef } from "react";
import cn from "classnames";
import { PlayButton, SliceConfig } from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsMedia } from "@ottomotors/ottomotors-sanity";
import { useApp } from "@ottomotors/ottomotors.com/hooks";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsMedia;
}

// todo: @dog I think this is legacy component from the boilerplate that we might not need anymore.
const Media = ({ data: { sliceConfig, media } }: IProps) => {
  const { setLightboxSource } = useApp();
  const videoRef = useRef(null);

  return (
    <SliceConfig config={sliceConfig}>
      <section
        className={cn(styles.container, { [styles.video]: media?.video?.url })}
      >
        <figure className={styles.media}>
          {(media?.video?.url && (
            <video ref={videoRef} autoPlay muted loop playsInline>
              <source src={media.video.url} type="video/mp4" />
            </video>
          )) || (
            <>
              {media?.image?.asset && (
                <img src={media.image.asset.url} alt={media.image.alt} />
              )}
            </>
          )}
        </figure>

        {media?.video?.url && (
          <PlayButton
            className={styles.playButton}
            onClick={() => setLightboxSource(media.video.url)}
          />
        )}
      </section>
    </SliceConfig>
  );
};

export default Media;
