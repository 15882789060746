import React, { useEffect, useState } from "react";
import cn from "classnames";
import {
  Carousel,
  CarouselNav,
  LayoutProvider,
} from "@ottomotors/ottomotors-common/components";
import { SliceConfig } from "@ottomotors/ottomotors.com/components";
import Card from "./components/Card";
import useEmblaCarousel from "embla-carousel-react";
import { IOttoMotorsGalleryWithTextOverlay } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsGalleryWithTextOverlay;
}

const GalleryWithTextOverlay = ({
  data: { _key, sliceTheme, media, cards, sliceConfig },
}: IProps) => {
  if (!cards?.[0]) return null;

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: `start`,
    loop: true,
  });

  const [selectedIndex, setSelectedIndex] = useState(0);

  let theme = "light";
  let themedSliceConfig;

  const isThemedVariant = !media?.image?.asset && !media?.video?.url;

  const themes = {
    dark: {
      backgroundColor: { hex: `#1E1E1E` },
      color: { hex: `#EFEFEF` },
    },
    light: {
      backgroundColor: { hex: `#DEDEDE` },
      color: { hex: `#1E1E1E` },
    },
  };

  if (isThemedVariant) {
    theme = sliceTheme?.theme || "dark";
    themedSliceConfig = themes[theme];
  }

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", () => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  return (
    <SliceConfig config={isThemedVariant ? themedSliceConfig : sliceConfig}>
      <section
        className={cn(styles.container, { [styles.themed]: isThemedVariant })}
      >
        {(media?.image?.asset || media?.video?.url) && (
          <figure className={styles.media}>
            {(media?.video?.url && (
              <video autoPlay muted loop playsInline>
                <source src={media.video.url} type="video/mp4" />
              </video>
            )) || (
              <>
                {media?.image?.asset && (
                  <img src={media.image.asset.url} alt={media.image.alt} />
                )}
              </>
            )}
          </figure>
        )}

        <LayoutProvider className={styles.grid} grid>
          <div
            className={cn(styles.content, {
              [styles.center]: isThemedVariant,
              [styles.dark]: isThemedVariant && theme === "dark",
              [styles.light]: isThemedVariant && theme === "light",
            })}
          >
            {(cards?.[1] && (
              <>
                <Carousel
                  embla={{
                    api: emblaApi,
                    ref: emblaRef,
                  }}
                  slidesPerView={1}
                  spaceBetween={16}
                  slides={() =>
                    cards.map((card, index) => (
                      <Card
                        key={card?._key || card?._id}
                        data={card}
                        theme={theme}
                        isThemed={isThemedVariant}
                      />
                    ))
                  }
                />

                <CarouselNav
                  embla={emblaApi}
                  pagination
                  paginationBorders={false}
                  selectedIndex={selectedIndex}
                  items={cards}
                  theme={theme === "light" ? "dark" : "light"}
                  loop
                />
              </>
            )) ||
              cards?.map((card) => {
                let i;

                return (
                  <Card
                    key={card?._key || card?._id}
                    theme={theme}
                    isThemed={isThemedVariant}
                    data={card}
                  />
                );
              })}
          </div>
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default GalleryWithTextOverlay;
