import React, { useState } from "react";
import cn from "classnames";
import { TextInput } from "@ottomotors/ottomotors-common/components";
import { Autocomplete } from "@react-google-maps/api";

import * as styles from "./styles.module.scss";

interface IProps {
  setSearchedPlace: React.Dispatch<
    React.SetStateAction<google.maps.places.PlaceResult | undefined>
  >;
  className?: string;
  placeholder?: string;
}

const SearchAutocomplete = ({
  setSearchedPlace,
  placeholder,
  className,
}: IProps) => {
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);
  const [autocompleteData, setAutocompleteData] =
    useState<google.maps.places.Autocomplete | null>(null);

  const handlePlaceChanged = () => {
    const place = autocompleteData?.getPlace();
    if (place && place.geometry) {
      setSearchedPlace(place);
    } else if (autocompleteService && autocompleteData && place?.name) {
      autocompleteService.getPlacePredictions(
        {
          input: place.name,
          types: [
            "postal_code",
            "country",
            "administrative_area_level_1",
            "locality",
          ],
        },
        (predictions, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            predictions &&
            predictions.length > 0
          ) {
            const firstPrediction = predictions[0];

            const placesService = new google.maps.places.PlacesService(
              document.createElement("div")
            );
            placesService.getDetails(
              { placeId: firstPrediction.place_id },
              (placeDetails, status) => {
                if (
                  status === google.maps.places.PlacesServiceStatus.OK &&
                  placeDetails
                ) {
                  setSearchedPlace(placeDetails);
                }
              }
            );
          }
        }
      );
    }
  };

  const handleChange = (value: string) => {
    if (!value) {
      setSearchedPlace(undefined);
    }
  };

  return (
    <Autocomplete
      className={cn(styles.container, className)}
      onLoad={(autocomplete) => {
        setAutocompleteData(autocomplete);
        setAutocompleteService(new google.maps.places.AutocompleteService());
      }}
      onPlaceChanged={handlePlaceChanged}
    >
      <TextInput
        placeholder={placeholder}
        onChange={handleChange}
        hasSearchIcon
      />
    </Autocomplete>
  );
};

export default SearchAutocomplete;
