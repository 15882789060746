import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { LoadScript } from "@react-google-maps/api";
import DealerCard from "./components/DealerCard";
import SearchAutocomplete from "./components/SearchAutocomplete";
import Map, { IPlace } from "./components/Map";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import { SliceConfig } from "@ottomotors/ottomotors.com/components";
import {
  calculateCenterCoordinates,
  calculateDistanceBetweenGeolocations,
  distanceInKmBetweenEarthCoordinates,
} from "@ottomotors/ottomotors-common/utils";
import {
  IOttoMotorsDealer,
  IOttoMmotorsDealerMap,
  IGeopoint,
} from "@ottomotors/ottomotors-sanity";
import {
  useBreakpoints,
  useGeolocation,
} from "@ottomotors/ottomotors-common/hooks";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMmotorsDealerMap;
}

type TContinent = {
  [key: string]: string[];
};

type TContinentSearchRadius = {
  [key: string]: number;
};

// search radius in km
const SEARCH_RADIUS_KM = 20;

const CONTINENT_SEARCH_RADIUS: TContinentSearchRadius = {
  Europe: 2500,
  Asia: 5000,
  Africa: 3500,
  "North America": 4000,
  "South America": 2500,
  Oceania: 2000,
  Antarctica: 2000,
};

const CONTINENTS: TContinent = {
  Africa: [
    "AO",
    "BF",
    "BI",
    "BJ",
    "BW",
    "CD",
    "CF",
    "CG",
    "CI",
    "CM",
    "CV",
    "DJ",
    "DZ",
    "EG",
    "EH",
    "ER",
    "ET",
    "GA",
    "GH",
    "GM",
    "GN",
    "GQ",
    "GW",
    "KE",
    "KM",
    "LR",
    "LS",
    "LY",
    "MA",
    "MG",
    "ML",
    "MR",
    "MU",
    "MW",
    "MZ",
    "NA",
    "NE",
    "NG",
    "RE",
    "RW",
    "SC",
    "SD",
    "SH",
    "SL",
    "SN",
    "SO",
    "ST",
    "SZ",
    "TD",
    "TG",
    "TN",
    "TZ",
    "UG",
    "ZA",
    "ZM",
    "ZW",
  ],
  Antarctica: ["AQ", "GS"],
  Asia: [
    "AE",
    "AF",
    "AM",
    "AZ",
    "BD",
    "BH",
    "BN",
    "BT",
    "CC",
    "CN",
    "CY",
    "CX",
    "GE",
    "HK",
    "ID",
    "IL",
    "IN",
    "IO",
    "IQ",
    "IR",
    "JO",
    "JP",
    "KG",
    "KH",
    "KP",
    "KR",
    "KW",
    "KZ",
    "LA",
    "LB",
    "LK",
    "MM",
    "MN",
    "MO",
    "MV",
    "MY",
    "NP",
    "OM",
    "PH",
    "PK",
    "PS",
    "QA",
    "SA",
    "SG",
    "SY",
    "TH",
    "TJ",
    "TM",
    "TR",
    "TW",
    "UZ",
    "VN",
    "YE",
  ],
  Europe: [
    "AD",
    "AL",
    "AT",
    "BA",
    "BE",
    "BG",
    "BY",
    "CH",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FO",
    "FR",
    "GB",
    "GG",
    "GI",
    "GR",
    "HR",
    "HU",
    "IE",
    "IM",
    "IS",
    "IT",
    "JE",
    "LI",
    "LT",
    "LU",
    "LV",
    "MC",
    "MD",
    "ME",
    "MK",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "RS",
    "RU",
    "SE",
    "SI",
    "SJ",
    "SK",
    "SM",
    "UA",
    "VA",
  ],
  "North America": [
    "AG",
    "AI",
    "AN",
    "AW",
    "BB",
    "BM",
    "BS",
    "BZ",
    "CA",
    "CR",
    "CU",
    "DM",
    "DO",
    "GD",
    "GL",
    "GP",
    "GT",
    "HT",
    "HN",
    "JM",
    "KN",
    "KY",
    "LC",
    "MQ",
    "MS",
    "MX",
    "NI",
    "PA",
    "PM",
    "PR",
    "SV",
    "TC",
    "TT",
    "US",
    "VC",
    "VG",
    "VI",
  ],
  Oceania: [
    "AU",
    "AS",
    "CK",
    "FJ",
    "FM",
    "GU",
    "KI",
    "MH",
    "MP",
    "NC",
    "NF",
    "NR",
    "NU",
    "NZ",
    "PF",
    "PG",
    "PN",
    "PW",
    "SB",
    "TK",
    "TO",
    "TV",
    "VU",
    "WF",
    "WS",
  ],
  "South America": [
    "AR",
    "BO",
    "BR",
    "CL",
    "CO",
    "EC",
    "FK",
    "GF",
    "GY",
    "PE",
    "PY",
    "SR",
    "UY",
    "VE",
  ],
};

const DealerMap = ({ data: { heading, sliceConfig } }: IProps) => {
  const {
    allSanityOttomotorsDealer: { nodes: dealers },
  }: IQueryData = useStaticQuery(query);

  if (!dealers?.[0]) return;

  const { location: userGeolocation } = useGeolocation();
  const { largeTablet } = useBreakpoints();

  const mapRef = useRef(null);

  const [scriptHasLoaded, setScriptHasLoaded] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<IPlace | null>(null);
  const [centerCoordinates, setCenterCoordinates] = useState<
    IGeopoint | undefined
  >(undefined);
  const [showNoResults, setShowNoResults] = useState<boolean>(false);
  const [searchedPlace, setSearchedPlace] =
    useState<google.maps.places.PlaceResult>();

  const apiKey = process.env.GATSBY_GOOGLE_MAPS_API; // dev key

  const filterDealersBySearchRadius = (searchRadius: number) => {
    if (!searchedPlace) {
      return dealers;
    }

    const searchedPlaceLat = searchedPlace.geometry?.location?.lat() || 0;
    const searchedPlaceLng = searchedPlace.geometry?.location?.lng() || 0;

    const filteredDealers = dealers.filter((dealer) => {
      const dealerLocationLat = dealer?.location?.lat;
      const dealerLocationLng = dealer?.location?.lng;

      const distanceFromSearchedPlaceKm = distanceInKmBetweenEarthCoordinates(
        searchedPlaceLat,
        searchedPlaceLng,
        dealerLocationLat,
        dealerLocationLng
      );

      if (distanceFromSearchedPlaceKm <= searchRadius) {
        return true;
      } else {
        return false;
      }
    });

    return filteredDealers;
  };

  const filterDealersByState = () => {
    if (!searchedPlace) {
      return dealers;
    }

    const searchedPlaceState = searchedPlace.address_components?.filter(
      ({ types }) => types.includes("administrative_area_level_1")
    )?.[0]?.short_name;

    const filteredDealers = dealers.filter((dealer) => {
      const dealerState = dealer?.address?.state;

      if (searchedPlaceState?.toLowerCase() === dealerState.toLowerCase()) {
        return true;
      } else {
        return false;
      }
    });

    return filteredDealers;
  };

  const filterDealersByContinent = () => {
    if (!searchedPlace) {
      return dealers;
    }

    // get the country part from the address of the searched place
    const countryComponent = searchedPlace.address_components?.find(
      ({ types }) => types.includes("country")
    );

    // get the country code of the searched place
    const searchedCountryCode = countryComponent?.short_name;

    if (!searchedCountryCode) {
      console.log("searched country code not found");
      return;
    }

    // get the continent of the country code from the defined CONTINENTS object
    const searchedContinent = Object.keys(CONTINENTS).find((continent) =>
      CONTINENTS[continent].includes(searchedCountryCode)
    );

    if (!searchedContinent) {
      console.log("searched continent not found");
      return;
    }

    // get the continent's search radius from the defined CONTINENTS_SEARCH_RADIUS object
    const searchRadius = CONTINENT_SEARCH_RADIUS[searchedContinent];

    // get dealers within that search radius
    const dealersWithinSearchRadius = filterDealersBySearchRadius(searchRadius);

    return dealersWithinSearchRadius;
  };

  const getFilteredDealers = () => {
    if (!searchedPlace) {
      return dealers;
    }

    const dealersByState = filterDealersByState();
    const filteredDealers = dealersByState?.[0]
      ? dealersByState
      : filterDealersByContinent();

    if (!filteredDealers?.[0]) {
      return [];
    }

    return filteredDealers;
  };

  const filteredDealers = getFilteredDealers();
  const dealerLocations = filteredDealers?.[0] ? filteredDealers : dealers;

  const locations: IPlace[] = dealerLocations.map((dealer) => {
    const {
      address: { postcode, state, streetAddress, city },
      buttonText,
      contactOverlayOverride,
    } = dealer || {};
    return {
      _id: dealer._id,
      address: `${streetAddress}\n${city}, ${state} ${postcode}`,
      location: dealer.location,
      name: dealer.title,
      distance: calculateDistanceBetweenGeolocations(
        userGeolocation,
        dealer.location
      ),
      buttonText,
      contactOverlayOverride,
    };
  });

  locations.sort((a, b) => a.distance - b.distance);

  const handleOnDealerClick = (dealer: IPlace) => {
    setSelectedPlace(dealer);

    if (mapRef.current && !largeTablet) {
      const containerOffsetTop =
        mapRef.current.getBoundingClientRect().top + window?.pageYOffset;

      window.scrollTo({
        top: containerOffsetTop - 80,
        behavior: "smooth",
      });
    }
  };

  // set center coordinates of map based on the searched place
  useEffect(() => {
    const dealersByState = filterDealersByState();
    const filteredDealers = dealersByState?.[0]
      ? dealersByState
      : filterDealersByContinent();

    const calculatedCoordinates = calculateCenterCoordinates(locations);

    const centerCoordinates =
      searchedPlace && filteredDealers?.[0] ? calculatedCoordinates : undefined;

    if (!filteredDealers?.[0]) {
      setShowNoResults(true);
    } else {
      setShowNoResults(false);
    }

    setCenterCoordinates(centerCoordinates);
  }, [searchedPlace]);

  return (
    <LoadScript
      googleMapsApiKey={apiKey}
      libraries={["places", "geometry"]}
      onLoad={() => setScriptHasLoaded(true)}
    >
      <SliceConfig config={sliceConfig}>
        <section className={styles.container}>
          <LayoutProvider className={styles.grid} grid>
            <div className={styles.content}>
              {heading && <h3 className="h3">{heading}</h3>}

              {/* search */}
              <div className={styles.searchContainer}>
                <SearchAutocomplete
                  className={styles.search}
                  placeholder="Search by postcode/zipcode"
                  setSearchedPlace={setSearchedPlace}
                />
              </div>
            </div>

            {searchedPlace && !showNoResults && (
              <p className={cn("b1", styles.message)}>
                Closest Dealers
                <span>
                  {` to `}
                  <span className={styles.messageAddress}>
                    {searchedPlace?.formatted_address}
                  </span>
                  .
                </span>
              </p>
            )}

            {showNoResults && (
              <p className={cn("b1", styles.message)}>
                No Dealers found
                <span>
                  {` at `}
                  <span className={styles.messageAddress}>
                    {searchedPlace?.formatted_address}
                  </span>
                </span>
                {`, try another postcode.`}
              </p>
            )}

            <div className={styles.dealers}>
              {locations?.[0] &&
                locations.map((location) => {
                  return (
                    <DealerCard
                      key={`dealer-map-dealer-${location._id}`}
                      className={styles.dealer}
                      data={location}
                      setSelectedPlace={handleOnDealerClick}
                      userGeolocation={userGeolocation}
                    />
                  );
                })}
            </div>

            <div ref={mapRef} id="dealer-map" className={styles.map}>
              <Map
                locations={locations}
                scriptHasLoaded={scriptHasLoaded}
                centerCoordinates={centerCoordinates}
                searchRadius={SEARCH_RADIUS_KM}
                selectedPlace={selectedPlace}
                setSelectedPlace={setSelectedPlace}
              />
            </div>
          </LayoutProvider>
        </section>
      </SliceConfig>
    </LoadScript>
  );
};

export default DealerMap;

/**
 * GraphQL ====================================================================
 */

interface IQueryData {
  allSanityOttomotorsDealer: {
    nodes: IOttoMotorsDealer[];
  };
}

const query = graphql`
  query {
    allSanityOttomotorsDealer {
      nodes {
        _id
        title
        address {
          streetAddress
          city
          state
          postcode
        }
        location {
          lat
          lng
        }
        buttonText
        contactOverlayOverride {
          embed
          embedHeightDesktop
          embedHeightMobile

          textLockup {
            body
            bodyTypestyle
            heading
            isPageHeading
            headingTypestyle
            subheading
            caption
            captionTypestyle
            buttonPrimary {
              ...UniversalLinkWebsiteFragment
            }
            buttonSecondary {
              ...UniversalLinkWebsiteFragment
            }
            theme {
              theme
            }
          }
        }
      }
    }
  }
`;
