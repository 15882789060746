import React, { useEffect, useState } from "react";
import cn from "classnames";

import * as styles from "./styles.module.scss";

interface IProps {
  checkedText: string;
  uncheckedText: string;
  theme?: "darkOnLight" | "lightOnDark";
  onToggle?: () => void;
  onToggleWithText?: (text: string) => void;
}

const Slider = ({
  checkedText,
  uncheckedText,
  onToggle,
  onToggleWithText,
  theme = "lightOnDark",
}: IProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (onToggle) {
      onToggle();
    }

    if (onToggleWithText) {
      const text = isChecked ? checkedText : uncheckedText;

      onToggleWithText(text);
    }
  }, [isChecked]);

  return (
    <div
      className={cn(styles.container, {
        [styles.dark]: theme === "darkOnLight",
      })}
    >
      <label className={styles.sliderContainer}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span className={styles.slider}></span>
      </label>

      <p className="label">{isChecked ? checkedText : uncheckedText}</p>
    </div>
  );
};

export default Slider;
