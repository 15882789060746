import React from "react";
import {
  IUniversalLinkToolkit,
  IUniversalLinkOttoMotors,
} from "@ottomotors/ottomotors-sanity";
import { useApp } from "@ottomotors/ottomotors.com/hooks";
import { Button, type IButton } from "@ottomotors/ottomotors-common/components";
import { getUniversalLinkUrl } from "@ottomotors/ottomotors-common/utils";

type UniversalLink = IUniversalLinkToolkit | IUniversalLinkOttoMotors;

type UniversalLinkExtended = {
  [K in keyof UniversalLink]: UniversalLink[K];
};

type TRequiredButtonProps = Omit<
  IButton,
  "text" | "buttonType" | "to" | "href" | "onClick" | "display" | "loading"
>;

type TProps = {
  link: UniversalLinkExtended;
} & TRequiredButtonProps;

const UniversalLink = ({
  link: universalLink,
  className,
  disabled,
  fluid,
  iconLeft,
  iconRight,
  animateOnHover,
  variant,
  theme,
  typestyle,
  alignText,
  isHovered,
}: TProps) => {
  const buttonProps = {
    className,
    disabled,
    fluid,
    iconLeft,
    iconRight,
    animateOnHover,
    variant,
    theme: theme,
    typestyle,
    alignText,
    isHovered,
  };

  const { setLightboxSource } = useApp();

  const link = universalLink.link[0];
  const text = universalLink.text;
  const video = universalLink?.video;
  const addAnchorPoint = universalLink?.addAnchorPoint;
  const anchorPointId = universalLink?.anchorPointId;
  const params = universalLink?.params;
  const isExternal = link?._type === "externalUrl";

  let paramsSuffix = "";

  const handleAnchorClick = (anchor: string) => {
    const element = document.getElementById(anchor.toLowerCase().replace(/[^0-9a-z]/gi, ''));
    if (!element) return;
    element.scrollIntoView({
      behavior: 'smooth'
    });
  };

  if (params?.[0]) {
    paramsSuffix = params.map(
      (param, index) => `${index === 0 ? `?` : `&`}${param.key}=${param.value}`
    );
  }

  if (video?.url) {
    return (
      <Button
        text={text}
        onClick={() => setLightboxSource(video.url)}
        {...buttonProps}
      />
    );
  }

  if (addAnchorPoint && anchorPointId) {
    // using anchor point slice on the same page
    return (
      <Button
        text={text}
        onClick={() => handleAnchorClick(anchorPointId)}
        {...buttonProps}
      />
    );
  }

  const universalLinkUrl = getUniversalLinkUrl(universalLink.link);
  const url = `${universalLinkUrl}${paramsSuffix}`;

  return (
    <Button
      text={text}
      href={isExternal ? url : ""}
      to={!isExternal ? url : ""}
      {...buttonProps}
    />
  );
};

export default UniversalLink;
