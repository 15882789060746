import React from "react";
import {
  Button,
  UniversalLink,
} from "@ottomotors/ottomotors-common/components";
import { IRichTextButtonGroup } from "@ottomotors/ottomotors-sanity";
import {
  IUniversalLinkOttoMotors,
  IOttoMotorsFileDownloadButton,
} from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

export interface IProps {
  data: IRichTextButtonGroup;
}

const PortableTextButtonGroup = ({ data }: IProps) => {
  const { buttonPrimary, buttonSecondary } = data || {};

  if (!buttonPrimary?.[0] && !buttonSecondary?.[0]) return null;

  const getButtonJsx = (
    button: IUniversalLinkOttoMotors | IOttoMotorsFileDownloadButton,
    variant?: "primary" | "secondary"
  ) => {
    switch (button?._type) {
      case "universalLinkOttomotors":
        return (
          <UniversalLink
            className={styles.button}
            link={button}
            variant={variant}
            iconRight="arrowRight"
            iconLeft="arrowRight"
            animateOnHover
          />
        );

      case "ottomotors.fileDownloadButton":
        if ("file" in button) {
          // Check if button has 'file' property
          return (
            <Button
              className={styles.button}
              href={button?.file?.asset?.url}
              text={button?.text}
              variant={variant}
              iconRight="arrowRight"
              iconLeft="arrowRight"
              animateOnHover
            />
          );
        } else {
          return null; // Handle case where button does not have 'file' property
        }

      default:
        return <></>;
    }
  };

  return (
    <div className={styles.container}>
      {buttonPrimary?.[0] && (
        <div>{getButtonJsx(buttonPrimary[0], "primary")}</div>
      )}

      {buttonSecondary?.[0] && (
        <div>{getButtonJsx(buttonSecondary[0], "secondary")}</div>
      )}
    </div>
  );
};

export default PortableTextButtonGroup;
