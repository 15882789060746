import React from "react";
import { SVG } from "@ottomotors/ottomotors-common/components";
import cn from "classnames";
// import { ISliceConfig } from "@ottomotors/ottomotors-sanity";

import { IOttoMotorsIconTextLockup } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsIconTextLockup;
  className?: string;
}

const IconTextLockup = ({ data, className }: IProps) => {
  const { body, heading, icon } = data || {};

  return (
    <div className={cn(styles.container, className)}>
      <figure className={styles.icon}>
        <SVG svg={icon?.icon} />
      </figure>
      <div className={styles.content}>
        {heading && <p className="b1">{heading}</p>}
        {body && <p className="b2">{body}</p>}
      </div>
    </div>
  );
};

export default IconTextLockup;
