import React from "react";
import cn from "classnames";
import {
  ChecklistItem,
  UniversalLink,
} from "@ottomotors/ottomotors-common/components";
import { IOttoMotorsCardCarouselPlansCard } from "@ottomotors/ottomotors-sanity";
import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsCardCarouselPlansCard;
}

interface ICardColors {
  color?: string;
  backgroundColor?: string;
}

const PlanCard = ({ data }: IProps) => {
  const {
    highlightTabText,
    heading,
    body,
    sellingPoints,
    button,
    backgroundColor,
    textColor,
  } = data || {};

  const colorStyles: ICardColors = {};
  if (textColor?.hex) {
    colorStyles.color = textColor.hex;
  }
  if (backgroundColor?.hex) {
    colorStyles.backgroundColor = backgroundColor.hex;
  }

  return (
    <article
      style={colorStyles}
      className={cn(styles.container, {
        [styles.highlighted]: highlightTabText,
      })}
    >
      {highlightTabText && (
        <p className={cn("label", styles.highlight)}>{highlightTabText}</p>
      )}

      {(heading || body) && (
        <>
          <div className={styles.content}>
            <header className={styles.header}>
              <h4 className="h4">{heading}</h4>
            </header>

            {body && <p className="b1">{body}</p>}
          </div>

          {sellingPoints?.[0] && (
            <ul className={styles.sellingPoints}>
              {sellingPoints?.map((sellingPoint) => {
                return (
                  <li>
                    <ChecklistItem text={sellingPoint} />
                  </li>
                );
              })}
            </ul>
          )}

          {button && (
            <div className={styles.buttonContainer}>
              <UniversalLink
                link={button}
                iconRight={"arrowRight"}
                iconLeft={"arrowRight"}
                variant="secondary"
                fluid
                animateOnHover
              />
            </div>
          )}
        </>
      )}
    </article>
  );
};

export default PlanCard;
