// extracted by mini-css-extract-plugin
export var buttonContainer = "styles-module--buttonContainer--73c9a";
export var container = "styles-module--container--41371";
export var content = "styles-module--content--2a77e";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--660e9";
export var image = "styles-module--image--f24e7";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";