// extracted by mini-css-extract-plugin
export var button = "styles-module--button--f3b0f";
export var buttonContainer = "styles-module--buttonContainer--8d47f";
export var desktop = "1340px";
export var field = "styles-module--field--96d03";
export var fieldError = "styles-module--fieldError--fbecf";
export var form = "styles-module--form--c22c3";
export var giant = "2200px";
export var label = "styles-module--label--178aa";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var newsletterField = "styles-module--newsletterField--ef963";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var submissionError = "styles-module--submissionError--5b911";
export var tablet = "768px";
export var title = "styles-module--title--4aadb";