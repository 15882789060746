// extracted by mini-css-extract-plugin
export var caption = "styles-module--caption--4138f";
export var carousel = "styles-module--carousel--8252d";
export var carouselNav = "styles-module--carouselNav--2b8a6";
export var container = "styles-module--container--cadf5";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--c8d0f";
export var media = "styles-module--media--2e6be";
export var mediaContainer = "styles-module--mediaContainer--e3d4e";
export var mobile = "400px";
export var navButton = "styles-module--navButton--44e57";
export var navButtons = "styles-module--navButtons--23a93";
export var pagination = "styles-module--pagination--ecfea";
export var portrait = "styles-module--portrait--90104";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var textLockup = "styles-module--textLockup--bf77f";