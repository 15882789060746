// extracted by mini-css-extract-plugin
export var active = "styles-module--active--73d1e";
export var container = "styles-module--container--2678f";
export var desktop = "1340px";
export var giant = "2200px";
export var inner = "styles-module--inner--bdd4d";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--aaa8b";
export var mobile = "400px";
export var mobileTitle = "styles-module--mobileTitle--c16ad";
export var mobileToggleButton = "styles-module--mobileToggleButton--1eb43";
export var open = "styles-module--open--1d8be";
export var routeSvg = "styles-module--routeSvg--015a2";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var visible = "styles-module--visible--3c4f8";
export var xSvg = "styles-module--xSvg--79406";