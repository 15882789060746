import React from "react";
import cn from "classnames";
import { SVG } from "@ottomotors/ottomotors-common/components";

import * as styles from "./styles.module.scss";

interface IProps {
  onClick: any;
  className?: string;
}

const PlayButton = ({ onClick, className }: IProps) => {
  const handleOnClick = () => {
    if (!onClick) return;

    onClick();
  };

  return (
    <button
      className={cn(styles.playButton, className)}
      type="button"
      onClick={() => handleOnClick()}
    >
      <figure className={styles.playButtonContent}>
        <SVG className={styles.circleSvg} svg="circleBlurred"></SVG>
        <SVG className={styles.playSvg} svg="play" />
        <p className={cn("button", styles.playText)}>Play</p>
      </figure>
    </button>
  );
};

export default PlayButton;
