// extracted by mini-css-extract-plugin
export var container = "styles-module--container--c6580";
export var desktop = "1340px";
export var giant = "2200px";
export var iframe = "styles-module--iframe--c3035";
export var iframeContainer = "styles-module--iframeContainer--fb07c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var text = "styles-module--text--95698";