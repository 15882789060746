import React, { useEffect, useRef } from "react";
import cn from "classnames";
import gsap from "gsap";
import { useScroll } from "@ottomotors/ottomotors.com/hooks";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import { SliceConfig, TextLockup } from "@ottomotors/ottomotors.com/components";

import * as styles from "./styles.module.scss";

// todo: Props

const HeroTextAndMedia = ({
  index,
  data: {
    sliceConfig,
    media,
    textLockup,
    textAlignment = "left",
    mediaAlignment = "center",
    mediaOverlay,
    textBackground,
  },
  inView, // available to every slice
}) => {
  const { scrollY } = useScroll();

  const mediaParallaxRef = useRef();
  const textParallaxRef = useRef();

  // always set textlockup theme to 'light' if textBackground is set
  const textLockUpTheme = textBackground ? "light" : textLockup?.theme?.theme;

  const textLockupConfig = {
    ...textLockup,
    theme: {
      theme: textLockUpTheme,
    },
  };

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    if (scrollY > window.innerHeight) {
      return;
    }

    if (!mediaParallaxRef?.current || !textParallaxRef?.current) {
      return;
    }

    // todo: tweak transform % to in/decrease the effect
    const mediaScrollSeverity = 400; // lower means more movement
    const mediaScrollTransform = scrollY / mediaScrollSeverity;

    const mediaScaleSeverity = 10000; // lower means more movement
    const mediaScaleTransform = scrollY / mediaScaleSeverity; // lower means more movement

    //
    // media parallax

    const media = mediaParallaxRef.current;

    gsap.killTweensOf(media);

    gsap.to(media, {
      y: `${-5 + mediaScrollTransform}%`,
      // scale: 1 + mediaScaleTransform,
      duration: 0,
      ease: "none",
    });

    //
    // media parallax

    const text = textParallaxRef.current;

    const textSeverity = 20; // lower means more movement
    const textScrollTransform = scrollY / textSeverity;

    gsap.killTweensOf(text);

    gsap.to(text, {
      y: `${textScrollTransform}%`,
      duration: 0.125,
      ease: "power4.out",
    });
  }, [mediaParallaxRef, scrollY, textParallaxRef]);

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider className={styles.grid} grid>
          <TextLockup
            forwardRef={textParallaxRef}
            className={cn(styles.text, {
              [styles.alignRight]: textAlignment === "right",
              [styles.paddedBackground]: textBackground,
              [styles.paddedBackgroundLight]: textBackground === "light",
              [styles.paddedBackgroundDark]: textBackground === "dark",
            })}
            data={textLockupConfig}
            hasData={textLockup}
            inView={inView}
          />
        </LayoutProvider>

        <div className={styles.background}>
          <figure
            ref={mediaParallaxRef}
            className={cn(styles.figure, {
              [styles.alignLeft]: mediaAlignment === "left",
              [styles.alignRight]: mediaAlignment === "right",
              [styles.alignCenter]:
                !mediaAlignment || mediaAlignment === "center",
            })}
          >
            {(media?.video?.url && (
              <video autoPlay muted loop playsInline>
                <source src={media.video.url} type="video/mp4" />
              </video>
            )) || (
              <>
                {media?.image?.asset && (
                  <img src={media.image.asset.url} alt={media.image.alt} />
                )}
              </>
            )}

            {mediaOverlay && <div className={styles.backgroundOverlay} />}
          </figure>
        </div>
      </section>
    </SliceConfig>
  );
};

export default HeroTextAndMedia;
