import React from "react";
import { useLocation } from "@reach/router";
import cn from "classnames";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { SVG } from "@ottomotors/ottomotors-common/components";

import * as styles from "./styles.module.scss";

interface IProps {
  heading?: string;
  description?: string;
  className?: string;
}

const ShareButtons = ({
  heading = "OTTO Motors",
  description = "",
  className,
}: IProps) => {
  const location = useLocation();
  const url = location?.href;

  if (!url) return null;

  return (
    <div className={cn(styles.container, className)}>
      <p className="label">Share</p>
      <div className={styles.icons}>
        <button
          type="button"
          onClick={() => navigator.clipboard.writeText(url)}
        >
          <figure>
            <SVG className={styles.icon} svg="link" />
          </figure>
        </button>

        <LinkedinShareButton
          url={url}
          title={heading}
          summary={description}
          source="OTTO Motors"
        >
          <SVG className={styles.icon} svg="linkedin" />
        </LinkedinShareButton>

        <FacebookShareButton url={url}>
          <SVG className={styles.icon} svg="facebook" />
        </FacebookShareButton>

        <TwitterShareButton url={url} title={heading}>
          <SVG className={styles.icon} svg="twitter" />
        </TwitterShareButton>
      </div>
    </div>
  );
};

export default ShareButtons;
