import React from "react";
import cn from "classnames";
import { VideoPlay } from "@ottomotors/ottomotors.com/components";
import { IRichTextVideo } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

export interface IProps {
  data: IRichTextVideo;
}

const PortableTextImage = ({ data }: IProps) => {
  const { video, credit, caption, aspectRatio } = data || {};

  if (!video?.url) return null;

  return (
    <div className={styles.mediaContainer}>
      <figure
        className={styles.media}
        style={{
          aspectRatio: `${aspectRatio?.ratio ? aspectRatio.ratio : `1`}`,
        }}
      >
        {video?.url && <VideoPlay src={video.url} thumbnail={video?.thumbnail} controls />}
      </figure>

      {caption && (
        <p className={cn("caption", styles.mediaCaption)}>{caption}</p>
      )}
      {credit && <p className={cn("caption", styles.mediaCredit)}>{credit}</p>}
    </div>
  );
};

export default PortableTextImage;
