import { useState, useEffect, useRef, RefObject } from 'react';

const useElementWidth = (): { ref: RefObject<HTMLDivElement>, width: number } => {
  const [width, setWidth] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    // Function to update the width state
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };

    // Update width on load
    updateWidth();

    // Add event listener for window resize
    window.addEventListener('resize', updateWidth);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return {ref, width};
}

export default useElementWidth;
