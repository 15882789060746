import React, { useState } from "react";
import {
  IntersectionAnimation,
  LayoutProvider,
} from "@ottomotors/ottomotors-common/components";
import FaqAccordion from "./components/FaqAccordion";
import { SliceConfig, TextLockup } from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsFaq } from "@ottomotors/ottomotors-sanity";
import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsFaq;
}

const Faq = ({ data: { _key, textLockup, items, sliceConfig } }: IProps) => {
  const [expandedTab, setExpandedTab] = useState<number | null>(null);

  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.container}>
        <LayoutProvider className={styles.layout} grid>
          {textLockup && (
            <TextLockup className={styles.textLockup} data={textLockup} />
          )}
          <div className={styles.content}>
            {items?.[0] && (
              <ul className={styles.faqItems}>
                {items.map((item, index) => {
                  const {
                    _key: itemKey,
                    question,
                    _rawAnswer: answer,
                  } = item || {};

                  const isExpanded = expandedTab === index;
                  const delay = index * 150;

                  return (
                    <li
                      key={`faq-${_key}-item-${itemKey}`}
                      className={styles.faqItem}
                      onClick={() => setExpandedTab(expandedTab === index ? null : index)}
                    >
                      <IntersectionAnimation delay={delay}>
                        <FaqAccordion
                          question={question}
                          answer={answer}
                          isExpanded={isExpanded}
                        />
                      </IntersectionAnimation>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </LayoutProvider>
      </div>
    </SliceConfig>
  );
};

export default Faq;
