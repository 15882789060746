import React from "react";
import cn from "classnames";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

import * as styles from "./styles.module.scss";

interface IProps {
  name: string;
  label: string;
  type: string;
  options: Array<string>;
  placeholder: string;
  defaultValue?: string;
  handleChange?: () => {};
  register: any;
  validation: any;
  hasError?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
}

const FormField = ({
  name,
  label,
  type,
  options,
  placeholder,
  defaultValue,
  handleChange,
  register,
  validation,
  hasError,
}: IProps) => {
  if (type === "textarea") {
    return (
      <div className={styles.inputContainer}>
        {label && (
          <label className="label" htmlFor={name}>
            {label}
          </label>
        )}
        <textarea
          {...register(name, validation)}
          className={cn("b2", styles.textArea)}
          name={name}
          id={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
      </div>
    );
  }
  if (type === "select") {
    return (
      <div className={styles.inputContainer}>
        {label && (
          <label className="label" htmlFor={name}>
            {label}
          </label>
        )}
        <select
          name={name}
          {...register(name, validation)}
          className={cn("b2", styles.select)}
          aria-labelledby={placeholder}
          defaultValue={defaultValue}
        >
          <option value={""}>{placeholder}</option>
          {options.map((option) => (
            <option value={option} defaultValue={defaultValue} key={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  }

  if (type === "radio" || type === "checkbox") {
    return (
      <div className={`${type}-buttons`}>
        {options.map((option) => (
          <div key={option} className={`${type}-button`}>
            <input
              {...register(name, validation)}
              type={type}
              id={option}
              name={name}
              value={option}
              defaultValue={defaultValue}
              onChange={handleChange}
              className={styles.input}
            />
            {type === "checkbox" && (
              <svg viewBox="0 0 21 21">
                <polyline points="5 10.75 8.5 14.25 16 6" />
              </svg>
            )}
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className={styles.inputContainer}>
      {label && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}

      <input
        {...register(name, validation)}
        className={cn("b2", styles.input, { [styles.error]: hasError })}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default FormField;
