import React from "react";
import {
  LayoutProvider,
  UniversalLink,
} from "@ottomotors/ottomotors-common/components";
import { SliceConfig } from "@ottomotors/ottomotors.com/components";

import { IOttoMotorsFeaturedSoftware } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsFeaturedSoftware;
}

const FeaturedSoftware = ({
  data: {
    subheading,
    heading,
    body,
    buttonPrimary,
    buttonSecondary,
    media,
    sliceConfig,
  },
}: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider grid>
          <div className={styles.content}>
            <article className={styles.text}>
              {subheading && <p className="label">{subheading}</p>}
              {heading && <h2 className="h1">{heading}</h2>}
              {body && <p className="b2">{body}</p>}
            </article>

            <div className={styles.buttons}>
              {buttonPrimary?.text && buttonPrimary?.link[0] && (
                <div>
                  <UniversalLink
                    link={buttonPrimary}
                    iconRight={"arrowRight"}
                    iconLeft={"arrowRight"}
                    animateOnHover
                  />
                </div>
              )}

              {buttonSecondary?.text && buttonSecondary?.link[0] && (
                <div>
                  <UniversalLink
                    link={buttonSecondary}
                    variant="secondary"
                    iconRight={"arrowRight"}
                    iconLeft={"arrowRight"}
                    animateOnHover
                  />
                </div>
              )}
            </div>
          </div>

          {(media?.video?.url || media?.image?.asset) && (
            <figure className={styles.media}>
              {(media?.video?.url && (
                <video autoPlay muted loop playsInline>
                  <source src={media.video.url} type="video/mp4" />
                </video>
              )) || (
                <>
                  {media?.image?.asset && (
                    <img src={media.image.asset.url} alt={media.image.alt} />
                  )}
                </>
              )}
            </figure>
          )}
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default FeaturedSoftware;
