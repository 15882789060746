import React from "react";
import cn from "classnames";
import { SVG } from "@ottomotors/ottomotors-common/components";
import { IOttoMotorsSettings } from "@ottomotors/ottomotors-sanity";

import { IRichTextSocialShare } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

export interface IProps {
  data: IRichTextSocialShare;
  siteSettings: IOttoMotorsSettings;
}

const PortableTextSocialShare = ({ data, siteSettings }: IProps) => {
  const { socials } = siteSettings || {};

  const { heading } = data || {};

  return (
    <div className={styles.container}>
      {heading && <p className={cn("b1", styles.heading)}>{heading}</p>}
      {socials?.[0] && (
        <div className={styles.socialLinks}>
          {socials.map((socialLink) => {
            const { _key, url, platform } = socialLink || {};

            return (
              <div
                key={`rich-text-social-link-${_key}`}
                className={styles.button}
              >
                <a href={url?.url}>
                  <figure className={styles.icon}>
                    <SVG svg={platform.toLowerCase()} />
                  </figure>
                </a>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PortableTextSocialShare;
