// extracted by mini-css-extract-plugin
export var button = "styles-module--button--20965";
export var buttonContainer = "styles-module--buttonContainer--bfabf";
export var container = "styles-module--container--d0f60";
export var content = "styles-module--content--2c900";
export var desktop = "1340px";
export var giant = "2200px";
export var image = "styles-module--image--c5fc0";
export var landscape = "styles-module--landscape--00d4b";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var tag = "styles-module--tag--3e1cc";
export var text = "styles-module--text--82ece";