import React from "react";
import { Link } from "gatsby";
import cn from "classnames";
import { IOttoMotorsWorkflowItem } from "@ottomotors/ottomotors-sanity/schemas/ottomotors.com/objects/workflow.item";
import { Tag } from "@ottomotors/ottomotors-common/components";

import * as styles from "./styles.module.scss";

const WorkflowItemContent = ({
  item: { body, amrs },
}: {
  item: IOttoMotorsWorkflowItem;
}) => {
  return (
    <div className={styles.container}>
      <p className={cn("b2", styles.body)}>{body}</p>
      {amrs?.[0] && (
        <div className={styles.tagsContainer}>
          <p className={cn("label", styles.tagsLabel)}>Recommended OTTO AMRs</p>
          <ul className={styles.tags}>
            {amrs?.map((amr, index) => (
              <li key={index} className={styles.tag}>
                <Link to={`/${amr?.slug?.current}`}>
                  <Tag text={amr?.title} theme="dark" className={styles.tag} variant="outline" />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default WorkflowItemContent;
