import React from "react";
import cn from "classnames";
import { IOttoMotorsProductInDetailSpecItem } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  spec: IOttoMotorsProductInDetailSpecItem;
  active?: boolean;
}

const SpecItem = ({ spec, active = false }: IProps) => {
  const { title, body } = spec || {};
  return (
    <article className={cn(styles.spec, { [styles.active]: active })}>
      {title && (
        <p className={cn("label", styles.title, { [styles.active]: active })}>
          {title}
        </p>
      )}
      {body && <p className="b2">{body}</p>}
    </article>
  );
};

export default SpecItem;
