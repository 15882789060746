import React, { useEffect, useState } from "react";
import cn from "classnames";
import Card from "../Header/components/Card";
import {
  Button,
  SVG,
  UniversalLink,
} from "@ottomotors/ottomotors-common/components";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";
import * as styles from "./styles.module.scss";
import { useApp, useSiteSettings } from "@ottomotors/ottomotors.com/hooks";

const MobileMenu = () => {
  const { tablet } = useBreakpoints();
  const siteSettings = useSiteSettings();
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);

  const { isMenuOpen, setIsMenuOpen } = useApp();

  const {
    header: { items },
  } = siteSettings || {};

  const nestedLinks = items.map((headerLink) => headerLink.links);

  // if the mobile menu is closed then close all active sub-menus
  useEffect(() => {
    if (!isMenuOpen) {
      setActiveMenuIndex(null);
    }
  }, [isMenuOpen]);

  return (
    <div className={cn(styles.container, { [styles.active]: isMenuOpen })}>
      <div
        className={cn(styles.backButton, {
          [styles.visible]: activeMenuIndex !== null,
        })}
      >
        <Button
          variant="text"
          text="Back to menu"
          iconLeft="arrowLeft"
          theme="lightOnDark"
          onClick={() => setActiveMenuIndex(null)}
        />
      </div>

      <div
        className={cn(styles.menu, {
          [styles.active]: isMenuOpen,
          [styles.slideOut]: activeMenuIndex !== null,
        })}
      >
        <ul className={styles.primaryNav}>
          {items.map((headerLink, index) => {
            const { _key, title } = headerLink || {};

            return (
              <li key={`header-mobile-menu-primary-menu-${_key}`}>
                <button
                  className={cn(tablet ? "h3" : "h4", styles.primaryNavButton)}
                  type="button"
                  onClick={() => setActiveMenuIndex(index)}
                >
                  <span
                    className={cn(styles.primaryNavButtonText, {
                      [styles.active]: activeMenuIndex === index,
                    })}
                  >
                    {title}
                  </span>
                  <figure className={styles.chevron}>
                    <SVG svg="chevronRight" />
                  </figure>
                </button>
              </li>
            );
          })}

          <div className={styles.contactButton}>
            <Button
              text="Contact Us"
              to={`/contact/`}
              theme="lightOnDark"
              iconRight="arrowRight"
              fluid
            />
          </div>
        </ul>
      </div>

      {nestedLinks.map((secondaryLinks, index) => {
        const isMenuOpen = activeMenuIndex === index;
        const parentTitle = items[index].title;
        const containsItemCards = secondaryLinks.some(
          (secondaryLink) =>
            secondaryLink?._type === "ottomotors.header.item.card"
        );

        return (
          <div
            key={`header-mobile-menu-secondary-menu-${index}`}
            className={cn(styles.secondaryMenu, {
              [styles.slideIn]: isMenuOpen,
              [styles.borderless]: containsItemCards,
            })}
          >
            <p className={cn("label", styles.secondaryMenuTitle)}>
              {parentTitle}
            </p>

            <ul
              className={cn(styles.secondaryNav, {
                [styles.column]: !containsItemCards,
              })}
            >
              {secondaryLinks.map((secondaryLinkItem) => {
                const { _key, _type, link, text, params } =
                  secondaryLinkItem || {};

                // define a universal link object
                const universalLink = {
                  link: link,
                  text: text,
                  params: params,
                };

                if (_type === "ottomotors.header.item.card") {
                  return (
                    <div
                      key={`header-mobile-menu-secondary-menu-item-${_key}`}
                      className={styles.secondaryNavItemCard}
                    >
                      <Card
                        className={styles.secondaryNavItemCardInner}
                        data={secondaryLinkItem}
                      />
                    </div>
                  );
                }

                return (
                  <li key={`header-mobile-menu-secondary-menu-item-${_key}`}>
                    <UniversalLink
                      className={styles.secondaryNavItem}
                      link={universalLink}
                      typestyle={tablet ? "h3" : "h4"}
                      variant="text"
                      theme="lightOnDark"
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default MobileMenu;
