import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useInView } from "react-intersection-observer";
import * as styles from "./styles.module.scss";

type TAnimations = "fade" | "fadeUp" | "fadeGrow";

interface IProps {
  children: React.ReactNode;
  animation?: TAnimations;
  margin?: string;
  delay?: number;
  trigger?: boolean;
  containerElement?: React.ElementType;
  className?: string;
}

const IntersectionAnimation = ({
  children,
  animation = "fadeUp",
  margin = "-90px",
  delay = 0,
  trigger,
  containerElement: ContainerElement = "div",
  className,
}: IProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const { ref, inView } = useInView({
    rootMargin: margin,
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  useEffect(() => {
    if (trigger) {
      setIsVisible(true);
    }
  }, [trigger]);

  return (
    <ContainerElement
      ref={ref}
      style={{
        transitionDelay: `${delay}ms`,
      }}
      className={cn(className, styles.container, {
        [styles.fade]: animation === "fade",
        [styles.fadeUp]: animation === "fadeUp",
        [styles.fadeGrow]: animation === "fadeGrow",
        [styles.visible]: isVisible,
      })}
    >
      {children}
    </ContainerElement>
  );
};

export default IntersectionAnimation;
