// extracted by mini-css-extract-plugin
export var articles = "styles-module--articles--50415";
export var clearButton = "styles-module--clearButton--416d7";
export var clearButtonSvg = "styles-module--clearButtonSvg--d2395";
export var container = "styles-module--container--b0a2d";
export var desktop = "1340px";
export var filterBar = "styles-module--filterBar--5ac7e";
export var filterButtonsMobile = "styles-module--filterButtonsMobile--b174f";
export var footer = "styles-module--footer--3c4fa";
export var giant = "2200px";
export var heading = "styles-module--heading--770d6";
export var headingText = "styles-module--headingText--dc2f6";
export var headingTotal = "styles-module--headingTotal--07ba2";
export var integratorCard = "styles-module--integratorCard--07a2e";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var mobileFilter = "styles-module--mobileFilter--7df30";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";