import React, { useEffect, useState } from "react";
import cn from "classnames";
import {
  SVG,
  LayoutProvider,
  Button,
} from "@ottomotors/ottomotors-common/components";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";

import * as styles from "./styles.module.scss";

import FilterList from "../FilterList";

interface IProps {
  isOpen: boolean;
  setIsOpen: any;
  filters: any;
  activeRegions: string[];
  setActiveRegions: any;
  className?: string;
}

const MobileFilter = ({
  isOpen = true,
  setIsOpen,
  filters,
  activeRegions,
  setActiveRegions,
  className,
}: IProps) => {
  const { tablet } = useBreakpoints();

  const [regionFilters, setRegionFilters] = useState([]);

  const handleOnClick = () => {
    setActiveRegions(regionFilters);
    setIsOpen(false);
  };

  const clearAll = () => {
    setActiveRegions([]);
    setIsOpen(false);
  };

  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add(styles.disableScroll);
  //   } else {
  //     document.body.classList.remove(styles.disableScroll);
  //   }
  // }, [isOpen]);

  useEffect(() => {
    function handleResize() {
      if (tablet) {
        setIsOpen(false);
      }
    }

    window.addEventListener(`resize`, handleResize);

    handleResize();

    return () => window.removeEventListener(`resize`, handleResize);
  }, []);

  useEffect(() => {
    setRegionFilters(activeRegions);
  }, [activeRegions]);

  return (
    <LayoutProvider
      className={cn(styles.grid, className, { [styles.open]: isOpen })}
      grid
      fullWidthGrid
    >
      <aside className={cn(styles.container, { [styles.open]: isOpen })}>
        <header className={styles.header}>
          <button
            type="button"
            className={styles.closeButton}
            onClick={() => setIsOpen(false)}
          >
            <span className="button">Close</span>
            <figure className={styles.closeButtonSvg}>
              <SVG svg="x" />
            </figure>
          </button>
        </header>

        <div className={styles.filters}>
          {filters.map((filterItem, index) => {
            const { type, filters, heading } = filterItem || {};
            let activeFilters;
            let setActiveFilters;

            switch (type) {
              case "region":
                activeFilters = regionFilters;
                setActiveFilters = setRegionFilters;
                break;

              default:
                break;
            }

            return (
              <div key={`article-filter-mobile-filter-${heading}-${index}`}>
                <p className="b2">{heading}</p>

                <FilterList
                  filters={filters}
                  activeFilters={activeFilters}
                  onInputClick={setActiveFilters}
                  clearAll={clearAll}
                />
              </div>
            );
          })}
        </div>

        <Button
          className={styles.button}
          text="Apply Filters"
          iconRight="arrowRight"
          iconLeft="arrowRight"
          animateOnHover
          fluid
          onClick={() => handleOnClick()}
        />
      </aside>
    </LayoutProvider>
  );
};

export default MobileFilter;
