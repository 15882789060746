// extracted by mini-css-extract-plugin
export var alignCenter = "styles-module--alignCenter--ccf67";
export var alignLeft = "styles-module--alignLeft--49383";
export var alignRight = "styles-module--alignRight--48960";
export var background = "styles-module--background--a57fc";
export var container = "styles-module--container--aa3da";
export var content = "styles-module--content--fcb74";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var noBottomPadding = "styles-module--noBottomPadding--69984";
export var overlay = "styles-module--overlay--65a7d";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";