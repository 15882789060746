import React from "react";
import cn from "classnames";
import { SVG } from "@ottomotors/ottomotors-common/components";
import * as styles from "./styles.module.scss";

// interface IProps {

// }

interface IFilter {
  label: string;
  value: string;
}

interface IProps {
  filters: IFilter[];
  activeFilters?: string[];
  onInputClick?: any;
  clearAll?: any;
}

const FilterList = ({
  filters,
  activeFilters,
  onInputClick,
  clearAll,
}: IProps) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const updatedFilters = checked
      ? [...activeFilters, value] // Add the value to filters if checked
      : activeFilters.filter((filter) => filter !== value); // Remove the value from filters if unchecked
    onInputClick(updatedFilters); // Call the callback function with updated filters
  };

  return (
    <ul className={styles.container}>
      <li className={cn(styles.clearAll)}>
        <button
          className={styles.clearButton}
          type="button"
          onClick={() => clearAll()}
        >
          <figure className={styles.filterInputCross}>
            <SVG svg="x" />
          </figure>

          <p className={cn("button")}>Clear All</p>
        </button>
      </li>
      {filters.map((filter, index) => (
        <li
          key={`article-grid-filter-list-${filter.value}`}
          className={styles.listItem}
        >
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              type="checkbox"
              name={filter.label}
              value={filter.value}
              id={`mobile-filter-${filter.value}`}
              checked={activeFilters?.includes(filter.value)}
              onChange={handleCheckboxChange} // Call handleCheckboxChange on checkbox change
            />

            <figure className={styles.inputCheck}>
              <SVG svg="check" />
            </figure>
          </div>

          <label
            className={cn("label", styles.inputLabel)}
            htmlFor={`mobile-filter-${filter.value}`}
          >
            {filter.label}
          </label>
        </li>
      ))}
    </ul>
  );
};

export default FilterList;
