import React from "react";
import cn from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { SVG, UniversalLink } from "@ottomotors/ottomotors-common/components";
import {
  IOttoMotorsSliceTheme,
  IOttoMotorsGalleryWithTextOverlayCard,
} from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsGalleryWithTextOverlayCard;
  theme: "dark" | "light";
  isThemed: boolean;
}

const Card = ({ data, theme, isThemed }: IProps) => {
  const {
    _key,
    heading,
    body,
    quoteTitle,
    quoteSubtitle,
    quoteImage,
    stars,
    link,
  } = data || {};

  const STAR_ARRAY = Array.from({ length: 5 }, () => undefined);
  const hasQuote =
    quoteTitle || quoteSubtitle || quoteImage?.asset?.gatsbyImageData;

  return (
    <article
      className={cn(styles.container, {
        [styles.dark]: isThemed && theme === "dark",
        [styles.light]: isThemed && theme === "light",
      })}
    >
      <header className={styles.header}>
        {stars && (
          <figure className={styles.stars}>
            {STAR_ARRAY.map((index) => {
              return (
                <figure
                  key={`gallery-with-text-overlay-${_key}-star-${index}`}
                  className={styles.star}
                >
                  <SVG svg="star" />
                </figure>
              );
            })}
          </figure>
        )}

        {heading && <h3 className="h3">{heading}</h3>}
      </header>

      {(body || hasQuote) && (
        <div
          className={cn(styles.body, {
            [styles.marginBottom]: !link,
          })}
        >
          <p className={cn("b2", styles.bodyText)}>{body}</p>
          {hasQuote && (
            <div className={styles.quote}>
              {quoteImage?.asset?.gatsbyImageData && (
                <figure className={styles.quoteImage}>
                  <GatsbyImage
                    alt={quoteImage.altText || "OTTO Motors"}
                    image={quoteImage?.asset?.gatsbyImageData}
                    objectFit="cover"
                  />
                </figure>
              )}
              <div>
                {quoteTitle && <p className="b2">{quoteTitle}</p>}
                {quoteSubtitle && (
                  <p className={cn("b2", styles.quoteSubtitle)}>
                    {quoteSubtitle}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {link?.link?.[0] && (
        <div className={styles.linkContainer}>
          <UniversalLink
            variant="text"
            theme={theme === "dark" ? "lightOnDark" : "darkOnLight"}
            link={link}
            iconRight="arrowRight"
            iconLeft="arrowRight"
            animateOnHover
          />
        </div>
      )}
    </article>
  );
};

export default Card;
