import React, { useRef } from "react";
// import { useInView } from "react-intersection-observer";
import cn from "classnames";
import * as Slices from "@ottomotors/ottomotors.com/slices";
import { ISliceConfig } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

export interface IPageMetadata {
  heading?: string;
  description?: string;
  publishedDate?: string;
  startDate?: string;
  endDate?: string;
}

interface IProps {
  data: {
    _key: string;
    _type: string;
    sliceConfig?: ISliceConfig;
  };
  pageMetadata?: IPageMetadata;
  id?: string;
  index?: number;
  inView?: boolean;
}

const SanitySlice = (props: IProps) => {
  // const { ref, inView, entry } = useInView({});

  const ref = useRef();

  const { data, index } = props || {};
  const { _key, _type: sliceType } = data;

  if (!sliceType) {
    console.error(
      `SanitySlice requires a valid _type, but none was received. Check the /templates/page file to ensure a valid slice type is being passed to the SanitySlice component.`
    );

    return null;
  }

  const strippedSliceName = sliceType.replace(`ottomotors.`, ``);

  const sliceName =
    strippedSliceName[0].toUpperCase() +
    strippedSliceName.slice(1, strippedSliceName.length);

  const SliceSection = (Slices as any)?.[sliceName.replace(`ottomotors.`, ``)];

  if (!SliceSection) {
    console.error(
      `Can't find Slice '${sliceName}', are you sure it has been configured in the slices directory?`
    );

    return null;
  }

  let zIndex = index === 0 ? 5 : 10;

  if (sliceName === "HeroTextAndMedia" && index !== 0) {
    zIndex = 4;
  }

  if (
    (sliceName === "Integrators" || sliceName === "ArticleFilter") &&
    index !== 0
  ) {
    zIndex = 11;
  }

  return (
    <section
      id={`slice-${_key}`}
      ref={ref}
      className={cn(`slice-${sliceName}`, styles.container)}
      style={{ zIndex }}
    >
      {/* <SliceSection {...props} inView={inView} /> */}
      <SliceSection {...props} />
    </section>
  );
};

export default SanitySlice;
