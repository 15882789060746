// extracted by mini-css-extract-plugin
export var background = "styles-module--background--51e57";
export var card = "styles-module--card--a8a38";
export var cardCarousel = "styles-module--cardCarousel--1e70d";
export var cards = "styles-module--cards--b962e";
export var container = "styles-module--container--40d44";
export var content = "styles-module--content--55ee4";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var selected = "styles-module--selected--ffadf";
export var slider = "styles-module--slider--5d5ae";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tab = "styles-module--tab--76662";
export var tabCaption = "styles-module--tabCaption--507bb";
export var tabIcon = "styles-module--tabIcon--1f850";
export var tabText = "styles-module--tabText--5ee35";
export var tablet = "768px";
export var tabs = "styles-module--tabs--f37ac";
export var textLockup = "styles-module--textLockup--aa9bf";
export var visible = "styles-module--visible--a5907";