import React from "react";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import {
  CardCarousel,
  SliceConfig,
  TextLockup,
} from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsCardCarouselFeaturedProduct } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsCardCarouselFeaturedProduct;
}

const CardCarouselFeaturedProduct = ({
  data: { cards, sliceConfig, textLockup },
}: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider grid>
          {textLockup && (
            <header className={styles.header}>
              <TextLockup data={textLockup} />
            </header>
          )}

          <div className={styles.metricSlider}>
            <label className={styles.sliderContainer}>
              <input type="checkbox" />
              <span className={styles.slider}></span>
            </label>

            <p className="label">Metric</p>
          </div>

          <CardCarousel
            className={styles.carousel}
            data={{ cards }}
            variant="featuredProduct"
          />
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default CardCarouselFeaturedProduct;
