import React, { useEffect, useState } from "react";
import cn from "classnames";
import {
  Button,
  IntersectionAnimation,
  LayoutProvider,
} from "@ottomotors/ottomotors-common/components";
import { SliceConfig } from "@ottomotors/ottomotors.com/components";
import {
  IOttoMotorsPressReleases,
  IOttoMotorsPressRelease,
} from "@ottomotors/ottomotors-sanity";
import ResourceCard from "@ottomotors/ottomotors.com/components/CardCarousel/components/ResourceCard";
import { usePressReleases } from "@ottomotors/ottomotors.com/hooks";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

import * as styles from "./styles.module.scss";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

interface IProps {
  data: IOttoMotorsPressReleases;
}

const PressReleases = ({ data }: IProps) => {
  // ---------------------------------------------------------------------------
  // variables

  const { heading, sliceConfig } = data || {};
  const { largeTablet } = useBreakpoints();

  const allPressReleases = usePressReleases();

  // ---------------------------------------------------------------------------
  // ref / state

  const [articlesPerPage, setArticlesPerPage] = useState(largeTablet ? 12 : 6);

  const [visibleArticles, setVisibleArticles] = useState(
    allPressReleases.slice(0, articlesPerPage)
  );

  const [currentPage, setCurrentPage] = useState(1);

  // ---------------------------------------------------------------------------
  // methods

  const isPressReleasePublished = (date: any) => {
    const torontoTz = "America/Toronto";
    const pressReleaseDate = dayjs.tz(date, torontoTz);
    const currentDate = dayjs().tz(torontoTz);

    const isSameDay = pressReleaseDate.isSame(currentDate);
    const hasPassed = pressReleaseDate.isBefore(currentDate);

    return isSameDay || hasPassed;
  };

  const getSortedPressReleases = () => {
    let pressReleases: IOttoMotorsPressRelease[] = JSON.parse(
      JSON.stringify(allPressReleases)
    );

    const releasedPressReleases = pressReleases.filter(({ publishedDate }) =>
      isPressReleasePublished(publishedDate)
    );

    releasedPressReleases.sort((a, b) => {
      if (a.publishedDate < b.publishedDate) {
        return 1;
      }

      if (a.publishedDate > b.publishedDate) {
        return -1;
      }

      return 0;
    });

    return releasedPressReleases;
  };

  const paginate = () => {
    const nextPage = currentPage + 1;
    const startIndex = currentPage * articlesPerPage;
    const endIndex = nextPage * articlesPerPage;

    const newVisibleArticles = getSortedPressReleases().slice(
      startIndex,
      endIndex
    );

    setVisibleArticles([...visibleArticles, ...newVisibleArticles]);
    setCurrentPage(nextPage);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setCurrentPage(1);

    setVisibleArticles(getSortedPressReleases().slice(0, articlesPerPage));
  }, [articlesPerPage]);

  useEffect(() => {
    const shownArticles = largeTablet ? 12 : 6;
    setArticlesPerPage(shownArticles);
  }, [largeTablet]);

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider grid>
          <h2 className={cn("h2", styles.heading)}>
            <span className={styles.headingText}>{heading}</span>
            <span className={styles.headingTotal}>
              ({allPressReleases.length})
            </span>
          </h2>
        </LayoutProvider>

        <LayoutProvider className={styles.articles} grid>
          {visibleArticles.map((article) => {
            return (
              <ResourceCard
                key={`article-filter-card-${article?._id}`}
                className={styles.article}
                data={article}
                noImage
              />
            );
          })}

          <footer className={styles.footer}>
            <>
              <p className="caption">
                Currently displaying: {visibleArticles?.length} /{" "}
                {allPressReleases?.length} results
              </p>

              {visibleArticles?.[0] &&
                visibleArticles?.length !== allPressReleases?.length && (
                  <IntersectionAnimation>
                    <Button
                      text="Load More"
                      variant="secondary"
                      iconRight="arrowRight"
                      iconLeft="arrowRight"
                      animateOnHover
                      onClick={() => {
                        paginate();
                      }}
                    />
                  </IntersectionAnimation>
                )}
            </>
          </footer>
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default PressReleases;
