import React from "react";
import { UniversalLink } from "@ottomotors/ottomotors-common/components";
import cn from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./styles.module.scss";

const FeaturedProductCard = ({ data }) => {
  const { heading, body, image, attributes, buttonPrimary, buttonSecondary } =
    data || {};

  return (
    <article className={styles.container}>
      {image?.asset?.gatsbyImageData && (
        <figure className={styles.image}>
          <GatsbyImage
            image={image?.asset?.gatsbyImageData}
            alt={image?.altText || "OTTO Motors"}
          />
        </figure>
      )}

      <div className={styles.content}>
        {heading && (
          <header className="h1">
            <h3>{heading}</h3>
          </header>
        )}

        {body && body && <p className="h3">{body}</p>}

        {(buttonPrimary || buttonSecondary) && (
          <div className={styles.buttons}>
            {buttonPrimary?.text && buttonPrimary?.link?.[0] && (
              <UniversalLink
                link={buttonPrimary}
                iconRight={"arrowRight"}
                iconLeft={"arrowRight"}
                animateOnHover
              />
            )}

            {buttonSecondary?.text && buttonSecondary?.link?.[0] && (
              <UniversalLink
                link={buttonSecondary}
                iconRight={"arrowRight"}
                iconLeft={"arrowRight"}
                animateOnHover
                theme="lightOnDark"
                variant="secondary"
              />
            )}
          </div>
        )}
      </div>

      {attributes?.[0] && (
        <ul className={styles.attributes}>
          {attributes.map((attribute) => {
            const { _key, key, value } = attribute || {};

            return (
              <li
                key={`featured-product-card-attribute-${_key}`}
                className={styles.attribute}
              >
                {key && <p className="label">{key}</p>}
                {value && <p className="label">{value}</p>}
              </li>
            );
          })}
        </ul>
      )}
    </article>
  );
};

export default FeaturedProductCard;
