// extracted by mini-css-extract-plugin
export var bannerActive = "styles-module--bannerActive--5cc27";
export var contactButton = "styles-module--contactButton--f4f79";
export var container = "styles-module--container--bf3af";
export var content = "styles-module--content--a041d";
export var crossSvg = "styles-module--crossSvg--7072d";
export var desktop = "1340px";
export var desktopMenu = "styles-module--desktopMenu--e8522";
export var desktopMenuItem = "styles-module--desktopMenuItem--60ad9";
export var desktopMenuItemCards = "styles-module--desktopMenuItemCards--430ed";
export var desktopMenuItemInner = "styles-module--desktopMenuItemInner--9c07f";
export var desktopMenuItemInnerCard = "styles-module--desktopMenuItemInnerCard--80029";
export var desktopMenuItemInnerLink = "styles-module--desktopMenuItemInnerLink--fe836";
export var desktopMenuLabel = "styles-module--desktopMenuLabel--a6dc5";
export var desktopMenuList = "styles-module--desktopMenuList--697bb";
export var ellipsisSvg = "styles-module--ellipsisSvg--000e5";
export var giant = "2200px";
export var headerHeight = "56px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logo = "styles-module--logo--65bd9";
export var mobile = "400px";
export var mobileActive = "styles-module--mobileActive--34a8e";
export var mobileButton = "styles-module--mobileButton--e8ab8";
export var mobileButtonContainer = "styles-module--mobileButtonContainer--ce6eb";
export var mobileButtonSvg = "styles-module--mobileButtonSvg--d6f93";
export var open = "styles-module--open--fd374";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "styles-module--tablet--1d4df";
export var textLink = "styles-module--textLink--7b225";