import React from "react";
import { useASG } from "@ottomotors/ottomotors.com/hooks";
import { Helmet } from "react-helmet";
import getFontFamilyCss from "./getFontFamilyCss";
import getTypestyleCss from "./getTypestyleCss";

/** ============================================================================
 * @component
 * Inject the formatted CSS into the document head
 */
const Fonts = () => {
  const { getFontById, typestyles, fonts } = useASG();

  return (
    <Helmet>
      <style>{getFontFamilyCss(fonts)}</style>
      <style>{getTypestyleCss(typestyles, getFontById)}</style>
    </Helmet>
  );
};

export default Fonts;
