// extracted by mini-css-extract-plugin
export var active = "styles-module--active--247ac";
export var cardImage = "styles-module--cardImage--b025f";
export var container = "styles-module--container--de6ca";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var spec = "styles-module--spec--7ee2e";
export var specValue = "styles-module--specValue--af722";
export var specs = "styles-module--specs--08e79";
export var tablet = "768px";
export var textLockup = "styles-module--textLockup--e7b5d";
export var visible = "styles-module--visible--de691";