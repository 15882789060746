// extracted by mini-css-extract-plugin
export var body = "styles-module--body--f5425";
export var bodyText = "styles-module--bodyText--76188";
export var container = "styles-module--container--79637";
export var dark = "styles-module--dark--44503";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--42d4c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--f9efd";
export var linkContainer = "styles-module--linkContainer--88987";
export var marginBottom = "styles-module--marginBottom--af935";
export var mobile = "400px";
export var quote = "styles-module--quote--2dcb1";
export var quoteImage = "styles-module--quoteImage--9e3e0";
export var quoteSubtitle = "styles-module--quoteSubtitle--8d627";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var star = "styles-module--star--ea8b6";
export var stars = "styles-module--stars--b7b2a";
export var tablet = "768px";