import React from "react";
import cn from "classnames";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import { SliceConfig, TextLockup } from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsSplitColumn } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsSplitColumn;
}

const SplitColumn = ({
  data: { media, mediaPosition, textLockup, sliceConfig },
}: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider className={styles.grid} grid>
          {(media?.image?.asset || media?.video?.url) && (
            <div
              className={cn(styles.mediaContainer, {
                [styles.alignLeft]: mediaPosition === "left",
              })}
            >
              {(media?.video?.url && (
                <figure className={cn(styles.media, styles.video)}>
                  <video autoPlay muted loop playsInline>
                    <source src={media.video.url} type="video/mp4" />
                  </video>
                </figure>
              )) || (
                <figure className={styles.media}>
                  {media?.image?.asset && (
                    <img src={media.image.asset.url} alt={media.image.alt} />
                  )}
                </figure>
              )}
            </div>
          )}

          <article
            className={cn(styles.content, {
              [styles.alignRight]: mediaPosition === "left",
            })}
          >
            {textLockup && (
              <TextLockup className={styles.text} data={textLockup} fullWidth />
            )}
          </article>
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default SplitColumn;
