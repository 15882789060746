import React, { useEffect, useState } from "react";
import cn from "classnames";
import {
  Button,
  IntersectionAnimation,
  LayoutProvider,
  SVG,
} from "@ottomotors/ottomotors-common/components";
import { SliceConfig } from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsIntegrators } from "@ottomotors/ottomotors-sanity";
import FilterDropdown from "./components/FilterDropdown";
import MobileFilter from "./components/MobileFilter";
import IntegratorCard from "./components/IntegratorCard";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsIntegrators;
}

const REGION_FILTERS = [
  {
    label: `North America`,
    value: `northAmerica`,
  },
  {
    label: `South America`,
    value: `southAmerica`,
  },
  {
    label: `Europe`,
    value: `europe`,
  },
  {
    label: `Asia`,
    value: `asia`,
  },
  {
    label: `Oceania`,
    value: `oceania`,
  },
];

const Integrators = ({ data }: IProps) => {
  // ---------------------------------------------------------------------------
  // variables

  const { heading, integrators, sliceConfig } = data || {};
  const { largeTablet } = useBreakpoints();

  const allIntegrators = [...integrators];

  // ---------------------------------------------------------------------------
  // ref / state

  // const [filter, setFilter] = React.useState(``);
  const [regionFilters, setRegionFilters] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(true);
  const [integratorsPerPage, setIntegratorsPerPage] = useState(
    largeTablet ? 12 : 6
  );

  const [visibleIntegrators, setVisibleIntegrators] = useState(
    allIntegrators.slice(0, integratorsPerPage)
  );

  // ---------------------------------------------------------------------------
  // methods

  const getFilteredIntegrators = () => {
    let filteredIntegrators = JSON.parse(JSON.stringify(allIntegrators));

    if (regionFilters.length > 0) {
      filteredIntegrators = filteredIntegrators.filter((integrator) => {
        return regionFilters.some((filterValue) => {
          if (integrator?.region === filterValue) {
            return true;
          } else {
            return false;
          }
        });
      });
    }

    return filteredIntegrators;
  };

  const paginate = () => {
    const nextPage = currentPage + 1;
    const startIndex = currentPage * integratorsPerPage;
    const endIndex = nextPage * integratorsPerPage;

    const newvisibleIntegrators = getFilteredIntegrators().slice(
      startIndex,
      endIndex
    );

    setVisibleIntegrators([...visibleIntegrators, ...newvisibleIntegrators]);
    setCurrentPage(nextPage);
  };

  const handleClearFilter = () => {
    setRegionFilters([]);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setCurrentPage(1);

    const filteredArticles = getFilteredIntegrators();

    setVisibleIntegrators(filteredArticles.slice(0, integratorsPerPage));
  }, [regionFilters, integratorsPerPage]);

  useEffect(() => {
    const shownArticles = largeTablet ? 12 : 6;
    setIntegratorsPerPage(shownArticles);
  }, [largeTablet]);

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider grid>
          <h2 className={cn("h2", styles.heading)}>
            <span className={styles.headingText}>{heading}</span>
            <span className={styles.headingTotal}>
              ({allIntegrators.length})
            </span>
          </h2>

          <div className={styles.filterBar}>
            <FilterDropdown
              label="Region"
              filters={REGION_FILTERS}
              activeFilters={regionFilters}
              onInputClick={setRegionFilters}
            />

            {regionFilters?.[0] && (
              <button
                type="button"
                className={styles.clearButton}
                onClick={() => handleClearFilter()}
              >
                <span className="button">Clear All</span>
                <figure className={styles.clearButtonSvg}>
                  <SVG svg="x" />
                </figure>
              </button>
            )}
          </div>

          <div className={styles.filterButtonsMobile}>
            <Button
              text="Filter"
              iconRight="plus"
              variant="secondary"
              onClick={() => setIsMobileFilterOpen(true)}
            />

            {regionFilters?.[0] && (
              <button
                type="button"
                className={styles.clearButton}
                onClick={() => handleClearFilter()}
              >
                <span className="button">Clear All</span>
                <figure className={styles.clearButtonSvg}>
                  <SVG svg="x" />
                </figure>
              </button>
            )}
          </div>
        </LayoutProvider>

        <LayoutProvider className={styles.articles} grid>
          {visibleIntegrators.map((integrator) => {
            return (
              <IntegratorCard
                key={`integrator-filter-card-${integrator?._key}`}
                className={styles.integratorCard}
                data={integrator}
              />
            );
          })}

          <footer className={styles.footer}>
            <>
              <p className="caption">
                Currently displaying: {visibleIntegrators?.length} /{" "}
                {getFilteredIntegrators()?.length} results
              </p>
              {visibleIntegrators?.[0] &&
                visibleIntegrators?.length !==
                  getFilteredIntegrators()?.length && (
                  <IntersectionAnimation>
                    <Button
                      text="Load More"
                      variant="secondary"
                      iconRight="arrowRight"
                      iconLeft="arrowRight"
                      animateOnHover
                      onClick={() => {
                        paginate();
                      }}
                    />
                  </IntersectionAnimation>
                )}
            </>
          </footer>
        </LayoutProvider>

        <MobileFilter
          isOpen={isMobileFilterOpen}
          setIsOpen={setIsMobileFilterOpen}
          className={styles.mobileFilter}
          activeRegions={regionFilters}
          setActiveRegions={setRegionFilters}
          filters={[
            { type: "region", heading: "Region", filters: REGION_FILTERS },
          ]}
        />
      </section>
    </SliceConfig>
  );
};

export default Integrators;
