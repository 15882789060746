import React from 'react'
import { Script } from "gatsby"

const Scripts = () => {
  return (
    <>
      {/* COOKIEBOT - needs to be at top */}
      <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="5eee66a5-61e6-47ab-bee6-9fe5e917f56d"
        data-blockingmode="auto"
        type="text/javascript"
      />

      {/* ACCESSIBE */}

      <Script key="AccessiBe">
        {`
          (function(){
            var s    = document.createElement('script');
            var h    = document.querySelector('head') || document.body;
            s.src    = 'https://acsbapp.com/apps/app/dist/js/app.js';
            s.async  = true;
            s.onload = function(){
                acsbJS.init({
                    statementLink    : '',
                    footerHtml       : '',
                    hideMobile       : false,
                    hideTrigger      : false,
                    disableBgProcess : false,
                    language         : 'en',
                    position         : 'left',
                    leadColor        : '#1e1e1e',
                    triggerColor     : '#1e1e1e',
                    triggerRadius    : '50%',
                    triggerPositionX : 'left',
                    triggerPositionY : 'bottom',
                    triggerIcon      : 'people',
                    triggerSize      : 'medium',
                    triggerOffsetX   : 20,
                    triggerOffsetY   : 20,
                    mobile           : {
                        triggerSize      : 'small',
                        triggerPositionX : 'left',
                        triggerPositionY : 'bottom',
                        triggerOffsetX   : 10,
                        triggerOffsetY   : 10,
                        triggerRadius    : '50%'
                    }
                });
            };
            h.appendChild(s);
          })();
        `}
      </Script>
    </>
  )
}

export default Scripts;
