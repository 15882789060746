import React, { useEffect, useState } from "react";
import cn from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component Core Embla carousel component
 * Accepts optional useEmblaCarousel object as embla = [ref, api], otherwise
 * initializes one of its own. Returns the current int to slide children.
 */
const Carousel = ({
  embla,
  className = ``,
  slidesPerView = 1,
  spaceBetween = 0,
  slides = [],
  showOverflow = false,
  slideClassName = ``,
}: any) => {
  // --------------------------------------------------------------------------
  // context / ref / state

  const [current, setCurrent] = useState(0);
  const [pageMargin, setPageMargin] = useState(24);

  const { smallMobile, tablet, largeTablet } = useBreakpoints();

  const [defaultEmblaRef, defaultEmblaApi] = useEmblaCarousel({
    align: `start`,
    loop: true,
  });

  // --------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    let margin = 24;

    if (tablet) {
      margin = 40;
    }

    if (largeTablet) {
      margin = 64;
    }

    setPageMargin(margin);
  }, [smallMobile, tablet, largeTablet]);

  useEffect(() => {
    if (!embla?.api && !defaultEmblaApi) {
      return;
    }

    if (embla?.api) {
      embla.api.on(`select`, () => setCurrent(embla.api.selectedScrollSnap()));
    } else {
      defaultEmblaApi.on(`select`, () =>
        setCurrent(defaultEmblaApi.selectedScrollSnap())
      );
    }
  }, [embla?.api, defaultEmblaApi]);

  // --------------------------------------------------------------------------
  // render

  if (!embla?.ref && !defaultEmblaRef) {
    return <></>;
  }

  return (
    <>
      <div
        ref={embla?.ref || defaultEmblaRef}
        className={cn([styles.embla, `embla`, className])}
        // to do: check math for this. maybe it's just supposed to be 100% + page margin?
        style={{
          width: `calc(100% + ${showOverflow ? pageMargin : spaceBetween}px)`,
        }}
        // className={`${className} embla`}
      >
        <ul className={cn([styles.emblaContainer, `embla__container`])}>
          {slides({ current }).map((slide) => {
            const slideStyles = {
              flex: `0 0 calc(${100 / slidesPerView}% - ${spaceBetween}px)`,
              marginRight: `${spaceBetween}px`,
            };
            return (
              <li
                key={`${slide.key}-embla-slide`}
                style={slideStyles}
                className={cn([
                  styles.emblaSlide,
                  `embla__slide`,
                  slideClassName,
                ])}
              >
                {slide}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Carousel;
