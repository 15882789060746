// todo - can we delete the Grid, MaxWidth and Grid components?

import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  children: React.ReactNode;
  className?: string;
  grid?: boolean;
  padding?: boolean;
  maxWidth?: boolean;
  fullWidthGrid?: boolean;
}

const LayoutProvider = ({
  children,
  className,
  grid,
  maxWidth,
  padding,
  fullWidthGrid,
}: IProps) => {
  return (
    <div
      className={cn(styles.container, className, {
        [styles.maxWidth]: maxWidth,
        [styles.grid]: grid,
        [styles.padding]: padding,
        [styles.gridFull]: fullWidthGrid,
      })}
    >
      {children}
    </div>
  );
};

export default LayoutProvider;
