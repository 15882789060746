// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var download = "styles-module--download--6e288";
export var downloadDesktop = "styles-module--downloadDesktop--4abe8";
export var extension = "styles-module--extension--678d7";
export var extensionAndSize = "styles-module--extensionAndSize--4bead";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var lineItem = "styles-module--lineItem--aad95";
export var mobile = "400px";
export var size = "styles-module--size--1c2d4";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--cb23e";