import { useStaticQuery, graphql } from "gatsby";

const usePressReleases = () => {
  const allPressReleases = useStaticQuery(graphql`
    query pressReleasesQuery {
      allSanityOttomotorsPressRelease {
        nodes {
          _id
          _type

          title

          slug {
            current
          }

          publishedDate

          cardButtonText
          cardImage {
            altText
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  return allPressReleases.allSanityOttomotorsPressRelease.nodes;
};

export default usePressReleases;
