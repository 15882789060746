// extracted by mini-css-extract-plugin
export var carousel = "styles-module--carousel--1b24a";
export var carouselContainer = "styles-module--carouselContainer--cf1b4";
export var carouselGrid = "styles-module--carouselGrid--d0ca1";
export var carouselNav = "styles-module--carouselNav--2f7ff";
export var container = "styles-module--container--6fbf4";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--f3cbe";
export var mobile = "400px";
export var singleMedia = "styles-module--singleMedia--71c42";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var wordmark = "styles-module--wordmark--1aed8";