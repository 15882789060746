import { useEffect, useState } from "react";

interface GeolocationError {
  code: number;
  message: string;
}

export interface IGeolocationPosition {
  lat: number;
  lng: number;
}

interface GeolocationState {
  location: IGeolocationPosition | null;
  error: GeolocationError | null;
}

const useGeolocation = (): GeolocationState => {
  const [geolocationState, setGeolocationState] = useState<GeolocationState>({
    location: null,
    error: null,
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (userPosition) => {
          const { latitude, longitude } = userPosition.coords;
          setGeolocationState({
            location: { lat: latitude, lng: longitude },
            error: null,
          });
        },
        (userError) => {
          setGeolocationState({
            location: null,
            error: { code: userError.code, message: userError.message },
          });
        }
      );
    } else {
      setGeolocationState({
        location: null,
        error: {
          code: 0,
          message: "Geolocation is not available in this browser.",
        },
      });
    }
  }, []);

  return geolocationState;
};

export default useGeolocation;
