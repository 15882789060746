import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { LayoutProvider, SVG } from "@ottomotors/ottomotors-common/components";
import {
  useIframeResizer,
  useSiteSettings,
} from "@ottomotors/ottomotors.com/hooks";
import { AcceptCookiesMessage, TextLockup } from "@ottomotors/ottomotors.com/components";
import { useApp } from "@ottomotors/ottomotors.com/hooks";

import * as styles from "./styles.module.scss";

const ContactModal = () => {
  const {
    isContactModalOpen,
    setIsContactModalOpen,
    contactModalEmbedOverride,
    cookiesAccepted
  } = useApp();

  const siteSettings = useSiteSettings();

  const iframeRef = useRef(null);
  const [iframeWidth, setIframeWidth] = useState(0);

  useEffect(() => {
    if (!iframeRef?.current) {
      return;
    }

    const updateIframeWidth = () => {
      const { width } = iframeRef.current.getBoundingClientRect();
      setIframeWidth(width);
    };

    updateIframeWidth();
    window.addEventListener("resize", updateIframeWidth);

    return () => {
      window.removeEventListener("resize", updateIframeWidth);
    };
  }, [iframeRef]);

  const { contactOverlay } = siteSettings;

  // default to siteSettings embed values
  let embed = contactOverlay.embed;
  let embedHeightDesktop = contactOverlay.embedHeightDesktop;
  let embedHeightMobile = contactOverlay.embedHeightMobile;

  if (
      contactModalEmbedOverride?.embed &&
      contactModalEmbedOverride?.embedHeightDesktop &&
      contactModalEmbedOverride?.embedHeightMobile
  ) {
    embed = contactModalEmbedOverride.embed;
    embedHeightDesktop = contactModalEmbedOverride.embedHeightDesktop;
    embedHeightMobile = contactModalEmbedOverride.embedHeightMobile;
  }

  // Use the updated useIframeResizer hook
  const { iframeSrc, updateIframeAttributes } = useIframeResizer(
      iframeWidth,
      embed,
      embedHeightDesktop,
      embedHeightMobile
  );

  // Load the iframe after cookies are accepted
  useEffect(() => {
    if (cookiesAccepted && iframeSrc && iframeRef?.current) {
      updateIframeAttributes(iframeRef); // Update iframe dimensions
      iframeRef.current.src = iframeSrc; // Set the iframe src
    }
  }, [cookiesAccepted, iframeSrc, updateIframeAttributes]);

  return (
      <div
          className={cn(styles.container, { [styles.visible]: isContactModalOpen })}
      >
        <LayoutProvider className={styles.grid} grid>
          <div className={cn(styles.form)}>
            <div className={styles.close}>
              <button type="button" onClick={() => setIsContactModalOpen(false)}>
                <figure className={styles.closeSvg}>
                  <SVG svg="x" />
                </figure>
              </button>
            </div>

            <TextLockup
                data={
                    contactModalEmbedOverride?.textLockup || contactOverlay.textLockup
                }
            />

            {cookiesAccepted ? (
                <div className={styles.iframe}>
                  <iframe
                      ref={iframeRef}
                      width="100%"
                      height={embedHeightDesktop} // Height is handled dynamically in the hook
                      style={{ border: "0" }}
                      allowTransparency="true"
                      frameBorder="0"
                  />
                </div>
            ) : (
                <AcceptCookiesMessage text="access this form" />
            )}
          </div>
        </LayoutProvider>
      </div>
  );
};

export default ContactModal;
