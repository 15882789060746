import React, { useEffect, useState } from "react";
import cn from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import Fade from 'embla-carousel-fade';
import { Button, Carousel } from "@ottomotors/ottomotors-common/components";
import { VariableMedia } from "@ottomotors/ottomotors.com/components";
import WorkflowItemContent from "../WorkflowItemContent";
import { IOttoMotorsWorkflowItem } from "@ottomotors/ottomotors-sanity/schemas/ottomotors.com/objects/workflow.item";

import * as styles from "./styles.module.scss";

const getCounterValue = (index: number, length: number) => {
  const current = index + 1 < 10 ? `0${index + 1}` : index + 1;
  const max = length < 10 ? `0${length}` : length;
  return `${current}/${max}`;
};

const StepsCarousel = ({
  item,
  active,
}: {
  item: IOttoMotorsWorkflowItem;
  active: boolean;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel({}, [Fade()]);
  const slides = item?.slides;

  const onCarouselPrevClick = () => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  };

  const onCarouselNextClick = () => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  };

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", () => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  if (!slides?.[0]) return null;

  return (
    <div className={cn(styles.container, { [styles.active]: !!active })}>
      <div className={styles.workflowItemContent}>
        <WorkflowItemContent item={item} />
      </div>
      <Carousel
        embla={{
          api: emblaApi,
          ref: emblaRef,
        }}
        className={styles.carousel}
        slidesPerView={1}
        slides={() =>
          slides.map((slide, index) => {
            return (
              <VariableMedia
                media={slide}
                key={index}
                className={styles.media}
              />
            );
          })
        }
      />
      <div className={styles.carouselBottom}>
        <div className={styles.navButtons}>
          <Button
            className={styles.navButton}
            onClick={() => onCarouselPrevClick()}
            variant="iconButton"
            icon="arrowLeft"
            text="Carousel Previous"
            // disabled={selectedIndex === 0}
          />
          <Button
            className={styles.navButton}
            onClick={() => onCarouselNextClick()}
            variant="iconButton"
            icon="arrowRight"
            text="Carousel Next"
            // disabled={selectedIndex === slides?.length - 1}
          />
        </div>
        <div className={styles.captionContainer}>
          <div className={cn("label", styles.counter)}>
            Step {getCounterValue(selectedIndex, slides?.length)}
          </div>
          <div className={styles.caption}>
            <p className="b2">{slides[selectedIndex]?.caption}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsCarousel;
