// extracted by mini-css-extract-plugin
export var alignCenter = "styles-module--alignCenter--0213e";
export var alignLeft = "styles-module--alignLeft--4e34c";
export var alignRight = "styles-module--alignRight--1093e";
export var background = "styles-module--background--5a39a";
export var backgroundOverlay = "styles-module--backgroundOverlay--289c3";
export var body = "styles-module--body--82d7b";
export var container = "styles-module--container--3935a";
export var desktop = "1340px";
export var figure = "styles-module--figure--8abe8";
export var giant = "2200px";
export var grid = "styles-module--grid--6437f";
export var heading = "styles-module--heading--52aa3";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var paddedBackground = "styles-module--paddedBackground--2c489";
export var paddedBackgroundDark = "styles-module--paddedBackgroundDark--25e05";
export var paddedBackgroundLight = "styles-module--paddedBackgroundLight--a427e";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var text = "styles-module--text--a7e0e";