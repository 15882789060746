// extracted by mini-css-extract-plugin
export var button = "styles-module--button--952f7";
export var contactText = "styles-module--contactText--59fbb";
export var container = "styles-module--container--8ea9e";
export var content = "styles-module--content--da7c6";
export var copyright = "styles-module--copyright--85a9a";
export var desktop = "1340px";
export var footer = "styles-module--footer--a2167";
export var footerBorder = "styles-module--footerBorder--28c0d";
export var footerLinks = "styles-module--footerLinks--4763e";
export var giant = "2200px";
export var grid = "styles-module--grid--82c47";
export var icon = "styles-module--icon--162e2";
export var iconLink = "styles-module--iconLink--30794";
export var iconLinkFlex = "styles-module--iconLinkFlex--c3bd9";
export var iconLinks = "styles-module--iconLinks--f77fc";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logo = "styles-module--logo--be6d4";
export var mobile = "400px";
export var primary = "styles-module--primary--5ba0e";
export var primaryLinkGroup = "styles-module--primaryLinkGroup--abb46";
export var primaryLinkGroupHeading = "styles-module--primaryLinkGroupHeading--f56eb";
export var primaryLinkGroupLinks = "styles-module--primaryLinkGroupLinks--cdffb";
export var primaryLinks = "styles-module--primaryLinks--06016";
export var primaryLinksContainer = "styles-module--primaryLinksContainer--9a863";
export var secondaryLink = "styles-module--secondaryLink--aa963";
export var secondaryLinks = "styles-module--secondaryLinks--83a22";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var socialLinks = "styles-module--socialLinks--bdf61";
export var tablet = "768px";