import React from "react";
import cn from "classnames";
import {
  SVG,
  UniversalLink,
  UniversalLinkWrapper,
} from "@ottomotors/ottomotors-common/components";
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./styles.module.scss";

const Card = ({ data, className }) => {
  const { image, cardLink, cardIcon } = data || {};

  const isButton = !image && !cardIcon?.icon;

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  return (
    // wrap the element with a UniversalLinkWrapper if it's not a button card
    <ConditionalWrapper
      condition={!isButton}
      wrapper={(children) => (
        <UniversalLinkWrapper data={cardLink}>{children}</UniversalLinkWrapper>
      )}
    >
      <article
        className={cn(
          styles.container,
          { [styles.button]: isButton },
          className
        )}
      >
        {(image?.asset?.gatsbyImageData && (
          <figure>
            <GatsbyImage
              style={{ padding: `0` }}
              image={image.asset.gatsbyImageData}
              alt={image.altText || "OTTO Motors"}
            />
          </figure>
        )) ||
          (cardIcon?.icon && (
            <figure className={styles.icon}>
              <SVG svg={cardIcon?.icon}></SVG>
            </figure>
          ))}

        {(isButton && (
          <div className={styles.buttonContainer}>
            <UniversalLink
              link={cardLink}
              variant="text"
              theme="lightOnDark"
              iconRight="arrowRight"
            />
          </div>
        )) || <p className={cn("b2", styles.text)}>{cardLink?.text}</p>}
      </article>
    </ConditionalWrapper>
  );
};

export default Card;
