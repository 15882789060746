// extracted by mini-css-extract-plugin
export var button = "styles-module--button--91b56";
export var container = "styles-module--container--bd54e";
export var desktop = "1340px";
export var disabled = "styles-module--disabled--ee6f9";
export var disclaimer = "styles-module--disclaimer--2556e";
export var error = "styles-module--error--20e85";
export var errorMessage = "styles-module--errorMessage--9bf53";
export var form = "styles-module--form--4bc61";
export var formFlex = "styles-module--formFlex--8cfce";
export var formGroup = "styles-module--formGroup--63aab";
export var fullWidth = "styles-module--fullWidth--8a4e0";
export var giant = "2200px";
export var grid = "styles-module--grid--9780e";
export var header = "styles-module--header--55e5f";
export var hidden = "styles-module--hidden--e1d44";
export var inputGroup = "styles-module--inputGroup--a14be";
export var inputGroupRange = "styles-module--inputGroupRange--cf302";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var output = "styles-module--output--c0cec";
export var resultGroup = "styles-module--resultGroup--3fa54";
export var resultValue = "styles-module--resultValue--f730c";
export var results = "styles-module--results--05901";
export var resultsDisclaimer = "styles-module--resultsDisclaimer--251ff";
export var resultsInner = "styles-module--resultsInner--84826";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subheading = "styles-module--subheading--613f3";
export var tablet = "768px";
export var valueFlex = "styles-module--valueFlex--cfd26";