import React, { useState } from "react";
import cn from "classnames";
import { useForm } from "react-hook-form";
import FormField from "./components/FormField";
import { Button } from "@ottomotors/ottomotors-common/components";
import { useDataLayer } from "@ottomotors/ottomotors.com/hooks";
import { getFormFields } from "@ottomotors/ottomotors-common/utils";

import * as styles from "./styles.module.scss";

export interface IForm {
  type: string;
  title: string;
  onSubmit: () => {};
  formSparkId?: string;
  submitButton?: {
    submissionText?: string;
    defaultText?: string;
  };
  className?: string;
  setIsSubmitted?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Form = ({
  type,
  title,
  onSubmit,
  formSparkId,
  submitButton,
  className,
  setIsSubmitted,
}: IForm) => {
  const { register, handleSubmit, formState, reset } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { errors, isSubmitting } = formState;

  const [submissionError, setSubmissionError] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState("");
  const sendToDataLayer = useDataLayer();

  const fields = getFormFields(type);

  const resetForm = () => {
    const resetTimeout = setTimeout(() => {
      reset();

      if (setIsSubmitted) {
        setIsSubmitted(false);
      }
    }, 3000);

    return () => {
      clearTimeout(resetTimeout);
    };
  };

  const onSubmitHandler = async (values) => {
    try {
      if (onSubmit) {
        await onSubmit(values);
      } else {
        const url = `https://submit-form.com/${formSparkId || ""}`; // pass formSparkId to send to formSpark
        const config = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(values),
        };
      }
      resetForm();
      setSubmissionSuccess(`Thank you, you've been signed up`);
      sendToDataLayer("contact_form_submission");
    } catch (error) {
      console.error("Error submitting form", error);
      setSubmissionError("Oops something went wrong, please try again");
    }
  };

  return (
    <section>
      {title && <h4 className={styles.title}>{title}</h4>}
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        className={`${styles.form} ${className || ""}`}
      >
        <button
          type="submit"
          disabled
          aria-hidden="true"
          style={{ display: "none" }}
        />
        {fields?.[0] &&
          fields.map((field, index) => {
            const hasError = errors[field.name];

            return (
              <div
                key={`form-field-${field?.label || field?.name || index}`}
                className={cn(styles.field, field.className)}
              >
                {/* LABEL */}
                {/* <span className={styles.label}>{field.label}</span> */}
                <FormField {...field} register={register} hasError={hasError} />
                <input
                  {...register("_gotcha")}
                  type="checkbox"
                  name="_gotcha"
                  style={{ display: "none" }}
                  tabIndex="-1"
                  autoComplete="off"
                />
                <div
                  className={`${styles.fieldError} ${
                    hasError ? "active" : "inactive"
                  }`}
                >
                  {hasError && (
                    <span className="caption">
                      {field.validationMessage || "This field is required"}
                    </span>
                  )}
                </div>
              </div>
            );
          })}

        <div className={styles.buttonContainer}>
          <Button
            buttonType="submit"
            aria-label="submit"
            className={`button ${styles.button}`}
            disabled={isSubmitting}
            animateOnHover
            iconLeft="arrowRight"
            iconRight="arrowRight"
            text={
              isSubmitting
                ? submitButton?.submissionText || "Submitting"
                : submitButton?.defaultText || "Submit"
            }
          ></Button>
        </div>
      </form>
      {submissionError && (
        <span className={cn("caption", styles.submissionError)}>
          {submissionError}
        </span>
      )}

      {/* default submission success message */}
      {/* {submissionSuccess && (
        <span className={styles.submissionSuccess}>{submissionSuccess}</span>
      )} */}
    </section>
  );
};

export default Form;
