// extracted by mini-css-extract-plugin
export var article = "styles-module--article--19c52";
export var articles = "styles-module--articles--7d184";
export var clearButton = "styles-module--clearButton--ee500";
export var clearButtonSvg = "styles-module--clearButtonSvg--7076d";
export var container = "styles-module--container--62555";
export var desktop = "1340px";
export var filterBar = "styles-module--filterBar--d4697";
export var filterButtonsMobile = "styles-module--filterButtonsMobile--5cd6e";
export var filterDropdowns = "styles-module--filterDropdowns--a463a";
export var footer = "styles-module--footer--00124";
export var giant = "2200px";
export var heading = "styles-module--heading--fc257";
export var headingText = "styles-module--headingText--797c2";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var mobileFilter = "styles-module--mobileFilter--f89d4";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";