import React from "react";
import cn from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { VideoPlay } from "@ottomotors/ottomotors.com/components";

import { IVariableMedia } from "@ottomotors/ottomotors-sanity";

export interface IProps {
  media: IVariableMedia;
  controls?: boolean;
  className?: string;
}

const VariableMedia = ({ media, controls = false, className }: IProps) => {
  return (
    <figure className={cn(className)}>
      {(media.video?.url && (
        <VideoPlay src={media.video.url} thumbnail={media.video?.thumbnail} captions={media.video?.captions} controls={controls} />
      )) || (
        <>
          {media.image?.asset && (
            <GatsbyImage
              image={media.image.asset.gatsbyImageData}
              alt={media.image.altText || "OTTO Motors"}
            />
          )}
        </>
      )}
    </figure>
  );
};

export default VariableMedia;
