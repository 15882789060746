import React from "react";
import AnchorLinksTop from "./components/AnchorLinksTop";
import AnchorLinksSide from "./components/AnchorLinksSide";

interface IAnchorLinks {
  data: {
    slices: any[];
    side?: boolean;
  };
}

const AnchorLinks = ({ data }: IAnchorLinks) => {
  const { slices, side } = data || {};

  if (!slices?.[0]) {
    return null;
  }

  // check if any slices on the page have an anchor link title assigned
  if (slices.filter((slice: any) => slice?.anchorLink?.title).length <= 0) {
    return null;
  }

  const linkData = [...slices].filter(
    ({ anchorLink }) => anchorLink?.title?.length > 0
  );

  const anchorLinks = linkData.map((slice: any) => {
    const label = slice.anchorLink.title as string;

    if (!label) {
      return null;
    }

    return {
      id: `#slice-${slice?._key}` || `UNKNOWN`,
      label,
    };
  });

  if (side) return <AnchorLinksSide anchorLinks={anchorLinks} />

  return <AnchorLinksTop anchorLinks={anchorLinks} />;
};

export default AnchorLinks;
