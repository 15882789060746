// extracted by mini-css-extract-plugin
export var container = "styles-module--container--26cea";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--cf9fd";
export var mobile = "400px";
export var orderedList = "styles-module--orderedList--30a60";
export var orderedListItem = "styles-module--orderedListItem--d4345";
export var pressReleaseStyling = "styles-module--pressReleaseStyling--19974";
export var shareButtons = "styles-module--shareButtons--5c85a";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var unorderedList = "styles-module--unorderedList--ba79d";
export var unorderedListItem = "styles-module--unorderedListItem--963d5";