// extracted by mini-css-extract-plugin
export var container = "styles-module--container--9ae22";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var shareButtons = "styles-module--shareButtons--84af6";
export var shareButtonsContainer = "styles-module--shareButtonsContainer--733c1";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var text = "styles-module--text--4b885";