// extracted by mini-css-extract-plugin
export var carouselNav = "styles-module--carouselNav--d5586";
export var center = "styles-module--center--942be";
export var container = "styles-module--container--00b47";
export var content = "styles-module--content--05637";
export var dark = "styles-module--dark--07f04";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--48be8";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--5b55e";
export var media = "styles-module--media--5fcf5";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var themed = "styles-module--themed--b5b33";