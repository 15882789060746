// extracted by mini-css-extract-plugin
export var alignLeft = "styles-module--alignLeft--d8adc";
export var button = "styles-module--button--cf675";
export var content = "styles-module--content--5e14b";
export var contentText = "styles-module--contentText--c345a";
export var desktop = "1340px";
export var fluid = "styles-module--fluid--cc6cc";
export var giant = "2200px";
export var icon = "styles-module--icon--02218";
export var iconButton = "styles-module--iconButton--fe861";
export var iconLeft = "styles-module--iconLeft--af51b";
export var iconRight = "styles-module--iconRight--c174f";
export var inverted = "styles-module--inverted--c8f83";
export var isHovered = "styles-module--isHovered--d1005";
export var large = "styles-module--large--c7c6c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var loader = "styles-module--loader--ccdfe";
export var loaderContainer = "styles-module--loaderContainer--180ea";
export var loading = "styles-module--loading--c75de";
export var mobile = "400px";
export var primary = "styles-module--primary--3470b";
export var regular = "styles-module--regular--b8899";
export var secondary = "styles-module--secondary--5e0e9";
export var small = "styles-module--small--6858e";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var spin = "styles-module--spin--bd9eb";
export var tablet = "768px";
export var text = "styles-module--text--14a3d";