import React from "react";
import cn from "classnames";

import * as styles from "./styles.module.scss";

interface IProps {
  label: string;
  body: string;
  className?: string;
}

const FooterItem = ({ label, body, className }: IProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <p className="label">{label}</p>
      <p className="b1">{body}</p>
    </div>
  );
};

export default FooterItem;
