import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import cn from "classnames";
import {
  Button,
  IntersectionAnimation,
  LayoutProvider,
} from "@ottomotors/ottomotors-common/components";
import { SliceConfig } from "@ottomotors/ottomotors.com/components";
import {
  IOttoMotorsEvents,
  IOttoMotorsEvent,
} from "@ottomotors/ottomotors-sanity";
import ResourceCard from "@ottomotors/ottomotors.com/components/CardCarousel/components/ResourceCard";
import { useEvents } from "@ottomotors/ottomotors.com/hooks";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsEvents;
}

const Events = ({ data }: IProps) => {
  // ---------------------------------------------------------------------------
  // variables

  const { heading, sliceConfig } = data || {};
  const { largeTablet } = useBreakpoints();

  const location = useLocation();
  const allEvents = useEvents();

  // ---------------------------------------------------------------------------
  // ref / state

  const eventsRef = useRef();

  // const [filter, setFilter] = React.useState(``);
  const [typeFilters, setTypeFilters] = useState<string[]>([]);
  const [topicFilters, setTopicFilters] = useState<string[]>([]);
  const [sort, setSort] = useState(`startDate`);
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage, setEventsPerPage] = useState(largeTablet ? 12 : 6);

  const [visibleEvents, setVisibleEvents] = useState(
    allEvents.slice(0, eventsPerPage)
  );

  // ---------------------------------------------------------------------------
  // methods

  // Add a function to parse URL and extract type from it
  const getTypeFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("type") || "";
  };

  const isDatePassed = (date: string) => {
    const torontoTz = "America/Toronto";
    const eventDate = dayjs.tz(date, torontoTz);
    const today = dayjs().tz(torontoTz).startOf("day");

    // Check if the date is after today and not the same as today
    return eventDate.isAfter(today) || eventDate.isSame(today, "day");
  };

  const getFilteredEvents = () => {
    let filteredEvents = JSON.parse(JSON.stringify(allEvents));

    const upcomingEvents = filteredEvents.filter((event: IOttoMotorsEvent) =>
      isDatePassed(event.startDate)
    );

    switch (sort) {
      case `alphabetical`:
        upcomingEvents.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }

          if (a.title > b.title) {
            return 1;
          }

          return 0;
        });

        break;

      case `startDate`:
        upcomingEvents.sort((a, b) => {
          if (a.startDate < b.startDate) {
            return -1;
          }

          if (a.startDate > b.startDate) {
            return 1;
          }

          return 0;
        });

        break;

      default:
        break;
    }

    return upcomingEvents;
  };

  const paginate = () => {
    const nextPage = currentPage + 1;
    const startIndex = currentPage * eventsPerPage;
    const endIndex = nextPage * eventsPerPage;

    const filteredEvents = getFilteredEvents().slice(startIndex, endIndex);

    setVisibleEvents([...visibleEvents, ...filteredEvents]);
    setCurrentPage(nextPage);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!eventsRef?.current) {
      return;
    }

    const urlType = getTypeFromURL();

    setTypeFilters(urlType ? [urlType] : []);

    if (eventsRef.current) {
      eventsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [location, eventsRef]);

  useEffect(() => {
    setCurrentPage(1);

    const filteredEvents = getFilteredEvents();

    setVisibleEvents(filteredEvents.slice(0, eventsPerPage));
  }, [topicFilters, typeFilters, sort, eventsPerPage]);

  useEffect(() => {
    const shownArticles = largeTablet ? 12 : 6;
    setEventsPerPage(shownArticles);
  }, [largeTablet]);

  return (
    <SliceConfig config={sliceConfig}>
      <section ref={eventsRef} className={styles.container}>
        <LayoutProvider grid>
          <h2 className={cn("h2", styles.heading)}>
            <span className={styles.headingText}>{heading}</span>
          </h2>
        </LayoutProvider>

        <LayoutProvider className={styles.articles} grid>
          {visibleEvents.map((article) => {
            return (
              <ResourceCard
                key={`article-filter-card-${article?._id}`}
                className={styles.article}
                data={article}
              />
            );
          })}

          <footer className={styles.footer}>
            <>
              <p className="caption">
                Currently displaying: {visibleEvents?.length} /{" "}
                {getFilteredEvents()?.length} results
              </p>
              {visibleEvents?.[0] &&
                visibleEvents?.length !== getFilteredEvents()?.length && (
                  <IntersectionAnimation>
                    <Button
                      text="Load More"
                      variant="secondary"
                      iconRight="arrowRight"
                      iconLeft="arrowRight"
                      animateOnHover
                      onClick={() => {
                        paginate();
                      }}
                    />
                  </IntersectionAnimation>
                )}
            </>
          </footer>
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default Events;
