// extracted by mini-css-extract-plugin
export var article = "styles-module--article--27ae0";
export var articles = "styles-module--articles--6ba88";
export var clearButton = "styles-module--clearButton--87483";
export var clearButtonSvg = "styles-module--clearButtonSvg--ffe5d";
export var container = "styles-module--container--cbd78";
export var desktop = "1340px";
export var filterBar = "styles-module--filterBar--4670b";
export var filterButtonsMobile = "styles-module--filterButtonsMobile--8f145";
export var footer = "styles-module--footer--849f6";
export var giant = "2200px";
export var heading = "styles-module--heading--1790a";
export var headingText = "styles-module--headingText--34edd";
export var headingTotal = "styles-module--headingTotal--44bb4";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var mobileFilter = "styles-module--mobileFilter--15638";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";