import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { SVG } from "@ottomotors/ottomotors-common/components";
import { useKeyPress } from "@ottomotors/ottomotors-common/hooks";

import * as styles from "./styles.module.scss";

interface IFilter {
  label: string;
  value: string;
}

interface IProps {
  label: string;
  filters: IFilter[];
  activeFilters?: string[];
  onInputClick?: any;
}

const FilterDropdown = ({
  label,
  filters,
  activeFilters,
  onInputClick = {},
}: IProps) => {
  const ref = useRef(null);

  const escKeyPressed = useKeyPress("Escape");

  const [isOpen, setIsOpen] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const updatedFilters = checked
      ? [...activeFilters, value] // Add the value to filters if checked
      : activeFilters.filter((filter) => filter !== value); // Remove the value from filters if unchecked
    onInputClick(updatedFilters); // Call the callback function with updated filters
  };

  const getFilterLabel = (value: string) => {
    if (!value) return;

    const filter = filters.find((item) => item.value === value);

    if (!filter) return;

    return filter.label;
  };

  const getActiveFilterTagJsx = (selectedFilters: string[] | undefined) => {
    let text = "Select";

    if (!selectedFilters?.[0]) return <span className="b2">{text}</span>;

    if (selectedFilters.length > 1) {
      const totalFilters = selectedFilters.length;
      text = `${totalFilters} selected`;
    } else {
      text = getFilterLabel(selectedFilters[0]);
    }

    return (
      <div
        className={cn("caption", styles.filtersSelectBoxText)}
        onClick={() => onInputClick([])}
      >
        <span>{text}</span>
        <figure className={styles.filterSelectBoxCross}>
          <SVG svg="x" />
        </figure>
      </div>
    );
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  useEffect(() => {
    if (escKeyPressed) {
      setIsOpen(false);
    }
  }, [escKeyPressed]);

  return (
    <div ref={ref} className={styles.filterSelectContainer}>
      <p className="b2">{label}</p>

      <div className={styles.filterSelect}>
        <button
          className={styles.filterSelectButton}
          type="button"
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          {getActiveFilterTagJsx(activeFilters)}

          <figure
            className={cn(styles.filterSelectChevron, {
              [styles.open]: isOpen,
            })}
          >
            <SVG svg="chevronDown" />
          </figure>
        </button>

        <ul
          className={cn(styles.filterSelectOptions, {
            [styles.visible]: isOpen,
          })}
        >
          <li className={cn(styles.clearAll)}>
            <button
              className={styles.clearButton}
              type="button"
              onClick={() => onInputClick([])}
            >
              <figure className={styles.filterInputCross}>
                <SVG svg="x" />
              </figure>

              <p className={cn("button")}>Clear All</p>
            </button>
          </li>
          {filters.map((filter, index) => (
            <li
              key={`integrators-filter-${filter.value}`}
              className={styles.filterSelectItem}
            >
              <div className={styles.filterInputContainer}>
                <input
                  className={styles.filterInput}
                  type="checkbox"
                  name={filter.label}
                  value={filter.value}
                  id={filter.value}
                  checked={activeFilters?.includes(filter.value)}
                  onChange={handleCheckboxChange} // Call handleCheckboxChange on checkbox change
                />

                <figure className={styles.filterInputCheck}>
                  <SVG svg="check" />
                </figure>
              </div>

              <label
                className={cn("label", styles.filterInputLabel)}
                htmlFor={filter.value}
              >
                {filter.label}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FilterDropdown;
