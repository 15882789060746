// extracted by mini-css-extract-plugin
export var card = "styles-module--card--f6363";
export var cardHeader = "styles-module--cardHeader--48f30";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--4cd80";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";