import React, { useCallback, useEffect, useState } from "react";
import { EmblaCarouselType } from "embla-carousel";
import cn from "classnames";
import { Button } from "@ottomotors/ottomotors-common/components";

import {
  IOttoMotorsAllAmrsAmr,
  IOttoMotorsCardCarouselArticleCard,
  IOttoMotorsCardCarouselFeaturedProductCard,
  IOttoMotorsCardCarouselPlansCard,
  IOttoMotorsCardCarouselProductCard,
} from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  embla: any;
  selectedIndex: number;
  theme?: "dark" | "light";
  items?:
    | IOttoMotorsAllAmrsAmr[]
    | IOttoMotorsCardCarouselArticleCard[]
    | IOttoMotorsCardCarouselFeaturedProductCard[]
    | IOttoMotorsCardCarouselPlansCard[]
    | IOttoMotorsCardCarouselProductCard[];
  pagination?: boolean;
  paginationBorders?: boolean;
  loop?: boolean;
  className?: string;
}

const CarouselNav = ({
  embla,
  selectedIndex,
  items,
  theme = "dark",
  pagination = false,
  paginationBorders = true,
  loop = false,
  className,
}: IProps) => {
  if (!items?.[0]) return;

  // normalize theme names to match Button theme names
  let themeColor = "darkOnLight";

  if (theme === "light") {
    themeColor = "lightOnDark";
  }

  const [scrollProgress, setScrollProgress] = useState(0);

  const onCarouselPrevClick = () => {
    if (embla) {
      embla.scrollPrev();
    }
  };

  const onCarouselNextClick = () => {
    if (embla) {
      embla.scrollNext();
    }
  };

  const onScroll = useCallback((emblaApi: EmblaCarouselType) => {
    const scrollSnapList = emblaApi.scrollSnapList();
    const selectedScrollSnap = emblaApi.selectedScrollSnap();
    const lastScrollSnap = scrollSnapList.length - 1;

    let progress = 0;

    // workaround for when embla is looping
    // when looping from the end of the carousel back to the start
    // embla.scrollProgress() will be set to 1 then jump back down to 0
    if (selectedScrollSnap == 0) {
      progress = 0;
    } else if (selectedScrollSnap === lastScrollSnap) {
      progress = 1;
    } else {
      progress = selectedScrollSnap / (scrollSnapList.length - 1);
    }

    setScrollProgress(progress * 100);
  }, []);

  useEffect(() => {
    if (!embla) return;

    onScroll(embla);
    embla.on("reInit", onScroll);
    embla.on("scroll", onScroll);
  }, [embla, onScroll]);

  return (
    <div className={cn(styles.container, className)}>
      {!pagination && (
        <div
          className={cn(styles.progress, {
            [styles.light]: themeColor === "lightOnDark",
          })}
        >
          <div
            className={cn(styles.progressBar, {
              [styles.light]: themeColor === "lightOnDark",
            })}
            style={{
              width: `${scrollProgress}%`,
            }}
          />
        </div>
      )}

      {pagination && (
        <p
          className={cn("label", styles.pagination, {
            [styles.light]: themeColor === "lightOnDark",
            [styles.borderless]: !paginationBorders,
          })}
        >
          {`${selectedIndex <= 8 ? `0` : ``}${selectedIndex + 1}/${
            items?.length <= 9 ? `0` : ``
          }${items?.length}`}
        </p>
      )}
      <div>
        <div className={styles.navButtons}>
          <Button
            className={styles.navButton}
            onClick={() => onCarouselPrevClick()}
            variant="iconButton"
            icon="arrowLeft"
            text="Carousel Previous"
            theme={themeColor}
            disabled={loop ? false : selectedIndex === 0}
          />
          <Button
            className={styles.navButton}
            onClick={() => onCarouselNextClick()}
            variant="iconButton"
            icon="arrowRight"
            text="Carousel Next"
            theme={themeColor}
            disabled={loop ? false : selectedIndex === items?.length - 1}
          />
        </div>
      </div>
    </div>
  );
};

export default CarouselNav;
