import React from "react";
import cn from "classnames";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { IRichTextImage } from "@ottomotors/ottomotors-sanity";
import { ISanityConfig } from "../../index";

import * as styles from "./styles.module.scss";

export interface IProps {
  data: IRichTextImage;
  getGatsbyImageData: (imageId: string, {}, sanityConfig: ISanityConfig) => any;
  gatsbyImageComponent: React.FunctionComponent<any>;
}

const PortableTextImage = ({
  data,
  gatsbyImageComponent,
  getGatsbyImageData,
}: IProps) => {
  const { caption, credit, aspectRatio } = data;

  return (
    <div className={styles.mediaContainer}>
      <figure
        className={styles.media}
        style={{
          aspectRatio: `${aspectRatio?.ratio ? aspectRatio.ratio : `1`}`,
        }}
      >
        <ImageContent
          data={data}
          gatsbyImageComponent={gatsbyImageComponent}
          getGatsbyImageData={getGatsbyImageData}
        />
      </figure>

      {caption && (
        <p className={cn("caption", styles.mediaCaption)}>{caption}</p>
      )}
      {credit && <p className={cn("caption", styles.mediaCredit)}>{credit}</p>}
    </div>
  );
};

export default PortableTextImage;

const ImageContent = ({ data, gatsbyImageComponent: GatsbyImage }: IProps) => {
  const { asset } = data;

  const isPreview = !asset.id;

  if (isPreview) {
    return <img src={asset.url} alt={asset.altText} />;
  }

  const sanityConfig = { projectId: `armc7p5y`, dataset: `production` };

  const imageData = getGatsbyImageData(asset.id, {}, sanityConfig);

  return (
    <GatsbyImage
      alt={asset.altText || data.caption || "OTTO Motors"}
      image={imageData}
      objectFit="contain"
    />
  );
};
