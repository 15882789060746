// extracted by mini-css-extract-plugin
export var alignLeft = "styles-module--alignLeft--9f18e";
export var alignRight = "styles-module--alignRight--87b52";
export var container = "styles-module--container--227a4";
export var content = "styles-module--content--5016a";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--94853";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--9ae38";
export var mediaContainer = "styles-module--mediaContainer--bbe38";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var text = "styles-module--text--6ec8e";
export var video = "styles-module--video--7d94d";