import React from "react";
import AccordionTab from "../AccordionTab";
import { IntersectionAnimation } from "@ottomotors/ottomotors-common/components";
import { IOttoMotorsWorkflow } from "@ottomotors/ottomotors-sanity/schemas/ottomotors.com/slices/workflow";

import * as styles from "./styles.module.scss";

interface IProps {
  items: IOttoMotorsWorkflow['items'];
  expandedTab: number;
  setExpandedTab: (index: number) => void;
}

const WorkflowAccordion = ({ items, expandedTab, setExpandedTab }: IProps) => {
  
  return (
    <ul className={styles.items}>
      {items.map((item, index) => {
        const isExpanded = expandedTab === index;
        const delay = index * 150;

        return (
          <li
            key={index}
            className={styles.item}
            onClick={() => setExpandedTab(index)}
          >
            <IntersectionAnimation delay={delay}>
              <AccordionTab item={item} isExpanded={isExpanded} />
            </IntersectionAnimation>
          </li>
        );
      })}
    </ul>
  );
};

export default WorkflowAccordion;
