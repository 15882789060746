// extracted by mini-css-extract-plugin
export var active = "styles-module--active--96fb0";
export var caption = "styles-module--caption--c6f6a";
export var captionContainer = "styles-module--captionContainer--2b7f0";
export var carousel = "styles-module--carousel--b8709";
export var carouselBottom = "styles-module--carouselBottom--22cba";
export var container = "styles-module--container--8d2e3";
export var counter = "styles-module--counter--66a41";
export var desktop = "1340px";
export var fadeIn = "styles-module--fadeIn--9b4ef";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--4b62d";
export var mobile = "400px";
export var navButton = "styles-module--navButton--a65be";
export var navButtons = "styles-module--navButtons--89566";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var workflowItemContent = "styles-module--workflowItemContent--a87a8";