import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router"
import Cookies from "js-cookie";
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { Script } from "gatsby";
import { useApp } from "@ottomotors/ottomotors.com/hooks";

// This component is used to load marketing and statistics scripts based on the user's cookie preferences from Cookiebot.
// Cookiebot script is loaded in the <Scripts /> component in the layout.

const CookieScripts = () => {
  const location = useLocation();
  const [marketingCookiesAccepted, setMarketingCookiesAccepted] = useState(false);
  const [statisticsCookiesAccepted, setStatisticsCookiesAccepted] = useState(false);
  const { setCookiesAccepted } = useApp();
  
  // Cookiebot event handling (https://www.cookiebot.com/en/developer/)
  useEffect(() => {
    window.addEventListener('CookiebotOnDialogInit', function () {
      console.log('CookiebotOnDialogInit');
    });
    window.addEventListener('CookiebotOnDecline', function () {
      console.log('CookiebotOnDecline');
      setCookiesAccepted(false);
    });
    window.addEventListener('CookiebotOnAccept', function () {
      console.log('CookiebotOnAccept');
      setCookiesAccepted(true);
      if (window.Cookiebot) {
        if (window.Cookiebot?.consent?.statistics) {
          setStatisticsCookiesAccepted(true);
          Cookies.set('gatsby-gdpr-google-tagmanager', 'true', { expires: 365 });
        }
        if (window.Cookiebot?.consent?.marketing) {
          setMarketingCookiesAccepted(true);
        }
        initializeAndTrack(location);
      }
    });
  }, [ location, setCookiesAccepted ]);

  // https://help.salesforce.com/s/articleView?id=000383435&type=1

  return (
    <>
      {marketingCookiesAccepted && (
        <>
          <PardotScript />
          <DriftScript />
        </>
      )}
      {statisticsCookiesAccepted && (<></>)}
    </>
  );
};

const PardotScript = () => {
  return (
    <>
      {/* NEW */}
      <Script type="text/javascript">
        {`
          piAId = '93812';
          piCId = '3790';
        `}
      </Script>
      <Script type="text/javascript" src="https://cdn.pardot.com/pd.js"></Script>

      {/* OLD */}
      {/* <Script key="https://pi.pardot.com/">
        {`
          piAId = '93812';
          piCId = '3790';
          piHostname = 'pi.pardot.com';
          (function() {
            function async_load(){
              var s = document.createElement('script'); s.type = 'text/javascript';
              s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
              var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
            }
            if(window.attachEvent) { window.attachEvent('onload', async_load); }
            else { window.addEventListener('load', async_load, false); }
          })();
        `}
      </Script> */}
    </>
  )
};

const DriftScript = () => {
  useEffect(() => {
    if (window.drift) {
      window.drift.on("ready", function (api) {
        setTimeout(() => {
          window.drift.config({ verticalOffset: 7 });
          window.drift.config({ horizontalOffset: 7 });
        }, 1000);
      });
      setTimeout(() => {
      }, 1000);
    }
  }, []);
  if (!process.env.GATSBY_DRIFT_ID) return null;
  return (
    <Script>
      {`
        "use strict";

        !function() {
          var t = window.driftt = window.drift = window.driftt || [];
          if (!t.init) {
            if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
            t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ], 
            t.factory = function(e) {
              return function() {
                var n = Array.prototype.slice.call(arguments);
                return n.unshift(e), t.push(n), t;
              };
            }, t.methods.forEach(function(e) {
              t[e] = t.factory(e);
            }), t.load = function(t) {
              var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
              o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
              var i = document.getElementsByTagName("script")[0];
              i.parentNode.insertBefore(o, i);
            };
          }
        }();

        drift.SNIPPET_VERSION = '0.3.1';
        drift.load('${process.env.GATSBY_DRIFT_ID}'); // Your embed ID found within your account goes here
      `}
    </Script>
  )
};

export default CookieScripts;
