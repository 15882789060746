import React from "react";
import cn from "classnames";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import {
  IconTextLockup,
  SliceConfig,
  TextLockup,
} from "@ottomotors/ottomotors.com/components";
import LineItem from "./components/LineItem";
import { IOttoMotorsInformationAndDownloads } from "@ottomotors/ottomotors-sanity";
import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsInformationAndDownloads;
}

const InformationAndDownloads = ({
  data: { _key, textLockup, body, features, downloads, sliceConfig },
}: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.container}>
        <LayoutProvider className={styles.layout} grid>
          {textLockup && (
            <TextLockup className={styles.textLockup} data={textLockup} />
          )}
          <div className={styles.content}>
            {body && <h4 className={cn("h4", styles.body)}>{body}</h4>}

            {features?.[0] && (
              <ul>
                {features.map((feature) => {
                  return (
                    <li key={`info-and-downloads-feature-${feature?._key}`}>
                      <IconTextLockup data={feature} />
                    </li>
                  );
                })}
              </ul>
            )}

            {downloads?.[0] && (
              <div>
                <ul>
                  {downloads.map((lineItem) => {
                    const { _key: lineItemKey } = lineItem || {};

                    return (
                      <li
                        key={`information-and-downloads-${_key}-download-${lineItemKey}`}
                      >
                        <LineItem data={lineItem} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </LayoutProvider>
      </div>
    </SliceConfig>
  );
};

export default InformationAndDownloads;
