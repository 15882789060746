import React from "react";
import { IRichTextEmbed } from "@ottomotors/ottomotors-sanity";
import { useApp } from "@ottomotors/ottomotors.com/src/hooks";
import { AcceptCookiesMessage } from "@ottomotors/ottomotors.com/src/components";

import * as styles from "./styles.module.scss";

export interface IProps {
  data: IRichTextEmbed;
}

const PortableTextEmbed = ({ data }: IProps) => {
  const { code } = data || {};
  const { cookiesAccepted } = useApp();

  if (!code) return null;

  if (!cookiesAccepted) {
    return <AcceptCookiesMessage />;
  }

  return (
    <div className={styles.mediaContainer}>
      <div dangerouslySetInnerHTML={{ __html: code }} />
    </div>
  );
};

export default PortableTextEmbed;
