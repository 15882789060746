import React, { useEffect, useState } from "react";
import cn from "classnames";
import {
  Button,
  Carousel,
  IntersectionAnimation,
  LayoutProvider,
} from "@ottomotors/ottomotors-common/components";
import {
  SliceConfig,
  TextLockup,
  VideoPlay,
} from "@ottomotors/ottomotors.com/components";
import { GatsbyImage } from "gatsby-plugin-image";
import useEmblaCarousel from "embla-carousel-react";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";
import { IOttoMotorsGalleryCarousel } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsGalleryCarousel;
}

const GalleryCarousel = ({
  data: { items, carouselTheme, textLockup, sliceConfig },
}: IProps) => {
  if (!items?.[0]) return null;

  const { smallMobile, tablet, largeTablet } = useBreakpoints();

  // todo: for some reason selectedIndex messes with the slide transitions
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [spaceBetween, setSpaceBetween] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: `start`,
  });

  const onCarouselPrevClick = () => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  };

  const onCarouselNextClick = () => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  };

  useEffect(() => {
    let spacePx = 0;
    if (smallMobile) {
      spacePx = 16;
    }

    if (tablet) {
      spacePx = 24;
    }

    if (largeTablet) {
      spacePx = 64;
    }

    setSpaceBetween(spacePx);
  }, [smallMobile, tablet, largeTablet]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", () => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider grid>
          {textLockup && (
            <article className={styles.textLockup}>
              <TextLockup data={textLockup} />
            </article>
          )}
          <IntersectionAnimation className={styles.carousel}>
            <Carousel
              embla={{
                api: emblaApi,
                ref: emblaRef,
              }}
              slidesPerView={1}
              spaceBetween={spaceBetween}
              slides={() =>
                items.map((item) => {
                  const portraitStyles = {
                    width: `auto`,
                    aspectRatio: `0.74`,
                    margin: `0 auto`,
                  };

                  let isPortrait = false;

                  if (item?.image?.asset) {
                    const { width, height } = item.image.asset || {};

                    if (height && width) {
                      isPortrait = height > width;
                    }
                  }

                  return (
                    <div key={item._key} className={styles.mediaContainer}>
                      <figure className={styles.media}>
                        {(item.video?.url && (
                          // <video loop playsInline>
                          //   <source src={item.video.url} type="video/mp4" />
                          // </video>
                          <VideoPlay src={item.video.url} thumbnail={item.video.thumbnail} />
                        )) || (
                          <>
                            {item.image?.asset && (
                              <GatsbyImage
                                style={{
                                  padding: `0`,
                                  ...(isPortrait && portraitStyles),
                                }}
                                image={item.image.asset.gatsbyImageData}
                                alt={item.image.altText || "OTTO Motors"}
                              />
                            )}
                          </>
                        )}
                      </figure>
                    </div>
                  );
                })
              }
            />
          </IntersectionAnimation>

          <p className={cn("b1", styles.caption)}>
            {items[selectedIndex]?.caption}
          </p>

          {items?.length > 1 && (
            <div className={styles.carouselNav}>
              <p
                className={cn("label", styles.pagination, {
                  [styles.light]: carouselTheme?.theme == "light",
                })}
              >
                {`${selectedIndex <= 8 ? `0` : ``}${selectedIndex + 1}/${
                  items?.length <= 9 ? `0` : ``
                }${items?.length}`}
              </p>

              <div className={styles.navButtons}>
                <Button
                  className={styles.navButton}
                  onClick={() => onCarouselPrevClick()}
                  variant="iconButton"
                  icon="arrowLeft"
                  text="Carousel Previous"
                  disabled={selectedIndex === 0}
                />

                <Button
                  className={styles.navButton}
                  onClick={() => onCarouselNextClick()}
                  variant="iconButton"
                  icon="arrowRight"
                  text="Carousel Next"
                  disabled={selectedIndex === items?.length - 1}
                />
              </div>
            </div>
          )}
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default GalleryCarousel;
