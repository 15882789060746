import React from "react";
import { PortableText } from "@portabletext/react";
import cn from "classnames";
import {
  IRichTextBlockQuote,
  IRichTextButtonGroup,
  IRichTextEmbed,
  IRichTextImage,
  IRichTextVideo,
  IRichTextSocialShare,
  IOttoMotorsSettings,
} from "@ottomotors/ottomotors-sanity";
import { ShareButtons } from "@ottomotors/ottomotors.com/components";
import {
  PortableTextBlockQuote,
  PortableTextButtonGroup,
  PortableTextImage,
  PortableTextVideo,
  PortableTextEmbed,
  PortableTextSocialShare,
} from "@ottomotors/ottomotors-common/components";
import { IPageMetadata } from "@ottomotors/ottomotors.com/components/SanitySlice";

import * as styles from "./styles.module.scss";

// todo: make features optional
interface ICustomComponentsConfig {
  gatsbyImageComponent: React.FunctionComponent<any>; // import { GatsbyImage } from 'gatsby-plugin-image';
  getGatsbyImageData: (imageId: string, {}, sanityConfig: ISanityConfig) => any; // import { getGatsbyImageData } from 'gatsby-source-sanity';
  siteSettings: IOttoMotorsSettings;
  textClassName?: string;
  showShareButtons?: boolean;
  pageMetadata?: IPageMetadata;
  isPressReleasePage?: boolean;
}

interface IProps extends ICustomComponentsConfig {
  containerClassName?: string;
  rawText: any;
}

export interface ISanityConfig {
  projectId: string;
  dataset: string;
}

interface IRichTextBlockQuoteProps {
  value: IRichTextBlockQuote;
}

interface IRichTextButtonGroupProps {
  value: IRichTextButtonGroup;
}

interface IRichTextImageProps {
  value: IRichTextImage;
}

interface IRichTextVideoProps {
  value: IRichTextVideo;
}

interface IRichTextEmbedProps {
  value: IRichTextEmbed;
}

interface IRichTextSocialShareProps {
  value: IRichTextSocialShare;
}

const customComponents = ({
  gatsbyImageComponent,
  getGatsbyImageData,
  siteSettings,
  textClassName,
  isPressReleasePage,
}: ICustomComponentsConfig) => ({
  list: {
    bullet: ({ children }: any) => (
      <ul
        className={cn(styles.unorderedList, {
          [styles.pressReleaseStyling]: isPressReleasePage,
        })}
      >
        {children}
      </ul>
    ),
    number: ({ children }: any) => (
      <ul className={styles.orderedList}>{children}</ul>
    ),
  },
  listItem: {
    number: ({ children }: any) => {
      const nodeStyle = children.props?.node.style || textClassName || "b1";

      return (
        <li className={cn(styles.orderedListItem, nodeStyle)}>
          <span>{children}</span>
        </li>
      );
    },
    bullet: ({ children }: any) => {
      const nodeStyle = children.props?.node.style || "b1";

      return (
        <li className={cn(styles.unorderedListItem, nodeStyle)}>
          <span>{children}</span>
        </li>
      );
    },
  },
  block: {
    normal: ({ children }: any) => <p className="b1">{children}</p>,
    d1: ({ children }: any) => <h2 className="d1">{children}</h2>,
    h1: ({ children }: any) => <h2 className="h1">{children}</h2>,
    h2: ({ children }: any) => <h3 className="h2">{children}</h3>,
    h3: ({ children }: any) => <h4 className="h3">{children}</h4>,
    h4: ({ children }: any) => <h4 className="h4">{children}</h4>,
    b1: ({ children }: any) => <p className="b1">{children}</p>,
    b2: ({ children }: any) => <p className="b2">{children}</p>,
    label: ({ children }: any) => <p className="label">{children}</p>,
    caption: ({ children }: any) => <p className="caption">{children}</p>,
  },
  marks: {
    color: ({ children, value }: any) => {
      const hex = value?.color?.hex;
      const colorStyle = {
        color: hex,
      };
      return <span style={colorStyle}>{children}</span>;
    },
    paragraphSection: ({ children }: any) => {
      return (
        <span style={{ display: `inline-block`, marginBottom: `0.5rem` }}>
          {children}
        </span>
      );
    },
    link: ({ children, value }: any) => {
      // const rel = value.href.startsWith("/")
      //   ? undefined
      //   : "noreferrer noopener";
      const rel = value.href.startsWith("/");

      const containsSiteUrl =
        process.env.GATSBY_SITE_URL &&
        value.href.indexOf(process.env.GATSBY_SITE_URL) !== -1;

      const isExternal = !rel && !containsSiteUrl;

      return (
        <a
          className={styles.link}
          href={value.href}
          rel={isExternal ? "norefferer noopener" : undefined}
          target={isExternal || value?.newTab ? "_blank" : undefined}
        >
          {children}
        </a>
      );
    },
  },
  types: {
    richTextBlockQuote: ({ value }: IRichTextBlockQuoteProps) => (
      <PortableTextBlockQuote data={value} />
    ),
    richTextButtonGroup: ({ value }: IRichTextButtonGroupProps) => (
      <PortableTextButtonGroup data={value} />
    ),
    richTextImage: ({ value }: IRichTextImageProps) => (
      <PortableTextImage
        gatsbyImageComponent={gatsbyImageComponent}
        getGatsbyImageData={getGatsbyImageData}
        data={value}
      />
    ),
    richTextVideo: ({ value }: IRichTextVideoProps) => (
      <PortableTextVideo data={value} />
    ),
    richTextEmbed: ({ value }: IRichTextEmbedProps) => (
      <PortableTextEmbed data={value} />
    ),
    richTextSocialShare: ({ value }: IRichTextSocialShareProps) => (
      <PortableTextSocialShare siteSettings={siteSettings} data={value} />
    ),
  },
});

const PortableTextRenderer = ({
  rawText,
  containerClassName,
  gatsbyImageComponent,
  getGatsbyImageData,
  siteSettings,
  textClassName,
  showShareButtons,
  pageMetadata,
  isPressReleasePage = false,
}: IProps) => {
  return (
    <div className={cn(styles.container, containerClassName)}>
      <PortableText
        value={rawText}
        components={customComponents({
          gatsbyImageComponent,
          getGatsbyImageData,
          siteSettings,
          textClassName,
          isPressReleasePage,
        })}
      />

      {showShareButtons && (
        <ShareButtons
          className={styles.shareButtons}
          heading={pageMetadata?.heading}
          description={pageMetadata?.description}
        />
      )}
    </div>
  );
};
export default PortableTextRenderer;
