import React from "react";
import cn from "classnames";
import {
  LayoutProvider,
  UniversalLink,
} from "@ottomotors/ottomotors-common/components";
import { SVG } from "@ottomotors/ottomotors-common/components";
import { useSiteSettings } from "@ottomotors/ottomotors.com/hooks";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";
import { getUniversalLinkUrl } from "@ottomotors/ottomotors-common/utils";

import * as styles from "./styles.module.scss";

const Footer = () => {
  const siteSettings = useSiteSettings();

  const { smallMobile } = useBreakpoints();

  const {
    contactNumber,
    copyrightText,
    locationLink,
    primaryLinksTop,
    secondaryLinks,
  } = siteSettings?.footer || {};

  const { socials } = siteSettings || {};

  const locationPageUrl = getUniversalLinkUrl(locationLink?.link);

  const badges = [];

  if (contactNumber) {
    badges.push(contactNumber);
  }

  if (locationLink?.link?.[0]) {
    badges.push(locationLink.link[0]);
  }

  return (
    <footer className={styles.container}>
      <LayoutProvider className={styles.grid} grid>
        <div className={styles.content}>
          <figure className={styles.logo}>
            <SVG svg="wordmarkRockwell" />
          </figure>

          <ul className={cn(styles.iconLinks)}>
            {contactNumber && (
              <li className={styles.iconLink}>
                <a
                  href={`${contactNumber}`}
                  className={cn(styles.iconLinkFlex, styles.primary)}
                >
                  <p className={cn("b1")}>
                    Talk to a Human
                    <br />
                    1-844-SEE-OTTO
                  </p>

                  <figure>
                    <SVG svg="phone" />
                  </figure>
                </a>
              </li>
            )}

            {locationPageUrl && (
              <li className={styles.iconLink}>
                <a href={locationPageUrl} className={cn(styles.iconLinkFlex)}>
                  <p className={cn("b1")}>Find a Certified Dealer</p>

                  <figure>
                    <SVG svg="pin" />
                  </figure>
                </a>
              </li>
            )}
          </ul>
        </div>

        {primaryLinksTop?.[0] && (
          <div className={styles.primaryLinksContainer}>
            {primaryLinksTop?.[0] && (
              <div className={styles.primaryLinks}>
                {primaryLinksTop.map((primaryLink) => {
                  const { _key, heading, links } = primaryLink || {};

                  return (
                    <div
                      key={`footer-primary-link-group-${_key}`}
                      className={styles.primaryLinkGroup}
                    >
                      {heading && (
                        <p
                          className={cn(
                            "label",
                            styles.primaryLinkGroupHeading
                          )}
                        >
                          {heading}
                        </p>
                      )}

                      {links?.[0] && (
                        <ul className={styles.primaryLinkGroupLinks}>
                          {links.map((link) => {
                            return (
                              <li
                                key={`footer-primary-link-group-link-${link?._key}`}
                              >
                                <UniversalLink
                                  link={link}
                                  variant="text"
                                  typestyle="b2"
                                />
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}

        <div className={styles.footer}>
          <div className={styles.footerBorder} />

          <div className={styles.footerLinks}>
            {secondaryLinks?.[0] && (
              <ul className={styles.secondaryLinks}>
                {secondaryLinks.map((secondaryLink) => {
                  const url = getUniversalLinkUrl(secondaryLink?.link);

                  return (
                    <li
                      key={`footer-secondary-link-${secondaryLink?._key}`}
                      className={cn("b2", styles.secondaryLink)}
                    >
                      <a href={url}>{secondaryLink?.text}</a>
                    </li>
                  );
                })}
              </ul>
            )}

            {socials?.[0] && (
              <div className={styles.socialLinks}>
                {socials.map((socialLink) => {
                  const { _key, url, platform } = socialLink || {};

                  return (
                    <div
                      key={`rich-text-social-link-${_key}`}
                      className={styles.button}
                    >
                      <a
                        href={url?.url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <figure className={styles.icon}>
                          <SVG svg={platform.toLowerCase()} />
                        </figure>
                      </a>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {copyrightText && (
            <p className={cn("b2", styles.copyright)}>{`© ${copyrightText}`}</p>
          )}
        </div>
      </LayoutProvider>
    </footer>
  );
};

export default Footer;
