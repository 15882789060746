import React from "react";
import cn from "classnames";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import {
  CardCarousel,
  SliceConfig,
  TextLockup,
} from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsCardCarouselPlans } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsCardCarouselPlans;
}

const CardCarouselPlans = ({
  data: { cards, textLockup, sliceConfig },
}: IProps) => {
  const isColumns = cards.length <= 3;

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider grid>
          {textLockup && (
            <TextLockup className={styles.text} data={textLockup} />
          )}
          <CardCarousel
            className={cn(styles.carousel, { [styles.columns]: isColumns })}
            data={{ cards }}
            variant="plan"
          />
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default CardCarouselPlans;
