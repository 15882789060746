import * as breakpoints from "@ottomotors/ottomotors-common/styles/breakpoints.module.scss";
import { IFont, ITypestyle } from "@ottomotors/ottomotors-sanity";
import { pxStringToNumber } from "@ottomotors/ottomotors-common/utils";

const getTypestyleCss = (
  typestyles: ITypestyle[],
  getFontById: (id: string) => IFont | undefined
) => {
  const TABLET_BREAKPOINT = breakpoints.tablet; // 796px
  const DESKTOP_BREAKPOINT = breakpoints.largeTablet; // 1025px

  const minTabletPx = pxStringToNumber(TABLET_BREAKPOINT);
  const minDesktopPx = pxStringToNumber(DESKTOP_BREAKPOINT);

  if (!typestyles) return ``;

  let css = ``;

  typestyles.forEach((typestyle) => {
    const font = getFontById(typestyle.font._id);

    if (!font) {
      return;
    }

    css += `
				.${typestyle.className} {
					font-family: ${font?.title};
					font-size: ${typestyle.fontSizes.mobile};
					line-height: ${typestyle.fontSizes.mobileLineHeight};
					letter-spacing: ${typestyle.fontSizes.mobileLetterSpacing};
					text-indent: ${typestyle.fontSizes.mobileTextIndent};
					text-transform: ${typestyle.uppercase ? "uppercase" : "none"};
					text-decoration: ${typestyle.underline ? "underline" : "none"};

          ${
            typestyle?.fontVariationSettings &&
            `font-variation-settings: ${typestyle.fontVariationSettings};`
          }
				}
				@media (min-width: ${minTabletPx}px) {
					.${typestyle.className} {
            line-height: ${typestyle.fontSizes.tabletLineHeight};
            letter-spacing: ${typestyle.fontSizes.tabletLetterSpacing};
						font-size: ${typestyle.fontSizes.tablet};
					  text-indent: ${typestyle.fontSizes.tabletTextIndent};
					}
				}
				@media (min-width: ${minDesktopPx}px) {
					.${typestyle.className} {
            line-height: ${typestyle.fontSizes.desktopLineHeight};
            letter-spacing: ${typestyle.fontSizes.desktopLetterSpacing};
						font-size: ${typestyle.fontSizes.desktop};
					  text-indent: ${typestyle.fontSizes.desktopTextIndent};
					}
				}
			`;
  });

  return css;
};

export default getTypestyleCss;
