// extracted by mini-css-extract-plugin
export var carousel = "styles-module--carousel--3207c";
export var container = "styles-module--container--b07f5";
export var desktop = "1340px";
export var giant = "2200px";
export var inset = "styles-module--inset--8ff7e";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var text = "styles-module--text--eb06c";