import { useStaticQuery, graphql } from "gatsby";

const useEvents = () => {
  const allEventData = useStaticQuery(graphql`
    query eventsQuery {
      # ebooks
      allSanityOttomotorsEvent {
        nodes {
          _id
          _type

          title

          slug {
            current
          }
          category
          startDate
          endDate

          cardButtonText
          cardImage {
            altText
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  const allEvents = allEventData.allSanityOttomotorsEvent.nodes;

  return allEvents;
};

export default useEvents;
