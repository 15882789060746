import React, { useEffect, useRef, useState } from "react";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import { useApp, useIframeResizer } from "@ottomotors/ottomotors.com/hooks";
import { AcceptCookiesMessage, SliceConfig } from "@ottomotors/ottomotors.com/components";
import { SVG } from "@ottomotors/ottomotors-common/components";
import { TextLockup } from "@ottomotors/ottomotors.com/components";

import { IOttoMotorsPardotEmbed } from "@ottomotors/ottomotors-sanity";
import cn from "classnames";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsPardotEmbed;
}

const PardotEmbed = ({
                       data: {
                         _key,
                         sliceConfig,
                         textLockup,
                         links,
                         embed,
                         embedHeightDesktop,
                         embedHeightMobile,
                       },
                     }: IProps) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [iframeWidth, setIframeWidth] = useState(0);
  const { cookiesAccepted } = useApp();

  // Use the updated useIframeResizer hook
  const { iframeSrc, updateIframeAttributes } = useIframeResizer(
      iframeWidth,
      embed,
      embedHeightDesktop,
      embedHeightMobile
  );

  const updateIframeWidth = () => {
    const { width } = iframeRef.current.getBoundingClientRect();
    setIframeWidth(width);
  };

  useEffect(() => {
    if (!iframeRef?.current) return;

    updateIframeWidth();
    window.addEventListener("resize", updateIframeWidth);

    return () => {
      window.removeEventListener("resize", updateIframeWidth);
    };
  }, [iframeRef]);

  // Load the iframe once cookies are accepted
  useEffect(() => {
    if (cookiesAccepted && iframeSrc && iframeRef?.current) {
      updateIframeWidth();
      updateIframeAttributes(iframeRef); // Update iframe dimensions
      iframeRef.current.src = iframeSrc; // Set the iframe src from the parsed value
    }
  }, [cookiesAccepted, iframeSrc, updateIframeAttributes]);

  return (
      <SliceConfig config={sliceConfig}>
        <section className={styles.container}>
          <LayoutProvider grid>
            <div className={cn(styles.textLockup)}>
              <TextLockup data={textLockup} />

              <ul className={cn(styles.iconLinks)}>
                {links?.[0] &&
                    links.map((link, index) => {
                      const { actionType, actionValue, primary, text } = link;
                      const lines = text.split("\n");

                      const heading = lines?.[0] || "";
                      const body = lines?.[1] || "";

                      let prefix = ``;
                      let svg = `settings`;

                      if (actionType === "email") {
                        prefix = `mailto:`;
                        svg = `mail`;
                      } else if (actionType === "phone") {
                        prefix = `tel:`;
                        svg = `phone`;
                      }

                      return (
                          <li
                              key={`${_key}-iconlink-${actionType}-${actionValue}-${index}`}
                              className={styles.iconLink}
                          >
                            <a
                                href={`${prefix}${actionValue}`}
                                className={cn(styles.iconLinkFlex, {
                                  [styles.primary]: primary,
                                })}
                            >
                              <p className={cn("b2", styles.iconLinkContent)}>
                                {heading && <span>{heading}</span>}
                                {body && (
                                    <span className={styles.iconLinkBody}>{body}</span>
                                )}
                              </p>
                              <figure>
                                <SVG svg={svg} />
                              </figure>
                            </a>
                          </li>
                      );
                    })}
              </ul>
            </div>

            {cookiesAccepted ? (
                <div className={styles.iframe}>
                  <iframe
                      ref={iframeRef} // Use the ref for the iframe
                      width="100%"
                      height={embedHeightDesktop} // Dynamic height handled in the hook
                      style={{ border: "0" }}
                      allowTransparency="true"
                      frameBorder="0"
                  />
                </div>
            ) : (
                <div className={styles.iframe}>
                  <AcceptCookiesMessage text="access this form" />
                </div>
            )}
          </LayoutProvider>
        </section>
      </SliceConfig>
  );
};

export default PardotEmbed;
