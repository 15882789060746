import React from "react";
import {
  Button,
  LayoutProvider,
} from "@ottomotors/ottomotors-common/components";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const FourOhFour = () => {
  return (
    <div className={styles.container}>
      <LayoutProvider padding maxWidth className={styles.content}>
        <h1
          className={cn("h1", styles.title)}
        >{`Oops.\nThis page does not exist.`}</h1>

        <Button to="/" text="Back to Home" />
      </LayoutProvider>
    </div>
  );
};

export default FourOhFour;
