// extracted by mini-css-extract-plugin
export var buttonContainer = "styles-module--buttonContainer--74f21";
export var container = "styles-module--container--8e54a";
export var content = "styles-module--content--a9322";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--e3733";
export var highlight = "styles-module--highlight--83381";
export var highlighted = "styles-module--highlighted--4b76d";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var sellingPoints = "styles-module--sellingPoints--fdc73";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var tag = "styles-module--tag--93100";