import React from "react";
import cn from "classnames";
import { ISliceConfig } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  children: React.ReactNode;
  config?: ISliceConfig;
  noPadding?: boolean;
  forwardRef?: React.ForwardedRef<HTMLDivElement>;
}

interface ISliceConfigColors {
  color?: string;
  backgroundColor?: string;
}

const SliceConfig = ({ children, config, forwardRef }: IProps) => {
  const { backgroundColor, textColor } = config || {};

  const colorStyles: ISliceConfigColors = {
    color: `#1E1E1E`,
    backgroundColor: `#EFEFEF`,
  };
  if (textColor?.hex) {
    colorStyles.color = textColor.hex;
  }
  if (backgroundColor?.hex) {
    colorStyles.backgroundColor = backgroundColor.hex;
  }

  return (
    <div ref={forwardRef} style={colorStyles} className={cn(styles.container)}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default SliceConfig;
