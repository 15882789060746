import React, { useEffect, useRef } from "react";
import { SVG } from "@ottomotors/ottomotors-common/components";
import {
  useAccordion,
  useBreakpoints,
} from "@ottomotors/ottomotors-common/hooks";
import { GatsbyImage } from "gatsby-plugin-image";

import cn from "classnames";

import * as styles from "./styles.module.scss";

const AccordionTab = ({ data, isExpanded, theme }) => {
  const { heading, body, icon, image, video } = data || {};

  const { containerHeight, contentRef, setIsExpanded } = useAccordion();
  const { tablet } = useBreakpoints();

  const videoRef = useRef(null);

  const hasMedia = video?.url || image?.asset?.gatsbyImageData;

  useEffect(() => {
    setIsExpanded(isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    if (!videoRef?.current) return;

    if (isExpanded) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isExpanded]);

  return (
    <article
      className={cn(styles.container, {
        [styles.expanded]: isExpanded,
        [styles.dark]: theme === "dark",
      })}
    >
      <header className={cn("b1", styles.header)}>
        <p className={styles.heading}>{heading}</p>

        {icon?.icon && (
          <figure className={styles.icon}>
            <SVG svg={icon?.icon} />
          </figure>
        )}
      </header>

      <div
        className={styles.contentContainer}
        style={{
          height: containerHeight,
        }}
      >
        <article ref={contentRef}>
          <p className={cn("b2", styles.body)}>{body}</p>

          {!tablet && hasMedia && (
            <figure className={styles.media}>
              {video?.url ? (
                <video ref={videoRef} autoPlay muted loop playsInline>
                  <source src={video.url} type="video/mp4" />
                </video>
              ) : (
                image?.asset && (
                  <GatsbyImage
                    style={{ padding: `0` }}
                    image={image.asset.gatsbyImageData}
                    alt={image.altText || "OTTO Motors"}
                  />
                )
              )}
            </figure>
          )}
        </article>
      </div>
    </article>
  );
};

export default AccordionTab;
