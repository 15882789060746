import React from "react";
import cn from "classnames";
import { IPlace } from "../Map";
import { Button } from "@ottomotors/ottomotors-common/components";
import { IGeolocationPosition } from "@ottomotors/ottomotors-common/hooks/useGeolocation";
import { calculateDistanceBetweenGeolocations } from "@ottomotors/ottomotors-common/utils";

import { useApp } from "@ottomotors/ottomotors.com/hooks";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IPlace;
  setSelectedPlace: React.Dispatch<React.SetStateAction<IPlace | null>>;
  userGeolocation: IGeolocationPosition | null;
  className?: string;
}

const DealerCard = ({
  data: dealer,
  setSelectedPlace,
  userGeolocation,
  className,
}: IProps) => {
  const {
    name,
    location,
    address,
    distance,
    buttonText,
    contactOverlayOverride,
  } = dealer || {};

  const { setIsContactModalOpen, setContactModalEmbedOverride } = useApp();

  const { textLockup, embed, embedHeightDesktop, embedHeightMobile } =
    contactOverlayOverride || {};

  // const distance = calculateDistanceBetweenGeolocations(
  //   userGeolocation,
  //   location
  // );

  // console.log(distance, "distance");
  // Example usage:
  // const distance = calculateDistance(52.5200, 13.4050, 48.8566, 2.3522); // Berlin to Paris
  // console.log(`Distance: ${distance.toFixed(2)} km`);

  const handleOnClick = (dealer: IPlace) => {
    if (!setSelectedPlace) return;

    setSelectedPlace(dealer);
  };

  const handleOnContactClick = () => {
    if (textLockup || (embed && embedHeightDesktop && embedHeightMobile)) {
      setContactModalEmbedOverride({
        textLockup: textLockup,
        embed,
        embedHeightDesktop,
        embedHeightMobile,
      });
    }
    setIsContactModalOpen(true);
  };

  return (
    <div
      className={cn(styles.container, className)}
      onClick={() => handleOnClick(dealer)}
    >
      {userGeolocation && (
        <p className={cn("caption", styles.distance)}>{distance} km</p>
      )}

      <article>
        <p className="b1">{name}</p>
        <p className={cn("b1", styles.address)}>{address}</p>

        <div className={styles.button}>
          <Button
            text={buttonText || "Introduce Me"}
            iconRight="arrowRight"
            iconLeft="arrowRight"
            animateOnHover
            variant="text"
            onClick={() => handleOnContactClick()}
          />
        </div>
      </article>
    </div>
  );
};

export default DealerCard;
