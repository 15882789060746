// extracted by mini-css-extract-plugin
export var container = "styles-module--container--2fca5";
export var content = "styles-module--content--54610";
export var desktop = "1340px";
export var faqItems = "styles-module--faqItems--ea15e";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var layout = "styles-module--layout--06bb5";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var textLockup = "styles-module--textLockup--02e1c";