import React from "react";
import cn from "classnames";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import {
  CardCarousel,
  TextLockup,
  SliceConfig,
} from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsCardCarouselArticle } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsCardCarouselArticle;
}

const CardCarouselResources = ({
  data: { cards, carouselTheme, textLockup, sliceConfig },
}: IProps) => {
  if (!cards?.[0]) return null;

  const isInset = cards.length <= 3;

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider grid>
          {textLockup && (
            <header className={styles.header}>
              <TextLockup data={textLockup} />
            </header>
          )}

          <CardCarousel
            className={cn(styles.carousel, {
              [styles.inset]: isInset,
            })}
            data={{ cards }}
            variant="resources"
            theme={carouselTheme}
            landscapeCardImage={cards.length <= 2}
          />
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default CardCarouselResources;
