import React, { useEffect, useState } from "react";
import cn from "classnames";
import {
  SVG,
  LayoutProvider,
  Button,
} from "@ottomotors/ottomotors-common/components";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";

import * as styles from "./styles.module.scss";

import FilterList from "../FilterList";

interface IProps {
  isOpen: boolean;
  setIsOpen: any;
  filters: any;
  activeTypes: string[];
  setActiveTypes: any;
  activeTopics: string[];
  setActiveTopics: any;
  activeLanguages: string[];
  setActiveLanguages: any;
  className?: string;
}

const MobileFilter = ({
  isOpen = true,
  setIsOpen,
  filters,
  activeTypes,
  setActiveTypes,
  activeTopics,
  setActiveTopics,
  activeLanguages,
  setActiveLanguages,
  className,
}: IProps) => {
  const { tablet } = useBreakpoints();

  const [topicFilters, setTopicFilters] = useState([]);
  const [typeFilters, setTypeFilters] = useState([]);
  const [languageFilters, setLanguageFilters] = useState([]);

  const handleOnClick = () => {
    setActiveTypes(typeFilters);
    setActiveTopics(topicFilters);
    setActiveLanguages(languageFilters);
    setIsOpen(false);
  };

  const clearAllTypes = () => {
    setActiveTypes([]);
    setIsOpen(false);
  };

  const clearAllTopics = () => {
    setActiveTopics([]);
    setIsOpen(false);
  };

  const clearAllLanguages = () => {
    setActiveLanguages([]);
    setIsOpen(false);
  };

  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add(styles.disableScroll);
  //   } else {
  //     document.body.classList.remove(styles.disableScroll);
  //   }
  // }, [isOpen]);

  useEffect(() => {
    function handleResize() {
      if (tablet) {
        setIsOpen(false);
      }
    }

    window.addEventListener(`resize`, handleResize);

    handleResize();

    return () => window.removeEventListener(`resize`, handleResize);
  }, []);

  useEffect(() => {
    setTopicFilters(activeTopics);
    setTypeFilters(activeTypes);
    setLanguageFilters(activeLanguages);
  }, [activeTypes, activeTopics, activeLanguages]);

  return (
    <LayoutProvider
      className={cn(styles.grid, className, { [styles.open]: isOpen })}
      grid
      fullWidthGrid
    >
      <aside className={cn(styles.container, { [styles.open]: isOpen })}>
        <header className={styles.header}>
          <button
            type="button"
            className={styles.closeButton}
            onClick={() => setIsOpen(false)}
          >
            <span className="button">Close</span>
            <figure className={styles.closeButtonSvg}>
              <SVG svg="x" />
            </figure>
          </button>
        </header>

        <div className={styles.filters}>
          {filters.map((filterItem, index) => {
            const { type, filters, heading } = filterItem || {};
            let activeFilters;
            let setActiveFilters;
            let clearAll;

            switch (type) {
              case "topic":
                activeFilters = topicFilters;
                setActiveFilters = setTopicFilters;
                clearAll = clearAllTopics;
                break;

              case "type":
                activeFilters = typeFilters;
                setActiveFilters = setTypeFilters;
                clearAll = clearAllTypes;
                break;

              case "language":
                activeFilters = languageFilters;
                setActiveFilters = setLanguageFilters;
                clearAll = clearAllLanguages;
                break;

              default:
                break;
            }

            return (
              <div key={`article-filter-mobile-filter-${heading}-${index}`}>
                <p className="b2">{heading}</p>

                <FilterList
                  filters={filters}
                  activeFilters={activeFilters}
                  onInputClick={setActiveFilters}
                  clearAll={clearAll}
                />
              </div>
            );
          })}
        </div>

        <Button
          className={styles.button}
          text="Apply Filters"
          iconRight="arrowRight"
          onClick={() => handleOnClick()}
        />
      </aside>
    </LayoutProvider>
  );
};

export default MobileFilter;
