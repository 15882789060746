import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import { IOttoMotorsIntegratorCard } from "@ottomotors/ottomotors-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Button,
  UniversalLink,
} from "@ottomotors/ottomotors-common/components";
import { useApp } from "@ottomotors/ottomotors.com/hooks";

interface IProps {
  data: IOttoMotorsIntegratorCard;
  className?: string;
}

const IntegratorCard = ({ data, className }: IProps) => {
  const {
    heading,
    body,
    image,
    button,
    formButtonText,
    contactFormTextLockup,
    embed,
    embedHeightDesktop,
    embedHeightMobile,
  } = data || {};

  const { setIsContactModalOpen, setContactModalEmbedOverride } = useApp();

  const onClick = () => {
    if (
      contactFormTextLockup ||
      (embed && embedHeightDesktop && embedHeightMobile)
    ) {
      setContactModalEmbedOverride({
        textLockup: contactFormTextLockup,
        embed,
        embedHeightDesktop,
        embedHeightMobile,
      });
    }

    setIsContactModalOpen(true);
  };

  return (
    <article className={cn(styles.container, className)}>
      <figure className={styles.image}>
        {image?.asset?.gatsbyImageData && (
          <GatsbyImage
            image={image.asset.gatsbyImageData}
            alt={image.altText || "OTTO Motors"}
            objectFit="contain"
          />
        )}
      </figure>

      {heading && <h4 className="h4">{heading}</h4>}
      {body && <p className="b2">{body}</p>}

      {(button || formButtonText) && (
        <div className={styles.buttons}>
          {button && (
            <UniversalLink
              link={button}
              iconRight="arrowRight"
              iconLeft="arrowRight"
              animateOnHover
              fluid
            />
          )}

          {formButtonText && (
            <Button
              text={formButtonText}
              iconRight="arrowRight"
              iconLeft="arrowRight"
              animateOnHover
              fluid
              variant="secondary"
              onClick={onClick}
            />
          )}
        </div>
      )}
    </article>
  );
};

export default IntegratorCard;
