// extracted by mini-css-extract-plugin
export var container = "styles-module--container--fb1f3";
export var content = "styles-module--content--5ed72";
export var dealer = "styles-module--dealer--8ee46";
export var dealers = "styles-module--dealers--2754c";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--43fe3";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var map = "styles-module--map--de8bd";
export var message = "styles-module--message--9b643";
export var messageAddress = "styles-module--messageAddress--6b9c3";
export var mobile = "400px";
export var search = "styles-module--search--cbbe0";
export var searchContainer = "styles-module--searchContainer--f0adb";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";