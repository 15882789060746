// extracted by mini-css-extract-plugin
export var button = "styles-module--button--fcf89";
export var buttonContainer = "styles-module--buttonContainer--6c7d8";
export var container = "styles-module--container--2c03c";
export var content = "styles-module--content--e0848";
export var desktop = "1340px";
export var giant = "2200px";
export var image = "styles-module--image--28656";
export var landscape = "styles-module--landscape--502a0";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var tag = "styles-module--tag--66874";
export var text = "styles-module--text--5301c";