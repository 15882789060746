import React, { useState, useEffect } from "react";
import cn from "classnames";
import { IntersectionAnimation } from "@ottomotors/ottomotors-common/components";
import AccordionTab from "./components/AccordionTab";
import { IOttoMotorsValuePropsItem } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  items: IOttoMotorsValuePropsItem[];
  className?: string;
  setActiveItem?: any;
  setHoveredItem?: any;
  theme?: string;
}

const AccordionTabs = ({
  items,
  className,
  setActiveItem,
  setHoveredItem,
  theme,
}: IProps) => {
  if (!items?.[0]) {
    return null;
  }

  const [expandedTab, setExpandedTab] = useState(0);

  useEffect(() => {
    if (!setActiveItem) {
      return;
    }

    setActiveItem(expandedTab);
  }, [expandedTab]);

  return (
    <ul className={cn(styles.container, className)}>
      {items.map((item, index) => {
        const isExpanded = expandedTab === index;
        const delay = index * 150;

        return (
          <li
            key={`accordion-tabs-tab-${item?._key}`}
            onClick={() => setExpandedTab(index)}
            onMouseOver={() => setHoveredItem(index)}
          >
            <IntersectionAnimation delay={delay}>
              <AccordionTab data={item} isExpanded={isExpanded} theme={theme} />
            </IntersectionAnimation>
          </li>
        );
      })}
    </ul>
  );
};

export default AccordionTabs;
