// extracted by mini-css-extract-plugin
export var borderless = "styles-module--borderless--ebdbe";
export var container = "styles-module--container--e9d97";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--09626";
export var mobile = "400px";
export var navButton = "styles-module--navButton--c29f5";
export var navButtons = "styles-module--navButtons--4866c";
export var pagination = "styles-module--pagination--b2c0b";
export var progress = "styles-module--progress--966da";
export var progressBar = "styles-module--progressBar--aa0d9";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var spaceBetween = "styles-module--spaceBetween--cad85";
export var tablet = "768px";