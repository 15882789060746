import React, { useEffect } from "react";
import {
  Button,
  LayoutProvider,
} from "@ottomotors/ottomotors-common/components";
import { SliceConfig, TextLockup } from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsJobviteEmbed } from "@ottomotors/ottomotors-sanity";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsJobviteEmbed;
}

const JobviteEmbed = ({
  data: { textLockup, careerSiteName, sliceConfig },
}: IProps) => {
  const { largeTablet } = useBreakpoints();

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src =
      "//jobs.jobvite.com/__assets__/scripts/careersite/public/iframe.js";
    script.async = true;

    // script.onload = () => {
    //   const iframe = document.querySelector(".jv-careersite iframe");
    //   if (iframe) {
    //     iframe.addEventListener("load", () => {
    //       // // Get the iframe document
    //       // const iframeDoc = iframe?.contentWindow?.document;
    //       const iframeDoc =
    //         iframe.contentDocument || iframe.contentWindow.document;

    //       console.log(iframeDoc?.head, "da head");

    //       // Create a style element
    //       const style = document.createElement("style");
    //       style.innerHTML = `
    //         @font-face {
    //           font-family: 'Riforma LL Light';
    //           src: url('/fonts/RiformaLL-Light.woff2') format('woff2'),
    //                url('/fonts/RiformaLL-Light.woff') format('woff');
    //           font-weight: normal;
    //           font-style: normal;
    //         }
    //       `;

    //       // Append the style to the iframe document head
    //       iframeDoc.head.appendChild(style);
    //     });
    //   }
    // };

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup: Remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [largeTablet, careerSiteName]);

  return (
    <SliceConfig config={sliceConfig}>
      <LayoutProvider grid>
        <div className={styles.container}>
          {textLockup && (
            <article className={styles.content}>
              <TextLockup data={textLockup} buttonFlexDirection="row" />
            </article>
          )}

          {!largeTablet && (
            <article>
              <p>Go to job listings</p>
              <Button
                text="See job listings"
                href={`https://jobs.jobvite.com/${careerSiteName}`}
              />
            </article>
          )}

          {largeTablet && (
            <div
              className="jv-careersite"
              data-careersite={careerSiteName}
            ></div>
          )}
        </div>
      </LayoutProvider>
    </SliceConfig>
  );
};

export default JobviteEmbed;
