import React, { useLayoutEffect, useEffect, useRef, useState } from "react";
import cn from "classnames";
import {
  Carousel,
  CarouselNav,
  LayoutProvider,
} from "@ottomotors/ottomotors-common/components";
import { SliceConfig, TextLockup } from "@ottomotors/ottomotors.com/components";
import SpecItem from "./components/SpecItem";
import { IOttoMotorsProductInDetail } from "@ottomotors/ottomotors-sanity";
import useEmblaCarousel from "embla-carousel-react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gradient from "~/src/assets/images/gradient.png";

gsap.registerPlugin(ScrollTrigger);

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsProductInDetail;
}

const SEQUENCES = {
  "ottomotors.amr.sequence.ottoOneHundred": {
    id: "100",
    frames: 150,
    // define the exact frame number that each spec should be active. the number of elements in specFrames is equal to the number of specs.
    specFrames: [20, 40, 73, 127],
  },
  "ottomotors.amr.sequence.ottoSixHundred": {
    id: "600",
    frames: 160,
    specFrames: [19, 41, 100, 133],
  },
  "ottomotors.amr.sequence.ottoTwelveHundred": {
    id: "1200",
    frames: 150,
    specFrames: [19, 41, 100, 133],
  },
  "ottomotors.amr.sequence.ottoFifteenHundred": {
    id: "1500",
    frames: 150,
    specFrames: [19, 41, 86, 133],
  },
  "ottomotors.amr.sequence.ottoLifter": {
    id: "lifter",
    frames: 150,
    specFrames: [20, 42, 73, 103, 131],
  },
};

const ProductInDetail = ({
  data: { textLockup, carouselTheme, amrSequence, sliceConfig, _key },
}: IProps) => {
  if (!amrSequence?.sequence) return null;

  const { sequence: sequenceArray } = amrSequence || {};
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: `start`,
    loop: true,
  });

  const ref = useRef(null);
  const canvasRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollProgress, setScrollProgress] = useState(0);

  const [isLifter, setIsLifter] = useState(false);
  const [referenceAspect, setReferenceAspect] = useState(null);

  //

  const sequence = sequenceArray?.[0];

  const getSequenceConfig = (sequenceData) => {
    if (!sequenceData) return;

    const sequenceType = sequenceData._type;
    const config = SEQUENCES[sequenceType];

    return config;
  };

  const sequenceConfig = getSequenceConfig(sequence);

  const sequenceUrls = Array.from({ length: sequenceConfig.frames }, (_, i) => {
    const imageUrl = `/images/sequences/otto-${sequenceConfig.id}/${i}.jpg`;
    return imageUrl;
  });

  const scrollProgressAtFrame = (frameNumber, totalFrames) => {
    const progress = frameNumber / totalFrames;
    return parseFloat(progress.toFixed(2));
  };

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", () => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  // typically it's best to useLayoutEffect() instead of useEffect() to have React render the initial state properly from the very start.
  useLayoutEffect(() => {
    // if (!tablet) return; can disable this on mobile if needed.
    let playhead = { frame: 0 };
    let curFrame = -1;
    let images = Array<any>;

    // wrap all gsap inside a gsap.context to prevent gsap code from running twice
    // clean-up function to revert everything
    // reference: https://gsap.com/community/forums/topic/34794-react-multiple-pinned-elements-with-scroll-trigger/?do=findComment&comment=174223
    let gsapCtx = gsap.context(() => {
      const tl = gsap.timeline({
        ease: "power2.inOut",
        scrollTrigger: {
          trigger: ref.current,
          start: "top +=64", // start position of the scroll trigger. start at the top of the viewport and add 100px to account for the header
          end: "+=3000", // end position of the scroll trigger. smaller the value the longer the video
          scrub: 1, // important!
          pin: true,
          onUpdate: ({ progress }) => {
            const scrollProgress = parseFloat(progress.toFixed(2));
            setScrollProgress(scrollProgress);
          },
          // markers: {
          //   startColor: "red",
          //   endColor: "red",
          //   fontSize: "18px",
          //   fontWeight: "bold",
          //   indent: 20,
          // },
        },
      });

      const imageSequence = (config) => {
        const canvas =
          gsap.utils.toArray(config.canvas)[0] ||
          console.warn("canvas not defined");

        const ctx = canvas.getContext("2d");

        const updateImage = () => {
          const frame = Math.round(playhead.frame);

          if (frame !== curFrame) {
            const image = images[Math.round(playhead.frame)];
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            curFrame = frame;
          }
        };

        images = config.urls.map((url, i) => {
          let img = new Image();
          img.src = url;
          i || (img.onload = updateImage);
          return img;
        });

        return tl.to(playhead, {
          frame: images.length - 1,
          ease: "power1.inOut",
          onUpdate: updateImage,
          // duration: 1,
          paused: !!config.paused,
          delay: config?.delay || 0,
        });
      };

      imageSequence({
        urls: sequenceUrls, // Array of image URLs
        canvas: "#image-sequence", // <canvas> object to draw images to
      });
    });

    return () => {
      gsapCtx.revert();
    };
  }, []);

  useEffect(() => {
    if (typeof window === `undefined`) {
      return;
    }

    setIsLifter(
      window.location.pathname === `/lifter/` ||
        window.location.pathname === `/lifter`
    );

    const handleResize = () => {
      const windowAspectRatio = window.innerWidth / window.innerHeight;
      setReferenceAspect(windowAspectRatio);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const letterbox = referenceAspect > 1.75;
  const isLifterProblem = isLifter && referenceAspect > 2;

  return (
    <div className={styles.wrapper} ref={ref}>
      <SliceConfig config={sliceConfig}>
        <div className={styles.container}>
          <LayoutProvider className={styles.grid} grid>
            <div
              ref={canvasContainerRef}
              className={cn(styles.canvasContainer, {
                [styles.letterbox]: letterbox,
              })}
            >
              <div className={cn(styles.headingGradient)}>
                <img src={gradient} alt="gradient" />
              </div>

              <canvas
                ref={canvasRef}
                className={cn(styles.canvas, {
                  [styles.lifter]: isLifterProblem,
                })}
                id="image-sequence"
                width="1158"
                height="1158"
              />
            </div>

            {textLockup && (
              <TextLockup className={styles.textLockup} data={textLockup} />
            )}

            {sequence?.specs?.[0] && (
              <div className={styles.specsCarousel}>
                <ul>
                  <Carousel
                    embla={{
                      api: emblaApi,
                      ref: emblaRef,
                    }}
                    slidesPerView={1}
                    spaceBetween={8}
                    showOverflow
                    slides={() =>
                      sequence.specs.map((spec, index) => {
                        return (
                          <SpecItem
                            key={`product-in-detail--carousel-item-${_key}-spec-${spec}-${index}`}
                            spec={spec}
                            active
                          />
                        );
                      })
                    }
                  />
                </ul>

                <CarouselNav
                  embla={emblaApi}
                  selectedIndex={selectedIndex}
                  items={sequence.specs}
                  theme={carouselTheme?.theme || "dark"}
                  loop
                />
              </div>
            )}

            {sequence?.specs?.[0] && (
              <div
                className={cn(styles.specsList, {
                  [styles.letterbox]: letterbox,
                })}
              >
                {sequence.specs.map((spec, index) => {
                  const buffer = 0.06;
                  let isActive = false;

                  // the frame that this spec should be active
                  const activeAtFrame = sequenceConfig.specFrames[index];

                  // calculate the value of scroll progress at the frame value of activeAtFrame
                  const activeAtScrollProgress = scrollProgressAtFrame(
                    activeAtFrame,
                    sequenceConfig.frames
                  );

                  // add a small buffer to both end of activeAtScrollProgress so the spec is active during a small set of frames instead of a single frame
                  isActive =
                    scrollProgress >= activeAtScrollProgress - buffer &&
                    scrollProgress <= activeAtScrollProgress + buffer;

                  return (
                    <SpecItem
                      key={`product-in-detail-${_key}-spec-${spec}-${index}`}
                      spec={spec}
                      active={isActive}
                    />
                  );
                })}
              </div>
            )}
          </LayoutProvider>
        </div>
      </SliceConfig>
    </div>
  );
};

export default ProductInDetail;
