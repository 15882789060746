import React, { useEffect, useState } from "react";
import cn from "classnames";
import {
  Carousel,
  CarouselNav,
  IntersectionAnimation,
} from "@ottomotors/ottomotors-common/components";
import {
  AllAmrsCard,
  ArticleCard,
  FeaturedProductCard,
  PlanCard,
  ProductCard,
  ResourceCard,
} from "@ottomotors/ottomotors.com/components";
import useEmblaCarousel from "embla-carousel-react";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";

import {
  IOttoMotorsAllAmrsAmr,
  IOttoMotorsCardCarouselArticleCard,
  IOttoMotorsCardCarouselFeaturedProductCard,
  IOttoMotorsCardCarouselPlansCard,
  IOttoMotorsCardCarouselProductCard,
  IOttoMotorsSliceTheme,
} from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    cards:
      | IOttoMotorsAllAmrsAmr[]
      | IOttoMotorsCardCarouselArticleCard[]
      | IOttoMotorsCardCarouselFeaturedProductCard[]
      | IOttoMotorsCardCarouselPlansCard[]
      | IOttoMotorsCardCarouselProductCard[];
  };

  className?: string;
  variant: "article" | "allAmrs" | "featuredProduct" | "product" | "plan";
  measurementUnit?: string;
  contentVisible?: boolean;
  theme?: IOttoMotorsSliceTheme;
  landscapeCardImage?: boolean;
}

const CardCarousel = ({
  data,
  className = "",
  variant = "article",
  measurementUnit = "",
  contentVisible = false,
  theme,
  landscapeCardImage = false,
}: IProps) => {
  const { cards } = data || {};

  if (!cards?.[0]) return null;

  const CAROUSEL_CONFIG = {
    allAmrs: {
      component: AllAmrsCard,
      slidesPerView: {
        mobile: 1.15,
        tablet: 1.15,
        desktop: 1.15,
      },
      carouselThreshold: {
        mobile: 2,
        tablet: 2,
        desktop: 2,
      },
    },
    article: {
      component: ArticleCard,
      slidesPerView: {
        mobile: 1.25,
        tablet: 2.1,
        desktop: 3.1,
      },
      carouselThreshold: {
        mobile: 1,
        tablet: 3,
        desktop: 4,
      },
    },
    resources: {
      component: ResourceCard,
      slidesPerView: {
        mobile: 1.25,
        tablet: 2.1,
        desktop: 3.1,
      },
      carouselThreshold: {
        mobile: 1,
        tablet: 3,
        desktop: 4,
      },
    },
    featuredProduct: {
      component: FeaturedProductCard,
      slidesPerView: {
        mobile: 1.25,
        tablet: 1.25,
        desktop: 1.25,
      },
      carouselThreshold: {
        mobile: 2,
        tablet: 2,
        desktop: 2,
      },
    },
    product: {
      component: ProductCard,
      slidesPerView: {
        mobile: 1.1,
        tablet: 2.1,
        desktop: 3.1,
      },
      carouselThreshold: {
        mobile: 2,
        tablet: 3,
        desktop: 4,
      },
    },
    plan: {
      component: PlanCard,
      slidesPerView: {
        mobile: 1.05,
        tablet: 2.05,
        desktop: 3.05,
      },
      carouselThreshold: {
        mobile: 1,
        tablet: 2,
        desktop: 4,
      },
    },
  };

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: `start`,
    loop: true,
  });

  const breakpoints = useBreakpoints();

  const { smallMobile, tablet, largeTablet } = breakpoints || {};

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [spaceBetween, setSpaceBetween] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(0);
  const [isCarousel, setIsCarousel] = useState(false);

  const config = CAROUSEL_CONFIG?.[variant];
  const CardComponent = config?.component;
  // const asCarousel = cards.length >= config?.carouselThreshold;

  // useEffect(() => {

  // })

  useEffect(() => {
    let spacePx = 16;
    let asCarousel = cards.length >= config?.carouselThreshold?.mobile;
    let slidesPerView = config?.slidesPerView?.mobile;

    if (tablet) {
      spacePx = 24;
      asCarousel = cards.length >= config?.carouselThreshold?.tablet;
      slidesPerView = config?.slidesPerView?.tablet;
    }

    if (largeTablet) {
      spacePx = 24;
      asCarousel = cards.length >= config?.carouselThreshold?.desktop;
      slidesPerView = config?.slidesPerView?.desktop;
    }

    setSpaceBetween(spacePx);
    setIsCarousel(asCarousel);
    setSlidesPerView(slidesPerView);
  }, [smallMobile, tablet, largeTablet]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", () => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  return (
    <div className={cn(styles.container, className)}>
      {isCarousel && (
        <>
          <Carousel
            embla={{
              api: emblaApi,
              ref: emblaRef,
            }}
            className={styles.carousel}
            slidesPerView={slidesPerView}
            spaceBetween={spaceBetween}
            showOverflow
            slideClassName={{ [styles.planCardSlide]: variant === "plan" }}
            slides={() =>
              cards.map((card, index) => (
                <CardComponent
                  key={card?._key || card?._id}
                  data={card}
                  measurementUnit={measurementUnit}
                  contentVisible={contentVisible}
                  isVisible={
                    variant === "allAmrs" ? selectedIndex === index : null
                  }
                />
              ))
            }
          />

          <CarouselNav
            embla={emblaApi}
            selectedIndex={selectedIndex}
            items={cards}
            theme={theme?.theme}
            loop
          />
        </>
      )}

      {!isCarousel && (
        <div className={styles.cards}>
          {cards.map((card, index) => {
            const delay = index * 150;

            return (
              <div
                key={`card-carousel-card-${card?._key || card?._id}`}
                style={{
                  flexBasis: `calc(100%/${cards.length})`,
                }}
                // style={{
                //   flex: `0 0 calc(100% / ${Math.floor(
                //     config.slidesPerView
                //   )} - ${
                //     ((Math.floor(config.slidesPerView) - 1) * 24) /
                //     Math.floor(config.slidesPerView)
                //   }px)`,
                // }}
              >
                <IntersectionAnimation
                  className={styles.cardContainer}
                  delay={delay}
                >
                  <CardComponent
                    data={card}
                    landscapeCardImage={landscapeCardImage}
                  />
                </IntersectionAnimation>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CardCarousel;
