// extracted by mini-css-extract-plugin
export var circleSvg = "styles-module--circleSvg--21738";
export var container = "styles-module--container--83bef";
export var desktop = "1340px";
export var giant = "2200px";
export var hidden = "styles-module--hidden--c8669";
export var hide = "styles-module--hide--729ac";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var playButton = "styles-module--playButton--f509f";
export var playButtonContent = "styles-module--playButtonContent--e0823";
export var playSvg = "styles-module--playSvg--96871";
export var playText = "styles-module--playText--2b34d";
export var playing = "styles-module--playing--3f519";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var thumbnail = "styles-module--thumbnail--8dd42";
export var video = "styles-module--video--ef5f9";