// extracted by mini-css-extract-plugin
export var canvas = "styles-module--canvas--e77e7";
export var canvasContainer = "styles-module--canvasContainer--1dcaa";
export var canvasImageOverlay = "styles-module--canvasImageOverlay--6bd5e";
export var container = "styles-module--container--9d1fa";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--74b1c";
export var headingGradient = "styles-module--headingGradient--69459";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var letterbox = "styles-module--letterbox--b5b5e";
export var lifter = "styles-module--lifter--cba64";
export var media = "styles-module--media--48db4";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var specsCarousel = "styles-module--specsCarousel--1165a";
export var specsList = "styles-module--specsList--0dc27";
export var tablet = "768px";
export var textLockup = "styles-module--textLockup--d023b";
export var visible = "styles-module--visible--13f4b";
export var wrapper = "styles-module--wrapper--a3f2b";