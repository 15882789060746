import React, { useEffect, useState, useRef } from "react";
import mapStyles from "./mapStyles";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { IGeopoint } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

export interface IPlace {
  _id: string;
  name: string;
  address: string;
  location: {
    lat: number;
    lng: number;
  };
  distance: number | undefined;
}

interface IProps {
  locations: IPlace[];
  scriptHasLoaded: boolean;
  centerCoordinates?: IGeopoint;
  searchRadius: number;
  selectedPlace: IPlace | null;
  setSelectedPlace: React.Dispatch<React.SetStateAction<IPlace | null>>;
}

const Map = ({
  locations,
  scriptHasLoaded,
  centerCoordinates,
  searchRadius,
  selectedPlace,
  setSelectedPlace,
}: IProps) => {
  const mapRef = useRef<google.maps.Map | null>(null);

  const firstLocationCoordinates = locations?.[0]?.location;

  const [prevMapCenter, setPrevMapCenter] = useState<IGeopoint | null>(null);
  const [mapCenter, setMapCenter] = useState<IGeopoint | null>(
    centerCoordinates || firstLocationCoordinates
  );

  const searchRadiusZoom = -0.25 * searchRadius + 10.5; // Figured this out manually using https://www.calcmaps.com/map-radius/

  const calculateZoom = (coordinates: IGeopoint | undefined) => {
    return coordinates ? searchRadiusZoom : 4;
  };

  const [zoom, setZoom] = useState(calculateZoom(centerCoordinates));

  const onClickMarker = (place: IPlace) => {
    setSelectedPlace(place);
  };

  const handleOnCloseClick = (place: IPlace) => {
    setSelectedPlace(null);
    setPrevMapCenter(place.location);
  };

  const handleZoomChanged = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom()!);
    }
  };

  useEffect(() => {
    if (centerCoordinates) {
      setMapCenter(centerCoordinates);
      setZoom(calculateZoom(centerCoordinates));
    }
  }, [centerCoordinates]);

  useEffect(() => {
    if (selectedPlace) {
      setMapCenter(selectedPlace?.location);
    } else if (prevMapCenter) {
      setMapCenter(prevMapCenter);
    }
  }, [selectedPlace]);

  return (
    <div className={styles.container}>
      {scriptHasLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={mapCenter as IGeopoint} // This is a hack...
          zoom={zoom}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            styles: mapStyles,
          }}
          onLoad={(map: google.maps.Map) => {
            mapRef.current = map;
          }}
          onZoomChanged={handleZoomChanged}
        >
          {locations.map((place, index) => {
            return (
              <div key={`dealer-map-marker${index}`}>
                {selectedPlace?._id === place?._id && (
                  <InfoWindow
                    position={{
                      lat: selectedPlace.location.lat,
                      lng: selectedPlace.location.lng,
                    }}
                    options={{
                      minWidth: 300,
                      maxWidth: 300,
                    }}
                    onCloseClick={() => handleOnCloseClick(place)}
                  >
                    <div>
                      {/* <div className={styles.logoContainer}>
                      <GatsbyImage
                        alt="Mayfield logo"
                        image={place.logo.asset.gatsbyImageData}
                      />
                    </div> */}

                      <h2 className="h4">{selectedPlace.name}</h2>
                      <p className="b2">{selectedPlace.address}</p>
                    </div>
                  </InfoWindow>
                )}

                {place?._id !== selectedPlace?._id && (
                  <Marker
                    position={place.location}
                    onClick={() => onClickMarker(place)}

                    // icon={{
                    //   url: place.logo.asset.url!,
                    //   scaledSize: new window.google.maps.Size(48, 27),
                    //   anchor: new window.google.maps.Point(24, 46),
                    // }}
                  />
                )}
              </div>
            );
          })}
        </GoogleMap>
      )}
    </div>
  );
};

export default Map;
