// extracted by mini-css-extract-plugin
export var card = "styles-module--card--82f0c";
export var center = "styles-module--center--a2b66";
export var container = "styles-module--container--55c90";
export var content = "styles-module--content--a36fd";
export var desktop = "1340px";
export var fixedWidth = "styles-module--fixedWidth--7f004";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var paddingBottomNone = "styles-module--paddingBottomNone--15b55";
export var paddingTopNone = "styles-module--paddingTopNone--1afe5";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";