import React, { useEffect } from "react";
import cn from "classnames";
import { useAccordion } from "@ottomotors/ottomotors-common/hooks";
import { IOttoMotorsWorkflowItem } from "@ottomotors/ottomotors-sanity/schemas/ottomotors.com/objects/workflow.item";
import WorkflowItemContent from "../WorkflowItemContent";

import * as styles from "./styles.module.scss";

interface IProps {
  item: IOttoMotorsWorkflowItem;
  isExpanded: boolean;
}

const AccordionTab = ({ item, isExpanded }: IProps) => {
  const { containerHeight, contentRef, setIsExpanded } = useAccordion();
  useEffect(() => {
    setIsExpanded(isExpanded);
  }, [isExpanded]);
  return (
    <div className={cn(styles.container, { [styles.expanded]: isExpanded })}>
      <div className={cn("b1", styles.header)}>
        <span className={styles.heading}>{item?.heading}</span>
      </div>
      <div
        className={styles.contentContainer}
        style={{
          height: containerHeight,
        }}
      >
        <div ref={contentRef} className={styles.content}>
          <div className={styles.contentPad}>
            <WorkflowItemContent item={item} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionTab;
