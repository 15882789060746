import React from "react";

import * as styles from "./styles.module.scss";

const PageLoader = () => {
  return (
    <div className={styles.container}>
      <h4 className="h1">Loading...</h4>
    </div>
  );
};

export default PageLoader;
