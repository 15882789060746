// extracted by mini-css-extract-plugin
export var card = "styles-module--card--64c4e";
export var cardContainer = "styles-module--cardContainer--1beea";
export var cards = "styles-module--cards--37d32";
export var carousel = "styles-module--carousel--6908f";
export var container = "styles-module--container--c02ca";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var planCardSlide = "styles-module--planCardSlide--1e74e";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";