import * as React from "react";
import AppProvider from "./src/context/AppContext";
import ASGProvider from "./src/context/ASGContext";

// workaround for Gatsby Link issues on Gatsby 5 when using "scroll-behavior: smooth"
// reference: https://github.com/gatsbyjs/gatsby/issues/38201#issuecomment-1631378720
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);

  return false;
};

export const wrapRootElement = ({ element }) => (
  <AppProvider>
    <ASGProvider>{element}</ASGProvider>
  </AppProvider>
);

export const onRouteUpdate = ({ location }) => {
  if (typeof window !== "undefined" && window.drift) {
    window.drift.page()
  }
};