// extracted by mini-css-extract-plugin
export var accordionTabs = "styles-module--accordionTabs--b2e96";
export var active = "styles-module--active--d2d2c";
export var container = "styles-module--container--8cfbd";
export var dark = "styles-module--dark--5f4c9";
export var desktop = "1340px";
export var giant = "2200px";
export var image = "styles-module--image--c4bb7";
export var imageWrapper = "styles-module--imageWrapper--fd965";
export var images = "styles-module--images--84753";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var layout = "styles-module--layout--802bf";
export var left = "styles-module--left--bfb02";
export var mobile = "400px";
export var right = "styles-module--right--f65c9";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var textLockup = "styles-module--textLockup--1af90";