import React from "react";
import cn from "classnames";
import { LayoutProvider, Tag } from "@ottomotors/ottomotors-common/components";
import FooterItem from "./components/FooterItem";
import {
  SliceConfig,
  VariableMedia,
} from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsEventAtf } from "@ottomotors/ottomotors-sanity";
import { IPageMetadata } from "@ottomotors/ottomotors.com/components/SanitySlice";
import dayjs from "dayjs";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsEventAtf;
  pageMetadata?: IPageMetadata;
}

const EventAtf = ({ data, pageMetadata }: IProps) => {
  const { media, heading, area, location, sliceConfig } = data || {};

  let date = dayjs(pageMetadata?.startDate).format("MMMM D, YYYY");

  if (pageMetadata?.endDate) {
    const startDate = dayjs(pageMetadata?.startDate);
    const endDate = dayjs(pageMetadata?.endDate);

    const isSameMonth = endDate.isSame(startDate, "month");

    const formattedDateStart = startDate.format("MMMM D");

    if (isSameMonth) {
      const formattedDateEnd = endDate.format("D, YYYY");

      date = `${formattedDateStart} - ${formattedDateEnd}`;
    } else {
      const formattedDateEnd = endDate.format("MMMM D, YYYY");

      date = `${formattedDateStart} - ${formattedDateEnd}`;
    }
  }

  const getFormattedTag = (tag) => {
    if (!tag) return;

    let formattedTag;

    switch (tag) {
      case "conference":
        formattedTag = "Conference";
        break;
      case "tradeShow":
        formattedTag = "Trade Show";
        break;
      case "partnerEvent":
        formattedTag = "Partner Event";
        break;
      case "speakingSession":
        formattedTag = "Speaking Session";
        break;
      default:
        break;
    }

    return formattedTag;
  };

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider grid>
          <header className={styles.header}>
            <p className="label">Event</p>
            {heading && <h1 className="d1">{heading}</h1>}
          </header>

          <div className={styles.footer}>
            <LayoutProvider className={styles.footerGrid} grid fullWidthGrid>
              {date && (
                <FooterItem
                  className={styles.footerItem}
                  label="Date"
                  body={date}
                />
              )}

              {area && (
                <FooterItem
                  className={styles.footerItem}
                  label="Area"
                  body={area}
                />
              )}

              {location && (
                <FooterItem
                  className={styles.footerItem}
                  label="Location"
                  body={location}
                />
              )}

              {pageMetadata?.category?.[0] && (
                <ul className={cn(styles.footerItem, styles.tags)}>
                  <li>
                    <Tag
                      text={getFormattedTag(pageMetadata.category?.[0])}
                      variant="outline"
                      theme="light"
                    />
                  </li>
                </ul>
              )}
            </LayoutProvider>
          </div>

          {(media?.image || media?.video) && (
            <VariableMedia
              className={cn(styles.media, {
                [styles.videoContainer]: media?.video,
              })}
              media={media}
            />
          )}
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default EventAtf;
