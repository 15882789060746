import React from "react";
import cn from "classnames";
import { IRichTextBlockQuote } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

export interface IProps {
  data: IRichTextBlockQuote;
}

const PortableTextBlockQuote = ({ data }: IProps) => {
  const { quote, source, sourceCaption } = data || {};

  if (!quote) return null;

  return (
    <div className={styles.container}>
      <h4 className={cn("h4", styles.quote)}>{quote}</h4>

      {(source || sourceCaption) && (
        <div>
          {source && <p className="b2">{source}</p>}
          {sourceCaption && (
            <p className={cn("b2", styles.sourceCaption)}>{sourceCaption}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default PortableTextBlockQuote;
