import { useStaticQuery, graphql } from "gatsby";
import { IOttoMotorsSettings } from "@ottomotors/ottomotors-sanity";

const useSiteMetadata: () => IOttoMotorsSettings = () => {
  const settings = useStaticQuery(graphql`
    query OttoMotorsSiteSettings {
      sanityOttomotorsSettings {
        contactEmail
        socials {
          _key
          platform
          url {
            url
          }
        }
        banner {
          showBanner
          text
          link {
            ...UniversalLinkWebsiteFragment
          }
        }
        header {
          items {
            _key
            link {
              ... on SanityExternalUrl {
                _type
                url
              }

              ... on SanityReferenceOttomotorsPage {
                _type
                page {
                  slug {
                    current
                  }
                }
              }

              ... on SanityReferenceOttomotorsAmr {
                _type
                amr {
                  slug {
                    current
                  }
                }
              }
              ... on SanityReferenceOttomotorsProduct {
                _type
                product {
                  slug {
                    current
                  }
                }
              }
            }
            links {
              ...UniversalLinkWebsiteFragment

              ... on SanityOttomotorsHeaderItemCard {
                _key
                _type
                cardIcon: icon {
                  icon
                }
                image {
                  asset {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
                cardLink: link {
                  ...UniversalLinkWebsiteFragment
                }
              }
            }
            title
          }
        }
        footer {
          contactNumber
          copyrightText
          locationLink {
            ...UniversalLinkWebsiteFragment
          }
          primaryLinksTop {
            _key
            heading
            links {
              ...UniversalLinkWebsiteFragment
            }
          }
          secondaryLinks {
            ...UniversalLinkWebsiteFragment
          }
        }
        contactOverlay {
          textLockup {
            ...OttoMotorsTextLockupFragment
          }
          embed
          embedHeightDesktop
          embedHeightMobile
        }
        seo {
          title
          keywords
          # tagline
          description
          image {
            asset {
              url
            }
          }
          # favicon {
          #   asset {
          #     url
          #   }
          # }
        }
      }
    }
  `)?.sanityOttomotorsSettings;

  return settings;
};

export default useSiteMetadata;
