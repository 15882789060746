import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import gsap from "gsap";
import { useInView } from "react-intersection-observer";
import { useScroll } from "@ottomotors/ottomotors.com/hooks";
import { LayoutProvider, SVG } from "@ottomotors/ottomotors-common/components";
import {
  AllAmrsCard,
  CardCarousel,
  Slider,
  SliceConfig,
  TextLockup,
} from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsAllAmrs } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsAllAmrs;
}

const AllAmrs = ({ data }: IProps) => {
  const { ref, inView, entry } = useInView({
    triggerOnce: true,
  });

  const { scrollY } = useScroll();

  const { amrs, textLockup, sliceConfig } = data || {};

  const backgroundRef = useRef();
  const tabsRef = useRef([]);

  const [measurementUnit, setMeasurementUnit] = useState("metric");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    if (!inView || !entry?.target || typeof window === "undefined") {
      return;
    }

    const boundingRect = entry?.target.getBoundingClientRect();
    const windowOffset = window.innerHeight / 2;
    const adjustedY = 0 - boundingRect.y + window.innerHeight;

    let percentScrolled = -0.5 + adjustedY / windowOffset;

    if (percentScrolled < 0) {
      percentScrolled = 0;
    } else if (percentScrolled > 1) {
      percentScrolled = 1;
    }

    gsap.killTweensOf(backgroundRef.current);

    gsap.to(backgroundRef.current, {
      duration: 0.1,
      opacity: percentScrolled,
      ease: "power2.out",
    });

    setContentVisible(percentScrolled > 0.8);
  }, [inView, scrollY, entry, backgroundRef]);

  useEffect(() => {
    gsap.killTweensOf(tabsRef.current);

    tabsRef.current.forEach((tab, index) => {
      if (contentVisible) {
        gsap.to(tab, {
          delay: 0.1 + 0.1 * index,
          duration: 0.5,
          x: 0,
          opacity: 1,
          ease: "power4.out",
        });
      } else {
        gsap.to(tab, {
          duration: 0.5,
          x: -16,
          opacity: 0,
          ease: "power4.out",
        });
      }
    });
  }, [tabsRef, contentVisible]);

  return (
    <SliceConfig config={sliceConfig}>
      <section ref={ref} className={styles.container}>
        <div ref={backgroundRef} className={styles.background}></div>

        <div className={styles.content}>
          <LayoutProvider grid>
            {textLockup && (
              <article className={styles.textLockup}>
                <TextLockup data={textLockup} />
              </article>
            )}

            <div className={styles.slider}>
              <Slider
                uncheckedText="metric"
                checkedText="imperial"
                onToggleWithText={setMeasurementUnit}
              />
            </div>

            <ul className={styles.tabs}>
              {amrs.map((amr, index) => {
                const {
                  amr: amrData,
                  icon: { icon },
                } = amr || {};

                const { title, technicalSpecs } = amrData || {};

                const isSelected = index === selectedIndex;

                return (
                  <li
                    key={`all-amrs-item-${amrData?._id}`}
                    ref={(el) => (tabsRef.current[index] = el)}
                  >
                    <article
                      className={cn(styles.tab, {
                        [styles.selected]: isSelected,
                      })}
                      onClick={() => setSelectedIndex(index)}
                    >
                      <div className={styles.tabText}>
                        <p className="label">{title}</p>
                        <p className={cn("caption", styles.tabCaption)}>
                          Moves loads up to{" "}
                          <span>
                            {technicalSpecs?.[measurementUnit]?.payloadCapacity}
                          </span>
                          <span>{` ${
                            measurementUnit === "metric" ? `kg` : `lb`
                          }`}</span>
                        </p>
                      </div>

                      <figure className={styles.tabIcon}>
                        <SVG svg={icon} />
                        {/* <SVG svg="ottoLifter" /> */}
                      </figure>
                    </article>
                  </li>
                );
              })}
            </ul>

            <div className={styles.cards}>
              {amrs?.[0] &&
                amrs.map((amr, index) => {
                  const isVisible = index === selectedIndex;

                  return (
                    <AllAmrsCard
                      key={`all-amrs-card-${amr?._key}`}
                      className={cn(styles.card, {
                        [styles.visible]: isVisible,
                      })}
                      contentVisible={contentVisible}
                      isVisible={isVisible}
                      measurementUnit={measurementUnit}
                      data={amr}
                    />
                  );
                })}
            </div>

            <div className={styles.cardCarousel}>
              <CardCarousel
                data={{ cards: amrs }}
                variant="allAmrs"
                measurementUnit={measurementUnit}
                contentVisible={contentVisible}
              />
            </div>
          </LayoutProvider>
        </div>
      </section>
    </SliceConfig>
  );
};

export default AllAmrs;
