import React from "react";
import cn from "classnames";
import {
  LayoutProvider,
  UniversalLinkWrapper,
} from "@ottomotors/ottomotors-common/components";
import { TextLockup, SliceConfig } from "@ottomotors/ottomotors.com/components";

import {
  IOttoMotorsClientLogoBoard,
  IExternalUrl,
  IReferenceOttoMotorsAmr,
  IReferenceOttoMotorsPage,
  IReferenceOttoMotorsProduct,
} from "@ottomotors/ottomotors-sanity";

import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsClientLogoBoard;
}

interface IConditionalLinkWrapper {
  condition:
    | IExternalUrl
    | IReferenceOttoMotorsAmr
    | IReferenceOttoMotorsPage
    | IReferenceOttoMotorsProduct;
  wrapper: any;
  children: React.ReactNode;
}

const ClientLogoBoard = ({
  data: { clients, textLockup, sliceConfig },
}: IProps) => {
  if (!clients?.[0]) return;

  const { giant } = useBreakpoints();

  const ConditionalLinkWrapper = ({
    condition,
    wrapper,
    children,
  }: IConditionalLinkWrapper) => (condition ? wrapper(children) : children);

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider className={styles.grid} grid>
          {textLockup && (
            <div className={styles.textLockup}>
              <TextLockup data={textLockup} />
            </div>
          )}
        </LayoutProvider>

        {/* desktop marquee */}
        <div className={cn(styles.logos)}>
          <div className={styles.marquee}>
            {clients.map((client) => {
              const { image, link } = client || {};

              return (
                <figure
                  key={`client-logo-board-logo-${client?._key}`}
                  className={styles.logo}
                >
                  <ConditionalLinkWrapper
                    condition={link?.[0]}
                    wrapper={(children: React.ReactNode) => (
                      <UniversalLinkWrapper data={{ link: link }}>
                        {children}
                      </UniversalLinkWrapper>
                    )}
                  >
                    <img
                      src={image.asset.url}
                      alt={image?.altText || "OTTO Motors"}
                    />
                  </ConditionalLinkWrapper>
                </figure>
              );
            })}
          </div>

          <div className={styles.marquee}>
            {clients.map((client) => {
              const { image, link } = client || {};

              return (
                <figure
                  key={`client-logo-board-logo-${client?._key}`}
                  className={styles.logo}
                >
                  <ConditionalLinkWrapper
                    condition={link?.[0]}
                    wrapper={(children: React.ReactNode) => (
                      <UniversalLinkWrapper data={{ link: link }}>
                        {children}
                      </UniversalLinkWrapper>
                    )}
                  >
                    <img
                      src={image.asset.url}
                      alt={image?.altText || "OTTO Motors"}
                    />
                  </ConditionalLinkWrapper>
                </figure>
              );
            })}
          </div>

          {giant && (
            <div className={styles.marquee}>
              {clients.map((client) => {
                const { image, link } = client || {};

                return (
                  <figure
                    key={`client-logo-board-logo-${client?._key}`}
                    className={styles.logo}
                  >
                    <ConditionalLinkWrapper
                      condition={link?.[0]}
                      wrapper={(children: React.ReactNode) => (
                        <UniversalLinkWrapper data={{ link: link }}>
                          {children}
                        </UniversalLinkWrapper>
                      )}
                    >
                      <img
                        src={image.asset.url}
                        alt={image?.altText || "OTTO Motors"}
                      />
                    </ConditionalLinkWrapper>
                  </figure>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </SliceConfig>
  );
};

export default ClientLogoBoard;
