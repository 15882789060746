module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OTTO Motors","short_name":"OTTO Motors","description":"OTTO Motors","start_url":"/","background_color":"#C9E1E6","theme_color":"#C9E1E6","display":"standalone","icon":"src/assets/images/manifest-icon.png","include_favicon":true,"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"e5b9b1160bcfedf37a3373c8697e59e7"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-K6DPHTM","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer","defaultDataLayer":{"platform":"gatsby"}},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
