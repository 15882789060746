import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  text: string;
  variant?: "filled" | "outline";
  theme?: "dark" | "light";
  className?: string;
}

const Tag = ({
  text,
  variant = "filled",
  theme = "dark",
  className,
}: IProps) => {
  return (
    <div
      className={cn(
        styles.container,
        className,
        styles[variant],
        styles[theme]
      )}
    >
      <p className="caption"> {text}</p>
    </div>
  );
};

export default Tag;
