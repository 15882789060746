// extracted by mini-css-extract-plugin
export var close = "styles-module--close--481f4";
export var closeSvg = "styles-module--closeSvg--71500";
export var container = "styles-module--container--249ad";
export var desktop = "1340px";
export var form = "styles-module--form--28f7d";
export var giant = "2200px";
export var grid = "styles-module--grid--73965";
export var header = "styles-module--header--50a44";
export var iframe = "styles-module--iframe--485fc";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var visible = "styles-module--visible--3f6b9";