// extracted by mini-css-extract-plugin
export var container = "styles-module--container--3f252";
export var content = "styles-module--content--a0a93";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var lineItem = "styles-module--lineItem--33e49";
export var lineItemIcon = "styles-module--lineItemIcon--0ac5c";
export var lineItemKey = "styles-module--lineItemKey--12542";
export var lineItemKeyContainer = "styles-module--lineItemKeyContainer--111e7";
export var lineItemValue = "styles-module--lineItemValue--5a7a3";
export var media = "styles-module--media--cb247";
export var metrics = "styles-module--metrics--dd781";
export var metricsHeading = "styles-module--metricsHeading--ae205";
export var mobile = "400px";
export var slider = "styles-module--slider--facda";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";