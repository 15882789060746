// extracted by mini-css-extract-plugin
export var closeButton = "styles-module--closeButton--61bb6";
export var container = "styles-module--container--6bed2";
export var desktop = "1340px";
export var giant = "2200px";
export var hide = "styles-module--hide--1a2f5";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--717e4";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";