import React, { useState } from "react";
import cn from "classnames";
import {
  Button,
  UniversalLinkWrapper,
} from "@ottomotors/ottomotors-common/components";
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./styles.module.scss";

const ArticleCard = ({ data, landscapeCardImage = false, className }) => {
  const { heading, body, image, button } = data || {};

  const [isHovered, setIsHovered] = useState(false);

  const getContentType = (button) => {
    if (!button?.link?.[0]) return null;

    const buttonData = button?.link?.[0];

    let type;

    switch (buttonData?._type) {
      case "referenceOttomotorsCaseStudy":
        type = "Case Study";
        break;

      case "referenceOttomotorsBlogArticle":
        type = "Blog";
        break;

      case `referenceOttomotorsVideo`:
        type = "Video";
        break;

      case `referenceOttomotorsEbook`:
        type = "Ebook";
        break;

      case `referenceOttomotorsWebinar`:
        type = "Webinar";
        break;

      case "referenceOttomotorsWhitePaper":
        type = "White Paper";
        break;

      default:
        break;
    }

    return type;
  };

  const articleType = getContentType(button);

  return (
    <UniversalLinkWrapper className={className} data={button}>
      <article
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={styles.container}
      >
        {image?.asset?.gatsbyImageData && (
          <figure
            className={cn(styles.image, {
              [styles.landscape]: landscapeCardImage,
            })}
          >
            <GatsbyImage
              image={image?.asset?.gatsbyImageData}
              alt={image?.altText || "OTTO Motors"}
            />
          </figure>
        )}
        <div className={styles.content}>
          {articleType && (
            <p>
              <span className={cn("caption", styles.tag)}>{articleType}</span>
            </p>
          )}
          <div className={styles.text}>
            {heading && (
              <header className="h4">
                <h4>{heading}</h4>
              </header>
            )}

            {body && <p className="b1">{body}</p>}
          </div>

          {button && (
            <div className={styles.buttonContainer}>
              {button?.text && button?.link?.[0] && (
                <Button
                  className={styles.button}
                  display
                  text={button.text}
                  iconRight={"arrowRight"}
                  iconLeft={"arrowRight"}
                  animateOnHover
                  variant="text"
                  isHovered={isHovered}
                />
              )}
            </div>
          )}
        </div>
      </article>
    </UniversalLinkWrapper>
  );
};

export default ArticleCard;
