// extracted by mini-css-extract-plugin
export var container = "styles-module--container--d203a";
export var desktop = "1340px";
export var giant = "2200px";
export var iconLink = "styles-module--iconLink--9868f";
export var iconLinkBody = "styles-module--iconLinkBody--74d7e";
export var iconLinkContent = "styles-module--iconLinkContent--21ef8";
export var iconLinkFlex = "styles-module--iconLinkFlex--d8732";
export var iconLinks = "styles-module--iconLinks--35c75";
export var iframe = "styles-module--iframe--9ed9c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var primary = "styles-module--primary--5ca3c";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var textLockup = "styles-module--textLockup--20b9a";