import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";
import {
  NumberCountingAnimation,
  TextLockup,
} from "@ottomotors/ottomotors.com/components";
import cn from "classnames";

import * as styles from "./styles.module.scss";

import { IOttoMotorsAllAmrsAmr } from "@ottomotors/ottomotors-sanity";

interface IProps {
  data: IOttoMotorsAllAmrsAmr;
  className?: string;
  measurementUnit?: string;
  isVisible?: boolean;
  contentVisible?: boolean;
}

const AllAmrsCard = ({
  data,
  className,
  measurementUnit,
  isVisible,
  contentVisible,
}: IProps) => {
  const { amr: amrDocument, image } = data || {};
  const { technicalSpecs } = amrDocument || {};

  const cardLink = {
    _type: `referenceOttomotorsAmr`,
    amr: {
      // todo: not sure what's wrong with this one
      slug: amrDocument.slug,
    },
  };

  const breakpoints = useBreakpoints();
  const isTablet = breakpoints["tablet"];

  const cardTextLockup = {
    body: isTablet && data?.body ? data.body : "",
    heading: amrDocument.title,
    buttonPrimary: {
      text: data.ctaText,
      link: [cardLink],
    },
  };

  return (
    <article className={cn(styles.container, className)}>
      {image?.asset?.gatsbyImageData && (
        <figure className={styles.cardImage}>
          <GatsbyImage
            style={{ padding: `0` }}
            image={image.asset.gatsbyImageData}
            alt={image.altText || "OTTO Motors"}
            objectFit="contain"
          />
        </figure>
      )}

      {cardTextLockup && (
        <TextLockup
          className={cn(styles.textLockup, { [styles.active]: contentVisible })}
          data={cardTextLockup}
        />
      )}

      {technicalSpecs && (
        <ul
          className={cn(styles.specs, {
            [styles.active]: contentVisible,
          })}
        >
          {technicalSpecs?.[measurementUnit]?.payloadCapacity && (
            <li
              className={cn(styles.spec, {
                [styles.visible]: isVisible,
              })}
              style={{ transitionDelay: `0ms` }}
            >
              <p className="label">Payload Capacity</p>
              <p className={cn("b1", styles.specValue)}>
                <NumberCountingAnimation
                  end={
                    isVisible
                      ? technicalSpecs[measurementUnit].payloadCapacity
                      : 0
                  }
                  increment={1}
                  duration={0.5}
                />
                <span>{` ${measurementUnit === "metric" ? `kg` : `lb`}`}</span>
              </p>
            </li>
          )}

          {technicalSpecs?.[measurementUnit]?.maximumTravelSpeed && (
            <li
              className={cn(styles.spec, {
                [styles.visible]: isVisible,
              })}
              style={{ transitionDelay: `75ms` }}
            >
              <p className="label">Maximum Travel Speed</p>
              <p className={cn("b1", styles.specValue)}>
                <NumberCountingAnimation
                  end={
                    isVisible
                      ? technicalSpecs[measurementUnit].maximumTravelSpeed
                      : 0
                  }
                  increment={0.1}
                  duration={0.25}
                />
                <span>{` ${
                  measurementUnit === "metric" ? `m/s` : `mph`
                }`}</span>
              </p>
            </li>
          )}

          {technicalSpecs?.[measurementUnit]?.totalFootprint && (
            <li
              className={cn(styles.spec, {
                [styles.visible]: isVisible,
              })}
              style={{ transitionDelay: `150ms` }}
            >
              <p className="label">Total Footprint</p>
              <p className={cn("b1", styles.specValue)}>
                <NumberCountingAnimation
                  end={
                    isVisible
                      ? technicalSpecs[measurementUnit].totalFootprint.length
                      : 0
                  }
                  increment={1}
                  duration={0.5}
                />
                <span> x </span>
                <NumberCountingAnimation
                  end={
                    isVisible
                      ? technicalSpecs[measurementUnit].totalFootprint.width
                      : 0
                  }
                  increment={1}
                  duration={0.5}
                />
                <span> x </span>
                <NumberCountingAnimation
                  end={
                    isVisible
                      ? technicalSpecs[measurementUnit].totalFootprint.height
                      : 0
                  }
                  increment={1}
                  duration={0.5}
                />
                <span>{` ${measurementUnit === "metric" ? `mm` : `in`}`}</span>
              </p>
            </li>
          )}
        </ul>
      )}
    </article>
  );
};

export default AllAmrsCard;
