// extracted by mini-css-extract-plugin
export var button = "styles-module--button--5025f";
export var container = "styles-module--container--69ef0";
export var desktop = "1340px";
export var footer = "styles-module--footer--189a1";
export var footerGrid = "styles-module--footerGrid--71471";
export var footerGridContainer = "styles-module--footerGridContainer--cb0d9";
export var footerItem = "styles-module--footerItem--8894b";
export var giant = "2200px";
export var header = "styles-module--header--1c454";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--f5b74";
export var mobile = "400px";
export var noButton = "styles-module--noButton--a76f0";
export var share = "styles-module--share--b3e05";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var tags = "styles-module--tags--2b427";
export var videoContainer = "styles-module--videoContainer--a92d5";