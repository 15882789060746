import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  IntersectionAnimation,
  LayoutProvider,
} from "@ottomotors/ottomotors-common/components";
import {
  AccordionTabs,
  TextLockup,
} from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsValueProps } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsValueProps;
}

const ValueProps = ({
  data: { theme, itemPosition, textLockup, items },
}: IProps) => {
  if (!items?.[0]) return null;

  const textLockupConfig = {
    ...textLockup,
    theme: {
      theme: theme === "light" ? "dark" : "light",
    },
    headingTypestyle: textLockup?.headingTypestyle || "h2",
  };

  const itemRefs = useRef(new Array());

  const [activeItem, setActiveItem] = useState(0);
  const [hoveredItem, setHoveredItem] = useState(0);

  useEffect(() => {
    if (itemRefs.current.length === 0) return;

    itemRefs.current.forEach((itemRef, index) => {
      if (!itemRef) return;

      if (activeItem === index) {
        itemRef.play();
      } else {
        itemRef.pause();
      }
    });
  }, [activeItem, items]);

  return (
    <section
      className={cn(styles.container, { [styles.dark]: theme === "dark" })}
    >
      <LayoutProvider className={styles.layout} grid>
        {textLockup && (
          <article className={styles.textLockup}>
            {/* not sure what this guys problem is */}
            <TextLockup data={textLockupConfig} />
          </article>
        )}

        <IntersectionAnimation
          className={cn(styles.images, {
            [styles.right]: itemPosition === "left",
          })}
        >
          {items?.map((item, index) => {
            const active = activeItem === index;
            // const hovered = hoveredItem === index;

            return (
              <div
                key={`value-props-item-${item?._key}`}
                className={cn(styles.imageWrapper, {
                  [styles.active]: active,
                  // [styles.hovered]: hovered,
                })}
              >
                <figure className={styles.image}>
                  {item?.video?.url ? (
                    <video
                      ref={(element) => (itemRefs.current[index] = element)}
                      autoPlay
                      muted
                      loop
                      playsInline
                    >
                      <source src={item.video.url} type="video/mp4" />
                    </video>
                  ) : (
                    item?.image?.asset && (
                      <GatsbyImage
                        style={{ padding: `0` }}
                        image={item.image.asset.gatsbyImageData}
                        alt={item.image.altText || "OTTO Motors"}
                      />
                    )
                  )}
                </figure>
              </div>
            );
          })}
        </IntersectionAnimation>

        <AccordionTabs
          className={cn(styles.accordionTabs, {
            [styles.left]: itemPosition === "left",
          })}
          items={items}
          theme={theme}
          setActiveItem={setActiveItem}
          setHoveredItem={setHoveredItem}
        />
      </LayoutProvider>
    </section>
  );
};

export default ValueProps;
