import React, { useEffect, useState } from "react";
import cn from "classnames";
import { SVG } from "@ottomotors/ottomotors-common/components";
import {
  useAccordion,
  useBreakpoints,
} from "@ottomotors/ottomotors-common/hooks";
import * as styles from "./styles.module.scss";

const AnchorLinksTop = ({ anchorLinks }: { anchorLinks: ({ id: string; label: string } | null)[] }) => {
  const { containerHeight, contentRef, isExpanded, setIsExpanded } =
    useAccordion();
  const { smallDesktop } = useBreakpoints();

  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      // Scrolling down
      setIsVisible(false);
    } else {
      // Scrolling up
      setIsVisible(true);
    }
    setLastScrollY(window.scrollY);
  };

  const handleAnchorLinkClick = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.preventDefault();

    // Scroll to anchor
    const element = document.getElementById(id.replace(/^#/g, ''));
    element?.scrollIntoView();

    setIsExpanded(false);
  };

  // todo: typescript
  const getAnchorLinksJsx = (key: string, anchorLinks: any[]) => {
    return anchorLinks.map((link, index) => {
      if (!link?.id) {
        return null;
      }

      return (
        <p key={`${key}-${index}`} className={styles.link}>
          <a href={link.id} onClick={(e) => handleAnchorLinkClick(e, link?.id)}>
            {link.label}
          </a>
        </p>
      );
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div className={cn(styles.container)}>
      <>
        <section className={styles.container}></section>

        {smallDesktop && (
          <div
            className={cn(styles.fixedContainer, {
              [styles.hidden]: !isVisible,
            })}
          >
            <p className={cn("b2", styles.title)}>
              <span>On this page:</span>
            </p>
            <div className={cn("button", styles.links)}>
              {getAnchorLinksJsx("desktop", anchorLinks)}
            </div>
          </div>
        )}

        {!smallDesktop && (
          <div
            className={cn(styles.fixedContainer, {
              [styles.hidden]: !isVisible,
            })}
          >
            <p
              className={cn("b2", styles.title, styles.accordionTitle)}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <span>On this page:</span>
              <SVG
                className={cn(styles.chevron, {
                  [styles.isExpanded]: isExpanded,
                })}
                svg="chevronDown"
              />
            </p>

            <div
              className={styles.accordionContent}
              style={{
                height: containerHeight,
              }}
            >
              <div ref={contentRef} className={cn("button", styles.links)}>
                {getAnchorLinksJsx("mobile", anchorLinks)}
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default AnchorLinksTop;
