// extracted by mini-css-extract-plugin
export var accordionContainer = "styles-module--accordionContainer--536e5";
export var carouselContainer = "styles-module--carouselContainer--6556f";
export var container = "styles-module--container--5eb85";
export var dark = "styles-module--dark--9a194";
export var desktop = "1340px";
export var dropdownContainer = "styles-module--dropdownContainer--71126";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var layout = "styles-module--layout--27464";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var textLockup = "styles-module--textLockup--4823c";