import React from "react";
import cn from "classnames";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import { SliceConfig, TextLockup } from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsCallToAction } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsCallToAction;
}

const CallToAction = ({
  data: {
    media,
    removeBottomPadding,
    textLockup,
    mediaAlignment = "center",
    sliceConfig,
  },
}: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <section
        className={cn(styles.container, {
          [styles.noBottomPadding]: removeBottomPadding,
        })}
      >
        {textLockup && (
          <LayoutProvider grid>
            <article className={styles.content}>
              <TextLockup data={textLockup} buttonFlexDirection="row" />
            </article>
          </LayoutProvider>
        )}

        {(media?.video?.url || media?.image?.asset) && (
          <>
            <figure
              className={cn(styles.background, {
                [styles.alignLeft]: mediaAlignment === "left",
                [styles.alignRight]: mediaAlignment === "right",
                [styles.alignCenter]:
                  !mediaAlignment || mediaAlignment === "center",
              })}
            >
              {(media?.video?.url && (
                <video autoPlay muted loop playsInline>
                  <source src={media.video.url} type="video/mp4" />
                </video>
              )) || (
                <>
                  {media?.image?.asset && (
                    <img
                      src={media.image.asset.url}
                      alt={media.image.altText}
                    />
                  )}
                </>
              )}
            </figure>

            <div className={styles.overlay} />
          </>
        )}
      </section>
    </SliceConfig>
  );
};

export default CallToAction;
